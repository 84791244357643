import React, { createRef, useState } from 'react';
import { InfoDynamic } from '.';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,
    Button,  
    Grid,
    Card,
    CardContent,
} from '@material-ui/core';  
const useStyles = makeStyles((theme) => ({
    root: {  
        flexGrow: 1,    
        '& > *': {
        margin: theme.spacing(1),
        },      
    },
    button: {
        margin: theme.spacing(.5),
    },
  secondaryButton: {
    backgroundColor: theme.palette.primary.main,
    hover: {
        backgroundColor: theme.palette.accent.dark,        
    }  
  },
  activeRecordHeader: { backgroundColor: '#E0E0E0' }  
}));

  const DynamicForm = ( {formDefaults, editMode = true, handleSubmit, handleCancel }) => {

    const classes = useStyles();
    const form = createRef();
    const [formValues, updateFormValues] = useState(
        formDefaults.reduce((memo, item) => {
            return {...memo, [item.fieldName]: item.fieldValue }
        }, {}));

    const submitForm = () => {
        if(form.current.reportValidity()) {
            handleSubmit(formValues);
        }
    }
    
    return (
    <Card className={classes.root}>
        <form ref={form} onSubmit={e => e.preventDefault()}>
        <CardContent>
        <Grid container spacing={2}>              
        {formDefaults.map((myField, myIndex) => (
            <Grid key={myIndex} 
                item md={
                    myField.displayLength === 'full' ? 12 : 
                    myField.displayLength === 'half' ?  6 : 
                    myField.displayLength === 'quarter' ?  3 : 
                    myField.displayLength === 'third' ?  4 : 
                    myField.displayLength === false ?  false : 
                    myField.displayLength === 'auto' ?  'auto' : 
                    12
                    }
                     xs={12}  sm={6}>  
                <InfoDynamic
                    {...myField}
                    editMode={editMode}
                    header={myField.fieldDisplay}
                    fieldName={myField.fieldName}
                    fieldValue={formValues[myField.fieldName]}
                    fieldType={myField.fieldType}
                    isRequired={myField.required}
                    handleChange={(newValue) => updateFormValues({...formValues, ...newValue })}       
                />
            </Grid> 
        ))}
    </Grid>
    </CardContent>       

    <CardContent className={classes.activeRecordHeader}> 
                            <Box display="flex" flexDirection="row" alignItems="space-between">
                                <Box flexGrow={1}>                                
                                </Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}                        
                                        onClick={submitForm}
                                    >Save</Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleCancel}
                                        className={classes.button}                        
                                    >Cancel</Button>                            
                            </Box>
                </CardContent>
    </form>
    </Card>     
    
    )
  }

  export {
      DynamicForm
  }