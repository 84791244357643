import {  
    Box, 
    FormControl,
    InputLabel,
    Select,    
    MenuItem,    
    }
from '@material-ui/core';
const SelectField = (props) => {
    const { header, fieldName, fieldValue, disabled=false, isRequired=true, options, handleChange } = props;
    
    return (
        <>
        <Box mt={2} />
        <FormControl  required={isRequired} fullWidth>
        <InputLabel className='MuiInputLabel-shrink' htmlFor={fieldName}>{header}</InputLabel>
        <Select
            displayEmpty            
            disabled={disabled}
            value={fieldValue || ''}
            onChange={(e)=>handleChange({ [e.target.name]: e.target.value })}
            label={header}
            inputProps={{
            name: fieldName,
            id: fieldName,
            }}
        >            
        {options.map((myOption, index) => (      
            <MenuItem key={`${fieldName}-${index}`} value={myOption}>
                {myOption}
            </MenuItem>                            
        ))}
        </Select>
        </FormControl>
        <Box mb={2} />
        </>

    )
}

export { SelectField }