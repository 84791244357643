import React, { useState, createRef, useEffect } from 'react';

import { 
  useSelector,
  useDispatch,
  shallowEqual
} from 'react-redux';

import {
  useParams,
  useNavigate
} from 'react-router-dom';

import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,    
    TextField,
    Typography
  } from '@material-ui/core';
  import Alert from '@material-ui/lab/Alert';
  import {
    Card,
    CardContent,
    CardActions
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { makeStyles } from '@material-ui/core/styles';
import { InfoDynamic } from '../Common';
import { backfillCustomFields } from '../../utils';
import { addNewDevice, getDeviceTemplates } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: 'white',
    margin: theme.spacing(1)
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE 11 issue.
  },
  navigationButton: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  selectStyle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  successIcon: {
    color: theme.palette.success.dark,
    fontSize: '56px'
  },
  warningIcon: {
    color: '#ff9800',
    fontSize: '56px'
  },
  content: {
    padding: theme.spacing(3),    
}  
}));


const initialFormData = Object.freeze({
  assetNumber: "",
  purchaseDate: new Date(),
  serial: null,
  purchasePrice: 0
});

const AddDevice = ( ) => {
    const { assetType } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = createRef();
    const category = useSelector((state) => state.assetCategories.categories.find(x => x.assetType === assetType));
    const { templates } = useSelector((state) => state.assetTemplates);
    const { asset, isLoading, error } = useSelector((state) => state.asset, shallowEqual);

    const [newDevice, updateNewDevice] = useState({});
    
    const handleAssetChange = (newValue) => {
        updateNewDevice({...newDevice, ...newValue });        
    }

    const handleCustomFieldChange = (newValue) => {
      try {        
          const fieldName = Object.keys(newValue).pop();
          const newCustomFields = newDevice.customFields.reduce((memo,item) => {
          if (item.fieldName === fieldName) {              
              return [...memo, {...item, fieldValue: newValue[fieldName]}];
          } else {
              return [...memo, item];
          }
          }, []);
          updateNewDevice({...newDevice, customFields: newCustomFields });
      } catch (err) {
          alert(err.message);
      }
    }  

    const handleTemplateChange = (e) => {
      const myTemplate = templates.find(x => x._id === e.target.value);
     
      const { customFields: categoryCustomFields } = category;
      const { customFields: templateCustomFields } = myTemplate;
      const customFields = backfillCustomFields(categoryCustomFields, templateCustomFields);

      updateNewDevice({ ...initialFormData, ...myTemplate, customFields });
    }
    
    const handleSubmit = () => {
      if(form.current.reportValidity()) {
        dispatch(addNewDevice(assetType, newDevice));
      }
    }


    const goBack = () => {
      navigate(`../`, { relative: 'path'})
    }

    useEffect(() => {

      if (asset && asset._id && Object.keys(newDevice).length) {
        navigate(`../asset/${asset._id}`)
      }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset]); 

    useEffect(() => {
        dispatch(getDeviceTemplates(assetType));
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetType]);

    return (
      <div className={classes.content}>
      <h3>Add New {assetType.charAt(0).toUpperCase() + assetType.slice(1)}</h3>
      
      <Button 
        size='small'
        variant="outlined"
        color="default"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={goBack}>
        Back
      </Button>
      <Box pt={2} /> 

      <FormControl className={classes.selectStyle} >
      <TextField
        label="Template"
        name="template"
        onChange={handleTemplateChange}
        select
        value={newDevice._id || ''}
        variant="outlined"
        required
      >              
        {templates && templates.map((template) => (
          <MenuItem key={template._id} value={template._id}>
            {template.template}
          </MenuItem>
        ))}              
      </TextField>
    </FormControl>

        <form ref={form} className={classes.form}  onSubmit={e => e.preventDefault()}>

      {newDevice && newDevice.assetName && (
       <Card>
         <CardContent>
        <Grid container spacing={1}>


        <Grid item xs={12} sm={12}>
              <InfoDynamic
                  header='Asset Name'
                  fieldName='assetName'
                  fieldValue={newDevice.assetName}
                  editMode={true}
                  isRequired={true}
                  handleChange={handleAssetChange}                
              />                    
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
              <InfoDynamic
                  fieldType='number'
                  header='Asset #'
                  fieldName='assetNumber'
                  fieldValue={newDevice.assetNumber}
                  editMode={true}
                  isRequired={false}
                  handleChange={handleAssetChange}                
              />                                        
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
              <InfoDynamic
                  fieldType='number'
                  header='Category #'
                  fieldName='categoryNumber'
                  fieldValue={newDevice.categoryNumber}
                  editMode={true}
                  isRequired={false}
                  handleChange={handleAssetChange}                
              />                                        
        </Grid>                    
        <Grid item xs={12} sm={6} md={4}>
              <InfoDynamic
                  header='Serial'
                  fieldName='serial'
                  fieldValue={newDevice.serial}
                  editMode={true}
                  isRequired={false}
                  handleChange={handleAssetChange}                
              />
        </Grid>
        <Grid item xs={12} sm={6}>
              <InfoDynamic
                  fieldType='date'
                  header='Purchase Date'
                  fieldName='purchaseDate'
                  fieldValue={newDevice.purchaseDate}
                  editMode={true}
                  isRequired={false}
                  handleChange={handleAssetChange}                
              />
        </Grid>                  
        <Grid item xs={12} sm={6}>
              <InfoDynamic
                  fieldType='currency'
                  header='Purchase Price'
                  fieldName='purchasePrice'
                  fieldValue={newDevice.purchasePrice}
                  editMode={true}
                  isRequired={false}
                  handleChange={handleAssetChange}                
              />                                        
          </Grid>

          <Grid container spacing={1}>
          {newDevice && newDevice.customFields && newDevice.customFields.map((myField, myFieldIndex) => (
              <Grid key={myFieldIndex} item md={myField.fieldType === 'full' ? 12 : 6} sm={6} xs={12}>  
                  <InfoDynamic 
                    header={myField.fieldDisplay} 
                    fieldType={myField.fieldType}
                    fieldName={myField.fieldName} 
                    fieldValue={newDevice.customFields[myFieldIndex].fieldValue}
                    options={myField.options || []}
                    editMode={true}
                    isRequired={newDevice.customFields[myFieldIndex].required}
                    handleChange={handleCustomFieldChange}
                    />
              </Grid>        
            ))}
          </Grid>

        </Grid>
        </CardContent>
    
        <CardActions>

            <Button
              className={classes.submit}
              color="primary"
              fullWidth
              disabled={isLoading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              {isLoading ? (
                <Typography
              className='blink-me'
                variant="button">
                  Saving...
             </Typography>
              ) : (
                <Typography>
                Save
                </Typography>
              )}                        
            </Button>
            </CardActions>
            {error && (
              <Box><Alert severity="error">{error}</Alert></Box>                
            )}
            </Card>
          )}

        </form>
        </div>
    )
}

export {
    AddDevice
};