import React, { useState, createRef, useEffect } from 'react';

import { 
    useSelector,
    useDispatch,
    shallowEqual   
  } from 'react-redux';
import { 
    Box,
    Button,  
    Grid,
    Divider,
} from '@material-ui/core';  
import { makeStyles } from '@material-ui/core/styles';
import { 
    InfoDynamic,
    AssetImage,
    Loading,    
//    UploadFile 
} from '../Common';
import { backfillCustomFields } from '../../utils';
import { 
    updateAssetOverview,
    // uploadAssetImage
} from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  
const tempInit = {
    serial: "",
    assetNumber: null,
    assetName: "",
    purchasePrice: null,
    uniqueCategoryNumber1: null,
    uniqueCategoryString1: "",
    customFields: []
}
const AssetOverview = ( { asset, isNew=false }) => {
    const form = createRef();
    const dispatch = useDispatch();    
    const classes = useStyles();
    const category = useSelector((state) => state.assetCategories.categories.find(x => x.assetType === asset.assetType));

    const [tempAsset, updateTempAsset] = useState({...tempInit, ...asset});
    const [editMode, updateEditMode] = useState(isNew);
    const { isLoading } = useSelector((state) => state.asset, shallowEqual);
    
    useEffect(() => {
        if (category) {
            populateTempAsset();
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [asset, category]);
    
      const populateTempAsset = () => {
        const { customFields: categoryCustomFields } = category;

        const { 
            assetType,
            serial,
            uniqueCategoryNumber1,
            uniqueCategoryString1,
            assetNumber,
            assetName,
            purchasePrice,
            purchaseDate,
            customFields: assetCustomFields
         } = asset;
        // Allow any changes to the category custom fields in the category to merge in.  Use order of category
        const customFields = backfillCustomFields(categoryCustomFields, assetCustomFields);
        updateTempAsset({_id: asset._id, serial, assetType, assetNumber, assetName, purchasePrice, purchaseDate, uniqueCategoryString1, uniqueCategoryNumber1, customFields});
      }

    const toggleEditMode = () => {
        if (editMode) {
            populateTempAsset();
        }
        updateEditMode(!editMode);        
    }

    const handleNewValueChange = (newValue) => {
        updateTempAsset({...tempAsset, ...newValue });
    }
    
    const handleCustomFieldChange = (newValue) => {
        try {        
            const fieldName = Object.keys(newValue).pop();
            const newCustomFields = tempAsset.customFields.reduce((memo,item) => {
            if (item.fieldName === fieldName) {              
                return [...memo, {...item, fieldValue: newValue[fieldName]}];
            } else {
                return [...memo, item];
            }
            }, []);
            updateTempAsset({...tempAsset, customFields: newCustomFields });
        } catch (err) {
            alert(err.message);
        }
      }   
      const handleSubmit = () => {
        if(form.current.reportValidity()) {
            dispatch(updateAssetOverview( asset._id, tempAsset));
            updateEditMode(false)
        }
      }
      
      // FILE IMAGE UPLOAD
    //   const uploadFile = (formData) => {
    //     dispatch(uploadAssetImage(asset._id, formData));
    //   }      

    return (
        <>
            
            <Grid
                container
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="center"
            >                
                
                <div className={classes.root}>
            {editMode ? (
                    <>
                    <Button                    
                    size="small"                    
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}>
                        Save
                    </Button>
                
                
                    <Button 
                    size="small"                     
                    variant="outlined"
                    color="primary"
                    onClick={toggleEditMode}>
                        Cancel
                    </Button>  
                    </>
                ) : (
                    <Button 
                    size="small" 
                    variant="outlined"
                    color="primary"
                    onClick={toggleEditMode}>
                        Edit
                    </Button>
            )}
            </div>
            {asset.mdmProvider && (
                <h3>
                    Managed by: {asset.mdmProvider}
                </h3>
            )}

            </Grid>
    
            <Box pb={3}>
                <Divider pb={2} />
            </Box>
            <Box pb={3}>
                {asset && asset.image && asset.image.storageName ? (
                    <AssetImage name={asset.image.storageName} />
                ) : (
                    <></>
                    // <UploadFile handleUpload={uploadFile} />
                )}
            </Box>                



            {isLoading ? (
                    <Loading />
                ) : (
                    <form ref={form} onSubmit={e => e.preventDefault()}> 
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <InfoDynamic
                                    header='Asset Name'
                                    fieldName='assetName'
                                    fieldValue={tempAsset.assetName}
                                    editMode={editMode}
                                    isRequired={true}
                                    handleChange={handleNewValueChange}                
                                />                    
                            </Grid>
                            {category && category.useSerial && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <InfoDynamic
                                        header='Serial'
                                        fieldName='serial'
                                        fieldValue={tempAsset.serial}
                                        editMode={editMode}
                                        isRequired={false}
                                        handleChange={handleNewValueChange}                
                                    />
                                </Grid>
                            )}
                            {category && category.useUniqueCategoryNumber1 && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <InfoDynamic
                                        header={category.uniqueCategoryNumber1Display || 'Unique ID'}
                                        fieldName='uniqueCategoryNumber1'
                                        fieldType='number'
                                        fieldValue={tempAsset.uniqueCategoryNumber1}
                                        editMode={editMode}
                                        isRequired={false}
                                        handleChange={handleNewValueChange}                
                                    />
                                </Grid>
                            )}    
                            {category && category.useUniqueCategoryString1 && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <InfoDynamic
                                        header={category.uniqueCategoryString1Display || 'Unique Field'}
                                        fieldName='uniqueCategoryString1'                                        
                                        fieldValue={tempAsset.uniqueCategoryString1}
                                        editMode={editMode}
                                        isRequired={false}
                                        handleChange={handleNewValueChange}                
                                    />
                                </Grid>
                            )}                                                        

                            {/* <Grid item xs={12} sm={6} md={4}>
                                <InfoDynamic
                                    fieldType='number'
                                    header='Asset #'
                                    fieldName='assetNumber'
                                    fieldValue={tempAsset.assetNumber}
                                    editMode={editMode}
                                    isRequired={false}
                                    handleChange={handleNewValueChange}                
                                />                                        
                            </Grid> 
                            <Grid item xs={12} sm={6} md={4}>
                                <InfoDynamic
                                    fieldType='number'
                                    header='Category #'
                                    fieldName='categoryNumber'
                                    fieldValue={tempAsset.categoryNumber}
                                    editMode={editMode}
                                    isRequired={false}
                                    handleChange={handleNewValueChange}                
                                />                                        
                            </Grid>                                              */}

                            <Grid item xs={12} sm={6} md={4}>
                                <InfoDynamic
                                    fieldType='date'
                                    header='Purchase Date'
                                    fieldName='purchaseDate'
                                    fieldValue={tempAsset.purchaseDate}
                                    editMode={editMode}
                                    isRequired={false}
                                    handleChange={handleNewValueChange}                
                                />
                            </Grid>                  
                            <Grid item xs={12} sm={6} md={4}>
                                <InfoDynamic
                                    fieldType='currency'
                                    header='Full Purchase Price'
                                    fieldName='purchasePrice'
                                    fieldValue={tempAsset.purchasePrice}
                                    editMode={editMode}
                                    isRequired={false}
                                    handleChange={handleNewValueChange}                
                                />                                        
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>              
                            {tempAsset && tempAsset.customFields && tempAsset.customFields.filter(x => x.hidden !== true).map((myField, myIndex) => (
                                <Grid key={myIndex} item md={myField.fieldType === 'full' ? 12 : 4} xs={12}  sm={6}>  
                                    <InfoDynamic
                                        header={myField.fieldDisplay}
                                        fieldName={myField.fieldName}
                                        fieldValue={myField.fieldValue}
                                        fieldType={myField.fieldType}
                                        options={myField.options || []}
                                        editMode={editMode}
                                        isRequired={myField.required}
                                        handleChange={handleCustomFieldChange}                
                                    />
                                </Grid> 
                            ))}
                        </Grid>
                    </form>
                )}
        
        </>
    )
};
export { AssetOverview };