import React, { useState, createRef, useEffect } from 'react';
import { 
    useDispatch,
    useSelector
  } from 'react-redux';


  import {
    Box,
    Button,
    FormControl,
    MenuItem,
    TextField,
    Card,
    CardContent,
    Typography,    
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getCategoryTemplates, addCategoryByTemplate,  ADD_ASSET_CATEGORY_SUCCESS, ADD_ASSET_CATEGORY_FAILURE} from '../../store/actions';
import { InfoDynamic } from '../Common';

const initCategory = {customFields: [], trackRefreshCycle: false, trackDepreciation: false};

const useStyles = makeStyles((theme) => ({
    selectStyle: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: '100%'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #fff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
      },
    badInput: {
        color: 'red',
        fontSize: '.9em'
    }  
  }));

function rand() {
return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
const top = 50 + rand();
const left = 50 + rand();

return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
};
}

const NewCategory = ( { reportBack, cancelAdd}) => {
    const form = createRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [templates, updateTemplates] = useState([]);
    const [activeTemplate, updateActiveTemplate] = useState({assetType: '', categoryName: ''});

    const [modalStyle] = useState(getModalStyle);
    

    const { categories } = useSelector((state) => state.assetCategories);
    const [nameTaken, updateNameTaken] = useState(false);
    const [editCategory, updateCategory] = useState(initCategory);
    const [submitting, updateSubmitting] = useState(false);



    const handleCategoryChange = (newValue) => {
        // Make sure that the key is not already taken
        if (Object.keys(newValue).find(x => x === 'assetType')) {                                    
            const myValue = newValue['assetType'].replace(/\s+/g, '-').toLowerCase();
            const isTaken = categories.find(x => x.assetType === myValue);
            updateNameTaken(isTaken ? true : false);
            updateCategory({...editCategory, assetType: myValue });
        } else {
            updateCategory({...editCategory, ...newValue });
        }        
    }

    const handleTemplateChange = (e) => {
        const myTemplate = templates.find(x => x.assetType === e.target.value);
        updateCategory(myTemplate);
        updateActiveTemplate(myTemplate);

      }    
  
    const handleSubmit = async () => {
        const isTaken = categories.find(x => x.assetType === editCategory.assetType);
        updateNameTaken(isTaken ? true : false);
        if (form.current.reportValidity() && !nameTaken) {
            updateSubmitting(true);
            const { assetType: templateName } =  activeTemplate;

            try {
                const myNewCategory = await addCategoryByTemplate(templateName, { ...editCategory});                
                dispatch({ type: ADD_ASSET_CATEGORY_SUCCESS, payload: myNewCategory});
                updateSubmitting(false);
                reportBack(myNewCategory.assetType);
            } catch (err) {
                updateSubmitting(false);
                dispatch({ type: ADD_ASSET_CATEGORY_FAILURE, payload: err.message});
            }        
        }
    }

    const fetchassetTypes = async () => {
        const templateOptions = await getCategoryTemplates();
        updateTemplates(templateOptions);
    }

    useEffect(() => {        
        fetchassetTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  



    return (
        <Card >
        <div style={modalStyle} className={classes.paper}>
        <CardContent>
        <Typography variant="h6" color="textSecondary" component="div" gutterBottom>
                Choose Template
            </Typography>                
        <FormControl className={classes.selectStyle} >
            <TextField
                label="Template"
                name="templateName"
                onChange={handleTemplateChange}
                select
                value={activeTemplate.assetType || ''}
                variant="outlined"
                required
            >              
                {templates && templates.map((template) => (
                <MenuItem key={template.assetType} value={template.assetType}>
                    {template.categoryName}
                </MenuItem>
                ))}              
          </TextField>
        </FormControl>

    </CardContent>
    <form ref={form} onSubmit={e => e.preventDefault()}>
        <CardContent>
          <Box display="flex" spacing={3} flexDirection="column" justifyContent="space-around" alignItems="flex-start">
          <InfoDynamic
              fieldType='text'
              header='Display Name'
              isRequired={true}
              fieldName='categoryName'
              fieldValue={editCategory.categoryName || ""}
              disabled={!activeTemplate.categoryName}
              editMode={true}
              handleChange={handleCategoryChange}
        />      

      <InfoDynamic
        fieldType='text'
        header='Unique Key (cannot change)'
        fieldName='assetType'
        isRequired={true}
        fieldValue={editCategory.assetType || ""}            
        error={nameTaken}
        helperText="Incorrect entry."
        disabled={!activeTemplate.categoryName}
        editMode={true}
        handleChange={handleCategoryChange}
      />
      {nameTaken && (
          <Typography className={classes.badInput}>Key is already in use.</Typography>                   
      )}
          </Box>
    </CardContent>
    <CardContent>
        <Box display="flex" spacing={3} flexDirection="row" justifyContent="space-around" alignItems="center">
                  <Button size="small" fullWidth className="button-list" color="primary" disabled={submitting || nameTaken || !activeTemplate.categoryName} onClick={() => handleSubmit()} variant="outlined">Save</Button>
                  <Button size="small" fullWidth className="button-list" onClick={cancelAdd} variant="outlined">Cancel</Button>
        </Box>    

            
    </CardContent>            
        </form>                          
    </div>
    </Card>
    )
}

export { NewCategory };