import React, { useState } from 'react';
import { 
    Box,
    Divider,
    Avatar,
    Grid 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { AssetNote } from '.';
import { 
    useSelector,
    useDispatch,
    shallowEqual
  } from 'react-redux';
import { 
    addNote,
    updateNote,
    deleteNote
} from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {  
        '& > *': {
        margin: theme.spacing(1),
        },      
    },    
    secondaryButton: {
        backgroundColor: theme.palette.primary.main,
        hover: {
            backgroundColor: theme.palette.accent.dark,        
        }  
    }
}));


const AssetNotes = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [addMode, updateAddMode] = useState(false);    
    const [recordId, updateRecordId] = useState();
           
    const { asset } = useSelector((state) => state.asset, shallowEqual); 
    const { user } = useSelector((state) => state.auth, shallowEqual); 
    
    const initNewRecord = {
        dateAdded: new Date(),  
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        note: '',
        attachments: []
    }

    const toggleAddMode = () => {        
        updateAddMode(!addMode);
    }


    
    const handleNewSubmit = (myRecord) => {
            dispatch(addNote(asset._id, myRecord));
            updateAddMode(false);
      }

      const handleEditSubmit = (newValue) => {          
          dispatch(updateNote(asset._id, newValue));
          updateRecordId();
    }
    const deleteRecord = (noteId) => {
        dispatch(deleteNote(asset._id, noteId));
    }   


    // const ConditionalWrapper = ({ condition, wrapper, children }) => 
    // condition ? wrapper(children) : children;

    return (
        <>
         <Grid
            container
            direction="row"
            justifyContent="flex-end"
            component="div"            
            >                

            <Box pr={1}></Box>

            {!addMode && (
                <Avatar
                    onClick={toggleAddMode}                    
                    className={classes.secondaryButton}>
                    <AddIcon />
                </Avatar>               
            )}
        </Grid>
        
        <Box pt={1} pb={3}>
            <Divider />
        </Box>

        {addMode &&  (
                <Box pb={3}>
                    <AssetNote
                        record={initNewRecord}                                
                        editMode={true}
                        handleCancel={() => updateAddMode(false)}
                        handleSubmit={handleNewSubmit}
                        handleDelete={() => false}
                    />                  
                </Box>
        )}
        
        {asset && asset.notes && asset.notes.map(record => (
            <Box pb={3} key={record._id} onDoubleClick={() => {updateRecordId(record._id)}}>

                <AssetNote                                
                    record={record}                                
                    editMode={(recordId === record._id) || false}
                    handleCancel={() => updateRecordId()}
                    handleSubmit={handleEditSubmit}
                    handleDelete={() => deleteRecord(record._id)}
                />         
            </Box>
        ))}
        </>
    )
}

export { AssetNotes };