import { InfoText, InfoEdit, EditDateField, EditCurrencyField, SelectField, SelectObjectField,  UserList, TextAreaEdit, SwitchField } from './';
const InfoDynamic = (props) => {
    const { header, fieldType='text', fieldValue, editMode } = props;
    return (
        <>
        {editMode ? (
            <>
            {(() => {
                switch (fieldType) {
                    case 'date':
                        return (        
                            <EditDateField {...props} />
                        )
                    case 'currency':
                        return (        
                            <EditCurrencyField {...props} />
                        )
                    case 'select':
                        return (        
                            <SelectField {...props} />
                        ) 
                    case 'selectObject':
                        return (        
                            <SelectObjectField {...props} />
                        )                         
                    case 'userDirectory':
                        return (        
                            <UserList {...props} />
                        )
                    case 'textArea':
                        return (
                            <TextAreaEdit {...props} />
                        ) 
                    case 'email':
                        const emailProps = {...props, validationType: 'email'};
                        return (        
                            <InfoEdit {...emailProps} />
                        )
                    case 'number':
                        const numberProps = {...props, validationType: 'number'};
                        return (        
                            <InfoEdit {...numberProps} />
                        )
                    case 'switch':                        
                        return (        
                            <SwitchField {...props} />
                        )                        
                    default:
                        return (        
                            <InfoEdit {...props} />
                        )
                }
            })()}
            </>
        ) : ( 
            <InfoText
                header={header}
                fieldType={fieldType}
                value={fieldValue} />
        )}
        </>
    )
}

export { InfoDynamic };