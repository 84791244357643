import React from 'react';
import { 
    useDispatch ,
    useSelector   
  } from 'react-redux';

import {
    Box,
    Divider,
    Grid,
    Typography,
    Icon
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { addMDMPlatform, deleteMDMPlatform, enableMdm, disableMdm } from "../../store/actions";
import { InfoDynamic } from "../Common"

const platformOptions = { 
    kandji: ['Mac', 'iPhone', 'iPad'],
    jamf: ['Mac', 'iPhone', 'iPad'] 
};

const CategoryMdm = ({ assetType,  }) => {
    const dispatch = useDispatch();
    const  category = useSelector((state) => state.assetCategories.categories.find(x => x.assetType === assetType));
    const  { categories } = useSelector((state) => state.assetCategories);
    const { user } = useSelector((state) => state.auth);
    const updatePlatform = ({ provider, platform, newStatus }) => {
        if (newStatus) {
            dispatch(addMDMPlatform({ assetType, mdmProvider: provider, platform }));
        } else {
            dispatch(deleteMDMPlatform({ assetType, mdmProvider: provider, platform }));
        }
    }

    const toggleUseMdm = (value) => {
        if (value.useMdm) {
            dispatch(enableMdm({assetType}))
        } else {
            dispatch(disableMdm({assetType}))
        }
    }

    const MDMPlatforms = ({ provider }) => {
        // platforms can only be applied to one category
       const usedPlatforms = categories.filter(x => x.assetType !== assetType).reduce((memo, item) => { 
           const taken = (item.mdmProviders && item.mdmProviders[provider]) || [];
           return [...memo, ...taken]
       }, [])
       
        const platformStatus = platformOptions[provider].reduce((memo, platform) => {
            const inUse = usedPlatforms.includes(platform);
            const isEnabled = category.mdmProviders && category.mdmProviders[provider] ? category.mdmProviders[provider].includes(platform) : false;
            return [...memo, {platform, isEnabled, inUse}];
        }, []);        
        
        return (
            <div>
                <Typography variant="button">{provider}</Typography>
            {platformStatus.map((platform, index) => (
                <Box key={index} display="flex" flexDirection="row" alignItems="center">
                    {platform.inUse ? (
                        <Icon color="secondary">disabled_by_default</Icon>
                    ) : (
                    <div onClick={() => updatePlatform( {provider, platform: platform.platform, newStatus: !platform.isEnabled}) }><Icon>{platform.isEnabled ? 'check_box' : 'check_box_outline_blank'}</Icon></div>
                    )} 
                    <div><Typography variant="caption">{platform.platform}</Typography></div>
                </Box>
            ))}
            </div>
        )
    }

    return (
        <>
   
      <Box display="flex">
        <Box mt={4} flexGrow={1}>
            <Typography variant="h6">MDM Provider Integration</Typography>
        </Box>
        <Box>

        <InfoDynamic
                    fieldType='switch'
                    header=''
                    fieldName='useMdm'
                    fieldValue={ (category.useMdm === true) }
                    editMode={true}
                    handleChange={toggleUseMdm}
                />
        </Box>
      </Box>
    
        <Divider />
        {category && category.useMdm && (
            <>
        
            <Alert severity="info">Attention: Platforms can only be assigned to one category.</Alert>           
            <Box pt={3} />
            <Grid container spacing={2}>
                <>            
                {Object.keys(user.integrationProviders).filter(x => user.integrationProviders[x] === 'mdm').map(item => (
                    <Grid key={item} item md={4} sm={4} xs={12}>
                        <MDMPlatforms  provider={item} />                    
                    </Grid>
                ))} 
                </>            
            </Grid>
            </>
        )}
      </>  
    );
}


export {
    CategoryMdm
};