import React, { useState, createRef } from 'react';
import { InfoEdit } from './';
import { 
    Button,
    Box,    
} from '@material-ui/core';  
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    invalidButton: {
      color: '#fff',
      backgroundColor: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.dark,
      },      
    },
    successButton: {
        color: '#fff',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },      
      },    
  }));

const UploadFile = ({ handleUpload, documentType='invoice' }) => { // Returns FormData Object
    const classes = useStyles();
    const [file, setFile] = useState();
    const [noFileError, setNoFileError] = useState(false);
    const [formFields, updateFormFields] = useState({description: '', customName: '', documentType});
    const form = createRef();

    const handleChange = (newValue) => {
        updateFormFields({...formFields, ...newValue });        
    }
    const handleFileChange = (e) => {
        setNoFileError(false);
        setFile(e.target.files[0]);
        if (!formFields.customName) {
            updateFormFields({...formFields, customName: e.target.files[0].name})
        }
    }    

    const handleSubmit = () => {
        if (!file) {
            setNoFileError(true);
        }
        if (form.current.reportValidity() && file) {
            const formData = new FormData();             
            formData.append('file', file);
            formData.append('description', formFields.description);
            formData.append('customName', formFields.customName);
            formData.append('documentType', formFields.documentType);
            handleUpload(formData)
        }        
    }

    return (
        <form ref={form} onSubmit={e => e.preventDefault()}>
            <Box display="flex">
                <Box width="100%">
                    <InfoEdit 
                        header='Name'
                        fieldName='customName'
                        fieldValue={formFields.customName}
                        editMode={true}
                        isRequired={true}
                        handleChange={handleChange}
                    />
                </Box>                
                <Box pl={3} pt={3} width={200}>
                    <Button
                        fullWidth
                        className={noFileError ? classes.invalidButton : file ? classes.successButton : ''}
                        variant="contained"
                        component="label"
                        onChange={handleFileChange}                     
                    >
                    {file ? (
                        <CheckIcon />

                    ) : (
                        <span>File</span>
                    )}                           
                        <input
                            type="file"                    
                            hidden
                        />
                    </Button>
                </Box>
            </Box>    

            <Box display="flex" >
                <Box width="100%">
                    <InfoEdit 
                        header='description'
                        fieldName='description'
                        fieldValue={formFields.description}
                        editMode={true}
                        isRequired={true}
                        handleChange={handleChange}
                    />
                </Box>                
                <Box pl={3} pt={3} width={200}>
                    <Button
                        fullWidth
                        // disabled={() => {form.current.checkValidity()}}
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={handleSubmit}
                    >
                    Submit
                    </Button>
                
                </Box>
            </Box>    

        </form>
    );
}

export { UploadFile };