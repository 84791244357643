import { differenceBy } from 'lodash';
const backfillCustomFields = (categoryCustomFields, existingCustomFields) => {
    const safeCategoryCustomFields = Array.isArray(categoryCustomFields) ? categoryCustomFields : [];      
    const safeExistingCustomFields = Array.isArray(existingCustomFields) ? existingCustomFields : [];
  
    // changes to category custom fields should cascade to pre-existing devices and templates, including order
    // map the incoming values to key value pairs to make them easy to access
    const existingValues = safeExistingCustomFields.reduce((memo, item) => {
        return { ...memo, [item.fieldName]: item.fieldValue };
     }, {});
 
    const customFields = safeCategoryCustomFields.map(item => ({...item, 'fieldValue': existingValues.hasOwnProperty(item.fieldName) ? existingValues[item.fieldName] : item[item.fieldValue] }));
    
    const uniqueFields = differenceBy(safeExistingCustomFields, safeCategoryCustomFields, 'fieldName');
  
    customFields.concat(uniqueFields);
    return customFields;
  };

  export {
      backfillCustomFields
  };