import {
    TextField,
} from '@material-ui/core';

const InfoEdit = (props) => {
    const { header, fieldName, fieldValue, disabled=false, isRequired=true, handleChange, validationType='text', error=false } = props;
    const handleCustomChange = (e) => {
        handleChange({ [e.target.name]: e.target.value });
    }
    return (
            <TextField            
                fullWidth
                disabled={disabled}                
                InputLabelProps={{ shrink: true }}
                label={header}
                margin="normal"
                name={fieldName}
                onChange={handleCustomChange}
                required={isRequired}
                type={validationType}
                error={error}
                value={fieldValue || ''}
                />
    )
}


export { InfoEdit };