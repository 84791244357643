import React, { useEffect, useState } from 'react';
import { 
    useSelector,
    useDispatch,
    shallowEqual
  } from 'react-redux';

import {  
  useParams,
  useNavigate,
  Link
} from "react-router-dom";

import { 
    Icon,
    Box,
    Button,
    Grid,
    Paper,
    makeStyles,
    Snackbar,
    Slide,
    Tabs,
    Tab, 
} from '@material-ui/core';  
import {
  Alert,
  TabPanel,
  TabContext }
 from '@material-ui/lab';
 
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CommentIcon from '@material-ui/icons/Comment';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { getDevice, deleteAsset } from '../../store/actions';
import { Loading } from '../Common';
import { AssetOverview, AssetOwnership, AssetInvoices, AssetNotes } from './';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,    
    // backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),    
    color: theme.palette.text.secondary,
  },
  infoPanel: {
    width: '100%',    
    backgroundColor: theme.palette.primary,
  },
  content: {
    padding: theme.spacing(3),
},

}));


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const AssetProfile = (  ) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { asset, isLoading, error } = useSelector((state) => state.asset, shallowEqual);
  const { categories } = useSelector((state) => state.assetCategories);
  const { assetId } = useParams();
  const [category, updateCategory] = useState({name: '', icon: '', customFields:[] });
  const [snackbarState, updateSnackbarState] = useState({
    open: false,
    Transition: SlideTransition,
  });
  const [deleting, updateDeleting] = useState(false);
  const [confirmDelete, updateConfirmDelete] = useState(false);
  const [tabIndex, setTabIndex] = useState("0");
  
  
  const handleClose = () => {
    updateSnackbarState({ ...snackbarState, open: false });
  };
  
  const findActiveCategory = () => {
    if (Array.isArray(categories) && categories.length) {
        const activeCategory = categories.find(x => x.assetType === asset.assetType);
        updateCategory(activeCategory);
    }
  }

  useEffect(() => {
    if(error) {
      updateSnackbarState({ ...snackbarState, open: true });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);  


  useEffect(() => {
    if(asset) {
      findActiveCategory();
    }    
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);      

  
  useEffect(() => {
      dispatch(getDevice(assetId));
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 


  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const handleDelete = async () => {
    
    
        updateDeleting(true);

        try {
            await deleteAsset(assetId);                        
            navigate(`../../`, { relative: 'path'});
        } catch (err) {
            updateDeleting(false);
            updateSnackbarState({ ...snackbarState, open: true });            
        }    
  } 

  return (
      <div className={classes.content}>
        <Snackbar
              autoHideDuration={3000}
              onClose={handleClose}
              TransitionComponent={snackbarState.Transition}
              open={snackbarState.open}
              
              message="Unknown error"
              key={snackbarState.Transition.name}
              >
              <Alert onClose={handleClose} severity="error">
                  {error || 'Unknown error'}
              </Alert>
          </Snackbar>

      <h2>Category: {category && category.categoryName}</h2>

            <Box display="flex"                    
                justifyContent="flex-end"
                direction="row"
                component="div"      
            >

            {/* <Link to='../..'  relative="path">Back</Link> */}
             <Box flexGrow={1}>
             <Button 
                size='small'
                variant="outlined"
                color="default"
                startIcon={<KeyboardArrowLeftIcon />}
                >
                <Link to='../..'  relative="path">Back</Link>
            </Button>

            </Box>
          {confirmDelete ? (
          <>
            <label htmlFor="confirm-delete-asset-button">
                <IconButton 
                  onClick={handleDelete}
                  disabled={deleting}
                  color="secondary" aria-label="confirm delete asset">
                <DeleteForeverIcon />
              </IconButton>
            </label>
            <label htmlFor="cancel-delete-asset-button">
                <IconButton 
                  onClick={() => updateConfirmDelete(false)}
                  disabled={deleting}
                  color="default" aria-label="cancel delete asset">
                <ClearIcon />
              </IconButton>
            </label>                   
          </>        
            ) : (
            <label htmlFor="delete-asset-button">
                <IconButton 
                  onClick={() => updateConfirmDelete(true)}
                  disabled={deleting}
                  color="primary" aria-label="delete asset">
                <DeleteIcon />
              </IconButton>
            </label>          
            )}

        </Box>

      
      <div className={classes.root}>


      
      {/* <h1>{asset && asset.assetType && asset.assetType}</h1> */}



      {isLoading && (
        <Loading />
      )}
      
      {!isLoading && (asset && asset._id) ? (

      <Grid container spacing={3}>
                
        {/* <Grid item sm={12} md={12}>            
            <Paper className={classes.paper}>
              <AssetOverview asset={asset} />
            </Paper>
        </Grid>
 */}

        
        <Grid item sm={12} md={12}>


            

        <div className={classes.root}>
   <Paper square>
            
        <TabContext value={tabIndex}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="fullWidth"
            centered
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            {/* <Tab icon={() => category && category.icon ? <Icon>{category.icon}</Icon> : <Icon>details_icon</Icon>} label="Overview" /> */}
            <Tab value="0" icon={<Icon>{category && category.icon ? category.icon : 'details_icon'}</Icon>} label="Overview" />
            <Tab value="1" icon={<PersonPinIcon />} label="Assignments" />
            <Tab value="2" icon={<AttachFileIcon />} label="Attachments" />
            <Tab value="3" icon={<CommentIcon />} label="Notes" />
          </Tabs>
          <TabPanel value="0">
            <AssetOverview asset={asset} />
          </TabPanel>          
          <TabPanel value="1">
              <AssetOwnership />
          </TabPanel>                    
          <TabPanel value="2">
              <AssetInvoices />
          </TabPanel>         
          <TabPanel value="3">
              <AssetNotes />
          </TabPanel>      
        </TabContext>
        </Paper>
      </div>

{/* 
            <div className={classes.root}>

            <TabContext value={tabIndex}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Item One" value="1" />
            <Tab label="Item Two" value="2" />
            <Tab label="Item Three" value="3" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <AssetOwnership />
        </TabPanel>
        <TabPanel value="2"><AssetInvoices /></TabPanel>
        <TabPanel value="3"><AssetNotes /></TabPanel>
      </TabContext>
      </div> */}

{/* 
        <AppBar position="static">
          <Tabs
            value={tabIndex}

            onChange={handleChange}
            variant="fullWidth"
            aria-label="Asset Profile Options"
            
          >
            <Tab label="Assignments" />
            <Tab label="Invoices" />
            <Tab label="Notes" />
          </Tabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
              <AssetOwnership />
          </TabPanel>          
          <TabPanel value={tabIndex} index={1}>
            <AssetInvoices />
          </TabPanel>         
          <TabPanel value={tabIndex} index={2}>
            <AssetNotes />
          </TabPanel>                              
         */}
        </Grid>        

          {/* <h2>Add File</h2>
          <UploadFile handleUpload={uploadFile} />
           */}
      


      </Grid>
            ) : (
              <div>{error}</div>
            )}

      </div>      
      </div>
  )
}

export {
    AssetProfile
};