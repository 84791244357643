import NumberFormat from 'react-number-format'; 
import moment from 'moment';

const DynamicFormat = (props) => {
    const {  fieldType='text', fieldValue } = props;    
    return (
        <>
            {(() => {            
                switch (fieldType) {
                    case 'date':
                        return (
                            <>
                            {fieldValue ? moment(fieldValue).local().format('MMM, DD YYYY') : '\u00A0'}
                            </>
                        )
                    case 'currency':
                        return (        
                            <NumberFormat 
                                value={fieldValue}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                />
                        )
                    case 'number':                        
                        return (        
                            <NumberFormat 
                            value={fieldValue || '0'}
                            displayType={'text'}
                            thousandSeparator={true}                            
                            />
                        )
                   
                    default:
                        return (        
                            fieldValue
                        )
                }
            
            })()}

        </>
    )
}

export { DynamicFormat };