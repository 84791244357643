import React, { useState, createRef, useEffect } from 'react';
import { 
    Box,
    Divider,
    Button,    
    Card,
    CardContent,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import { AssetOwnershipRecord, AssetDispose } from './';

import { 
    useSelector,
    useDispatch,
    shallowEqual
  } from 'react-redux';

import { 
    getUserDirectory,
    addOwnership,
    updateOwnership,
    deleteOwnership
} from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {  
        '& > *': {
        margin: theme.spacing(1),
        },      
    },
    button: {
        margin: theme.spacing(.5),
    },
    inactiveRecord: { cursor: 'pointer' },
    activeRecord: { backgroundColor: '#F8F6F6' },
    activeRecordHeader: { backgroundColor: '#E0E0E0' }
}));

// const initNewRecord = {
//         ownerType: '',
//         ownerKey: '',
//         ownerDisplay: '',        
//         dateAssigned: '',
//         // dateReturned: '',
//         startingCondition: '',
//         // returnCondition: '',
//         attachments: []
// }

const AssetOwnership = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const form = createRef();
    const editForm = createRef();
      
    const [addMode, updateAddMode] = useState(false);
    const [newRecord, updateNewRecord] = useState();
    const [editRecord, updateEditRecord] = useState();

    const [openDispose, updateOpenDispose] = useState(false);
        
    const { asset } = useSelector((state) => state.asset, shallowEqual);
    const disableNewOwner = Array.isArray(asset.ownership) ? asset.ownership.find(x => !x.dateReturned) : 0;
    
    useEffect(() => {
        dispatch(getUserDirectory());
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 
  

    const toggleAddMode = () => {        
        // updateEditMode(false);
        updateAddMode(!addMode);
        updateEditRecord();
        updateNewRecord();
        updateOpenDispose(false);
    }
    const triggerAssignMode = (ownerType) => {
        updateEditRecord();
        updateOpenDispose(false);
        updateAddMode(true);
        if (!disableNewOwner) {
            updateNewRecord({ ownerType });
        }
    }

    const triggerEditRecord = (record) => {
        updateAddMode(false);
        updateOpenDispose(false);
        updateEditRecord(record);
    }

    const handleNewValueChange = (newValue) => {
        updateNewRecord({...newRecord, ...newValue });
    }

    const handleEditRecordChange = (newValue) => {
        updateEditRecord({...editRecord, ...newValue });
    }    
    

    const handleSubmit = () => {
        if(form.current.reportValidity() && newRecord.dateAssigned) {            
            dispatch(addOwnership(asset._id, newRecord));
            updateNewRecord( );
            updateAddMode(false);
        }
      }
    
    const handleEditSubmit = () => {
        if(editForm.current.reportValidity()) {            
            dispatch(updateOwnership(asset._id, editRecord));
            updateEditRecord();
        }
    }
    const deleteRecord = (ownershipId) => {
        dispatch(deleteOwnership(asset._id, ownershipId));
    }   

    const ConditionalWrapper = ({ condition, wrapper, children }) => 
    condition ? wrapper(children) : children;

    const assignOptions = ['User', 'Storage', 'Transit'];
    return (
        <>
            <Box pr={1}></Box>
            

                    <Box display="flex"                    
                        justifyContent="flex-end"
                        direction="row"
                        component="div"      
                    >
                     <Box flexGrow={1}>
                    <Button
                        variant={ openDispose ? "contained" : "outlined" }
                        color="secondary"
                        size="small"
                        disabled={(addMode || !!editRecord || !!asset.dateDisposed)}
                        onClick={()=>updateOpenDispose(!openDispose)}
                        className={classes.button}                        
                    >Dispose </Button>
                    </Box>

                    {assignOptions.map((assignOption, index) => (
                        <Button
                        key={index}
                        variant={newRecord && newRecord.ownerType === assignOption ? "contained" : "outlined"}
                        color="primary"
                        disabled={(!!asset.dateDisposed || !!editRecord || openDispose || (newRecord && newRecord.ownerType !== assignOption))}
                        size="small"
                        onClick={()=>triggerAssignMode(assignOption)}
                        className={classes.button}                        
                        >{assignOption}</Button>
                    ))}
                            
                </Box>



        
        <Box pt={1} pb={3}>
            <Divider />
        </Box>

        
        {!disableNewOwner && (asset && !asset.dateDisposed) && (
            <Box pb={3}>
                <Alert severity="info">Asset "{asset.assetName}" is in limbo.</Alert>
            </Box>
        
        )}

        <AssetDispose newEntry={openDispose} handleCancel={()=>updateOpenDispose(false)} />

        {addMode && !disableNewOwner && (            
            <form ref={form} onSubmit={e => e.preventDefault()}>
                <Box pb={3}>                
                    <Card className={classes.activeRecord}>
                        <CardContent>                 
                            <AssetOwnershipRecord record={newRecord} editMode={true} handleNewValueChange={handleNewValueChange} />
                        </CardContent>
                        <CardContent className={classes.activeRecordHeader}> 
                        <Box display="flex" flexDirection="row" alignItems="space-between">
                            <Box flexGrow={1}>
        
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}                        
                                onClick={handleSubmit}
                            >Save</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={toggleAddMode}
                                className={classes.button}                        
                            >Cancel</Button>                            
                        </Box>
                    </CardContent>

                    </Card>            
                </Box>                            
            </form>
        )}
        
        {addMode && disableNewOwner && (
            <Alert severity="error">{asset.assetType} must be returned before it can be assigned</Alert>
            )}


        {asset && asset.ownership && asset.ownership.map(record => (
            <Box pb={3} key={record._id}>                
            <Card>
                <CardContent onDoubleClick={() => {triggerEditRecord(record)}} className={(editRecord && editRecord._id === record._id) ? classes.activeRecord : classes.inactiveRecord}> 
                    <ConditionalWrapper
                        condition={editRecord && editRecord._id === record._id}
                        wrapper={children => <form ref={editForm} onSubmit={e => e.preventDefault()}>{children}</form>}
                    >
                        <AssetOwnershipRecord 
                            key={record._id}
                            record={(editRecord && editRecord._id === record._id) ? editRecord : record}
                            editMode={(editRecord && editRecord._id === record._id) || false}
                            handleNewValueChange={handleEditRecordChange} 
                        />
                    </ConditionalWrapper>            
                </CardContent>

                {editRecord && editRecord._id === record._id && (
                    <CardContent className={classes.activeRecordHeader}> 
                        <Box display="flex" flexDirection="row" alignItems="space-between">
                            <Box flexGrow={1}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    className={classes.button}                        
                                    onClick={() => deleteRecord(record._id)}
                                >Delete</Button>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}                        
                                onClick={handleEditSubmit}
                            >Save</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => updateEditRecord()}
                                className={classes.button}                        
                            >Cancel</Button>                            
                        </Box>
                    </CardContent>
                )}

            </Card>
            
            </Box>
        ))}
        </>
    )
}

export { AssetOwnership };



