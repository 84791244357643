import React from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import {
    ThemeProvider
  } from '@material-ui/core/styles';

import { Dashboard, Devices, AddDevice, AssetProfile, MiniDrawer, CategoriesAdmin, Categories, Category, NewCategory, AssignmentList, Login, NotFound } from './components';
import { Users } from './components/Admin';
import { theme } from './theme';

function App() {

  let routes = createBrowserRouter([
    {
      path: "/",
      element: <MiniDrawer />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "/login", exact: true, element: <Login /> },
        { path: "/admin/users", element: <Users /> },
        { path: "/assignments", element: <AssignmentList /> },
        { path: "/category/:assetType/asset/:assetId", element: <AssetProfile /> },
        { path: "/category/:assetType/asset/add", element: <AddDevice /> },
        { path: "/category/:assetType", element: <Devices />},          
        {
          path: "/admin/categories",
          element: <CategoriesAdmin />,
          children: [
            { index: true, element: <Categories/>},
            { path: "add", element: <NewCategory isNew={true} />},
            { path: "category/add", element: <Category isNew={true} />},
            { path: "category/:assetType", element: <Category />},
          ],
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

    return (
        <ThemeProvider theme={theme}>
          <RouterProvider router={routes} />
        </ThemeProvider>
      );
}

export default App;