
import {    
    FETCH_USER_ASSETLIST_REQUEST,
    FETCH_USER_ASSETLIST_SUCCESS,
    FETCH_USER_ASSETLIST_FAILURE,
} from '../actions';

const initialState = 
{
    isLoading: false,
    error: '',    
    users: []
};

const userAssetsReducer = ((state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_ASSETLIST_REQUEST:            
            return { ...state, isLoading: true, error: '' };
            case FETCH_USER_ASSETLIST_SUCCESS:
                return { ...state, users: action.payload, isLoading: false, error: '' };        
        case FETCH_USER_ASSETLIST_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
});

export default userAssetsReducer;