import React, { useState, createRef } from 'react';
import { 
    useDispatch,
    useSelector
  } from 'react-redux';

  import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExtensionIcon from '@material-ui/icons/Extension';

import { CustomField } from '../Common';
import { toCamelCase } from '../../utils/toCamelCase';
import {
    addAssetCategoryCustomField,
    updateAssetCategoryCustomField,
    deleteAssetCategoryCustomField
   } from '../../store/actions';
   
const CustomFields = ( { categoryId, showHeader=false }) => {
    const dispatch = useDispatch();
    const form = createRef();
    const  category = useSelector((state) => state.assetCategories.categories.find(x => x._id === categoryId));
    const [editCustomIndex, updateEditCustomIndex] = useState();

    const newCustomField = {
        fieldDisplay: "",
        fieldName: "",
        fieldType: "text",
        displayLength: "half",
        fieldValue: "",
        required: true
    }

    const handleAddFieldSave = (index, newValue) => {
        if (form.current.reportValidity()) {
            const fieldName = toCamelCase(newValue.fieldDisplay);
            if (category.customFields.find(x => x.fieldName === fieldName)) {
                alert('Field is already in use.');
            } else {
                dispatch(addAssetCategoryCustomField({ categoryId, customField: {...newValue, fieldName } }));
                updateEditCustomIndex();
            }
        }
    }

    const handleCustomFieldSave = (index, newValue) => { 
        
        dispatch(updateAssetCategoryCustomField({ categoryId, customField: newValue }));
        updateEditCustomIndex();
        // if (!isNew) {
        // } else {
        //   const newCustomFields = editCategory.customFields.map((item, cfIndex) => {
        //     if (cfIndex === index) {
        //       return {...item, ...newValue};
        //     } else {
        //       return item;
        //     }
        //   })        
        //   updateCategory({...editCategory, customFields: newCustomFields });
        //   // handleSubmit();
        // }
      }

    
  
    const deleteCustomField = (field) => {
        // Delete if it is persisted in the database, or remove from the temp array of custom fields
        if (field.hasOwnProperty('_id')) {
          dispatch(deleteAssetCategoryCustomField( { categoryId: category._id, customFieldId: field._id}));
        } else {
        //   const { index } = field;
        //   const newCustomFields = editCategory.customFields.filter((x, xIndex) => { return index !== xIndex });
        //   updateCategory({...editCategory, customFields: newCustomFields });
        }
      } 
    return (
        <form ref={form} onSubmit={e => e.preventDefault()}>

          <Box display="flex">
            <Box flexGrow={1}>
                  <Typography variant="h6">Custom Fields</Typography>
                </Box>                     
            <Button onClick={() => updateEditCustomIndex('new')} size="small" disabled={editCustomIndex} variant="outlined">Add</Button>            
          </Box>

          <Box pt={1} />
          <Divider />    
          
          <Box pt={2} />


        <Grid container spacing={2}>
        <div style={{ width: '100%' }}>
       
            {editCustomIndex === 'new' && (
                <CustomField
                    categoryField={newCustomField}
                    editMode={true}
                    index='new'
                    deleteCustomField={deleteCustomField}
                    handleSave={handleAddFieldSave}
                    handleCancel={() => updateEditCustomIndex()}
                />
            )}
            <List dense={false}>
              {category && category.customFields && category.customFields.map((myField, myFieldIndex) => (
                <React.Fragment key={myFieldIndex}>
                <ListItem >
                  

                  {editCustomIndex === myFieldIndex ? (
                    <ListItemText
                        disableTypography={true}
                        secondary={
                            <CustomField
                              categoryField={myField}
                              editMode={true}
                              index={myFieldIndex}
                              deleteCustomField={deleteCustomField}
                              handleSave={handleCustomFieldSave}
                              handleCancel={() => updateEditCustomIndex()}
                            />
                    }
                    />
                  ) : (
                    <>
                  <ListItemAvatar>                    
                      <ExtensionIcon color='secondary'/>                    
                  </ListItemAvatar>                    
                    <ListItemText
                    primary={myField.fieldDisplay}
                    
                    />              
                    <ListItemSecondaryAction onClick={() => updateEditCustomIndex(myFieldIndex) }>
                      <IconButton edge="end" aria-label="delete">
                        <ChevronRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                    </>
                  )}
                </ListItem>
                <Divider variant="inset" component="li" />
                </React.Fragment >
              ))}
            </List>

        </div>
       </Grid>
       </form>
    )
}
export { CustomFields };