import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Paper, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  boxContentNumber: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  paperHeading: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2)
  },
  paperHeadingTitle: {
    marginRight: theme.spacing(0.5)
  },
  warningIcon: {
    color: '#ff9800',
    fontSize: '56px'
  }
}));

const DashboardBox = ({ content, headingIcon, headingTitle, path }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={2} square>
      <div className={classes.paperHeading}>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"            
        >        
          <Typography
            className={classes.paperHeadingTitle}
            component="h3"
            variant="body1"
          >
            {headingTitle}
          </Typography>          
          {headingIcon}          
        </Grid>
      </div>
      <div className={classes.paperContent}>        
        <Container className={classes.boxContent}>
          <Typography className={classes.boxContentNumber} variant="h3">
            {(content && content.numOfResources) || (
              0
            )}
          </Typography>
          <Typography variant="body2">
            {content && content.resourceText && content.numOfResources > 0
              ? content.resourceText
              : `${content.resourceText}`}
          </Typography>
        </Container>
        <Button
          aria-label="Edit"
          className={classes.button}
          color="primary"
          variant="contained"
          component={RouterLink} to={content.path}
        >
          View
        </Button>
      </div>
    </Paper>
  );
};

export {
    DashboardBox
};