import { makeStyles } from '@material-ui/core/styles';

  import {
  Outlet,
} from "react-router-dom";   


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },    
    content: {
      padding: theme.spacing(3),    
    },
    fontSizeXLarge: {
        fontSize: theme.typography.pxToRem(64),
    },    
  }));
const CategoriesAdmin = () => {
    const classes = useStyles();
    
    return (
        <div className={`${classes.content}`} >
          {/* <div className={`fadeIn`}> */}
            
              <Outlet />
          {/* </div> */}
          </div>
    )
}

export {
  CategoriesAdmin
}