import { API_URL } from '../../env';
import { axiosWithAuth } from '../../utils';

export const SET_GOOGLE_TOKEN = 'SET_GOOGLE_TOKEN';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const logoutUser = () => async (dispatch) => {
  // const { hostname } = new URL(API_URL);
  // document.cookie =`${AUTH_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${hostname}; path=/;`;
  // localSessionManager.logout()
  
    dispatch({ type: LOGOUT_REQUEST });          
    try {
      const url = `${API_URL}/api/v1/auth/logout`;
      await axiosWithAuth().get(url);
      dispatch({ type: LOGOUT_SUCCESS });            
    } catch (err) {
      alert('Unable to log out user');      
      // dispatch({ type: LOGIN_FAILURE, payload: err.message });          
    }
};

  export const getProfile = () => async (dispatch, getState) => {      
    const { user } = getState().auth;
    if (user && user.accountName) {
      return false;
    }
    dispatch({ type: USER_PROFILE_REQUEST });
    try {
        const url = `${API_URL}/api/v1/me`;
        const response = await axiosWithAuth().get(url);
        const { data } = response;
        dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
      } catch (err) {
        dispatch({ type: USER_PROFILE_FAILURE, payload: err.message });
      }
  };
  

  export const loginGoogle = (googleToken) => async (dispatch, getState) => {    
    dispatch({ type: LOGIN_REQUEST });    
    try {
      const url = `${API_URL}/api/v1/auth/sso/google/login`;
      const response = await axiosWithAuth().post(url, { googleToken });
      const { status, data } = response;
      if(status === 200) {
        // localSessionManager.login();
        dispatch({ type: LOGIN_SUCCESS, payload: data });
      } else if (status === 401) {
          throw new Error('Invalid login')
      }
      } catch (err) {
        dispatch({ type: LOGIN_FAILURE, payload: err.message });          
      }
  };