
import { DatePicker } from '@material-ui/pickers';  

const EditDateField = ({header, fieldName, fieldValue, isRequired=true, handleChange}) => { 
    
    return (    
        <DatePicker
            // error={isRequired && !fieldValue}
            // helperText={isRequired && !fieldValue ? "Please fill out this field" : ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={header}
            margin="normal"
            format="MM/DD/yyyy"
            name={fieldName}
            required={true}
            value={fieldValue || null}
            onChange={date => handleChange({ [fieldName]: date.format() } )}
            animateYearScrolling
        />
    )
}

export { EditDateField };