import React, { useState} from 'react';
import {
    Box,
    Container,
    
    Button,
    FormControlLabel,
    Grid,
    Icon,
    Switch,
    Paper
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { InfoDynamic } from './InfoDynamic';

const CustomField = ({ 
        categoryField,
        handleSave,
        handleCancel,
        editMode,
        deleteCustomField,
        index
    }) => {
    
    const header = `Custom Field Name`;
    
    const fieldTypeOptions = [ 'select', 'text', 'date', 'currency', 'textArea', 'email', 'number'];
    const displayLengthOptions = [ 'full', 'half'];
    const [newOption, updateNewOption] = useState("");
    const [confirmDelete, updateConfirmDelete] = useState(false);
    
    const [tempCustomField, updateTempCustomField] = useState(categoryField);


    const handleUpdate = (newValue) => {        
            updateTempCustomField({ ...tempCustomField, ...newValue });
            // handleChange(index, combinedNewValue);
    } 
    
    const handleSubmit = () => {
        handleSave(index, tempCustomField);
    }

    const addOption = () => {
        const currentOptions = Array.isArray(tempCustomField.options) ? tempCustomField.options : [];
        const options = [...currentOptions, newOption];
        handleUpdate({ options });
        updateNewOption();
    }
    const deleteOption = (index) => {
        const currentOptions = Array.isArray(tempCustomField.options) ? tempCustomField.options : [];
        const options = currentOptions.reduce((memo, option, myIndex) => {
            return +index === myIndex ? memo : [...memo, option];
        }, [])
        handleUpdate({ options })
    }

    return (
        <Grid
          container
          direction="column"          
        >
          {!editMode ? (
            <InfoDynamic
                fieldType='text'
                header={header}
                fieldName='fieldDisplay'
                fieldValue={tempCustomField.fieldDisplay || ''}
                editMode={editMode}
                handleChange={handleUpdate}
            />
          ) : (
            <Paper>
                <Container>
                    <InfoDynamic
                        fieldType='text'
                        header={header}
                        fieldName='fieldDisplay'
                        fieldValue={tempCustomField.fieldDisplay || ''}
                        editMode={editMode}
                        handleChange={handleUpdate}
                    />
                    
                    <InfoDynamic
                            fieldType='select'
                            header='Display Length'
                            options={ displayLengthOptions }
                            fieldName='displayLength'
                            fieldValue={tempCustomField.displayLength}
                            editMode={editMode}
                            handleChange={handleUpdate}
                    />

                    <InfoDynamic
                        fieldType='select'
                        header='Type'
                        options={ fieldTypeOptions }
                        fieldName='fieldType'
                        fieldValue={tempCustomField.fieldType}
                        editMode={editMode}
                        handleChange={handleUpdate}
                    />

                    {tempCustomField.fieldType && (tempCustomField.fieldType === 'select') && (
                        <div>
                        <Box display="flex"  flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Box flexGrow={1}>
                                <InfoDynamic
                                    fieldType='text'
                                    header='New Option'
                                    isRequired={false}
                                    fieldName='value'
                                    fieldValue={newOption || ''}
                                    editMode={editMode}
                                    handleChange={(newItem) => updateNewOption(newItem.value)}
                                />
                            </Box>
                    
                            <Box mt={3} ml={2}>
                                <Button variant="outlined" size="small" color="primary" onClick={addOption}>
                                    Add 
                                </Button>                        
                            </Box>
                        </Box>
                            {Array.isArray(tempCustomField.options) && tempCustomField.options.map(( myOption, index) => (
                        <Box display="flex" key={index} flexDirection="row" justifyContent="space-between">
                                <Box flexGrow={1}>{myOption}</Box>
                                <Box className='clickable' onClick={() => deleteOption(index)}>X</Box>
                        </Box>
                            ))}
                        </div>
                    )}
                
                <Box mb={3}  />
                
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        
                        {tempCustomField && tempCustomField._id && (
                        <Grid item>

                            {confirmDelete ? (
                                <>
                                <Button className='button-list' color="secondary" size='small' variant="outlined" onClick={() => deleteCustomField({...categoryField, index})}>Delete Forever</Button>
                                <Button className='button-list' color="default" size='small' variant="outlined" onClick={() => updateConfirmDelete(false)}>Cancel</Button>
                                </>
                            ) : (
                                <>
                                {/* <Button className='button-list' color="secondary" size='small' variant="outlined" onClick={() => updateConfirmDelete(true)}>Delete</Button> */}
                                <Icon onClick={() => updateConfirmDelete(true)} style={{ color: red[500], cursor: 'pointer' }}>delete_forever</Icon>
                                </>
                            )}

                            </Grid>
                        )}
                        {!confirmDelete && (
                        <>

                        <Grid item>
                            <FormControlLabel
                                control={<Switch
                                    checked={tempCustomField.required}
                                    onChange={(e) => handleUpdate({ [e.target.name]: (e.target.value === 'true') })}
                                    color="primary"
                                    value={!tempCustomField.required}
                                    name="required" />
                                }
                                label="Required"
                            />
                        </Grid>
                        <Grid item>                            
                            <Button className='button-list' color="default" size='small' variant="outlined" onClick={handleSubmit}>Save</Button>
                            <Button className='button-list' color="secondary" size='small' variant="outlined" onClick={handleCancel}>Cancel</Button>
                        </Grid>

                        </>           
                        )}
                        
                               
                    </Grid>                    
                    <Box mb={1}  />
                </Container>
            </Paper>
          )}

        
        </Grid>
    )
}


export { CustomField };