import {
    Box,
    Typography,

} from '@material-ui/core';
import * as moment from 'moment';
import NumberFormat from 'react-number-format';
const InfoText = ({header, value, fieldType='text'}) => {
    return (
        <Box flexDirection='column'>
            <Typography
                color="textSecondary"
                variant="caption"
            >            
                {header}
            </Typography>

            <>
            {(() => {
  
                switch (fieldType) {
                   case 'date':
                       return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="primary"
                        >
                        {value ? moment(value).local().format('MMM, DD YYYY') : '\u00A0'}
                        </Typography>
                       )
                   case 'currency':
                       return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="primary"
                        >
                        {value ? (
                        <NumberFormat
                            prefix="$"
                            displayType={'text'}
                            thousandSeparator
                            fixedDecimalScale={true}
                            decimalScale={2}
                            value={value}
                            />
                        ) : (
                            <>
                            {'\u00A0'}
                            </>
                        )}
                        </Typography>
                       )
                    case 'number':                        
                    return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="primary"
                        >                          
                        <NumberFormat 
                        value={value}
                        displayType={'text'}
                        thousandSeparator={true}                            
                        />
                        </Typography>
                    )    
                    case 'switch':                        
                    return (        
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="primary"
                        >
                        {(value === true) ? 'yes' : 'no'}
                        </Typography>
                    )                                           
                   default:
                       return (
                        <Typography        
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="primary"
                        >
                        {value || '\u00A0'}
                        </Typography>
                       )
                }
       
             })()}
                </>
        </Box>
    )
}

export { InfoText };