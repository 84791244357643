import React from 'react';
import { 
    useSelector,
    shallowEqual
  } from 'react-redux';
import {
    Divider,
    Grid,
    Box
 }
from '@material-ui/core';

import { InfoDynamic } from '../Common';

const AssetOwnershipRecord = ({record, editMode, handleNewValueChange}) => {
    const { user } = useSelector((state) => state.auth, shallowEqual);
   
    const handleUserChange = (newUser) => {        
        const { email: ownerKey, fullName: ownerDisplay } = newUser;
        handleNewValueChange({ ownerKey, ownerDisplay });
    }    
    const handleNonUserChange = (newOwner) => {        
        handleNewValueChange({ ...newOwner, ownerDisplay: newOwner.ownerKey });
    }
    
    // const ownerTypeWidth = record && record._id && !record.returnCondition ? 6 : 12
    const ownerTypeWidth = 12;

    return (

    <Grid container spacing={editMode ? 2 : 0}>



        <Grid item  md={ownerTypeWidth} sm={ownerTypeWidth} xs={ownerTypeWidth}>


        {(() => {            
                switch (record.ownerType) {
                    case 'Storage':
                        return (        
                            <InfoDynamic
                            fieldType='select'
                            header='Storage Location'
                            options={(user && user.settings && user.settings.storageLocations) || ['Storage']}
                            fieldName='ownerKey'
                            fieldValue={record.ownerKey || ''}
                            editMode={editMode}
                            handleChange={handleNonUserChange}
                        />
                        )                        
                        
                    case 'Transit':                        
                        return (       
                        <Grid container>
                            <Grid item md={6}> 
                                <InfoDynamic
                                    fieldType='select'
                                    header='Shipping Company'
                                    options={(user && user.settings && user.settings.shippingOptions) || ['Other']}
                                    fieldName='ownerKey'
                                    fieldValue={record.ownerKey || ''}
                                    editMode={editMode}
                                    handleChange={handleNewValueChange}
                                />
                            </Grid>
                            <Grid item md={6}> 
                                <InfoDynamic
                                    fieldType='text'
                                    header='Tracking Number'                                    
                                    fieldName='trackingNumber'
                                    isRequired={false}
                                    fieldValue={record.trackingNumber || ''}
                                    editMode={editMode}
                                    handleChange={handleNewValueChange}
                                />
                            </Grid>
                        </Grid>

                        )
                    case 'Retired':                      
                    return (        
                        <InfoDynamic
                        fieldType='select'
                        header='Disposal Method'
                        options={(user && user.settings && user.settings.disposalOptions) || ['Other']}
                        fieldName='ownerKey'
                        fieldValue={record.ownerKey || ''}
                        editMode={editMode}
                        handleChange={handleNewValueChange}
                    />
                    )                        
                   
                    default:
                        return (        
                            <InfoDynamic
                            fieldType='userDirectory'
                            header='Employee'
                            email={record.ownerKey}
                            fieldName='ownerKey'
                            fieldValue={`${record.ownerDisplay}`}
                            editMode={editMode}
                            handleChange={handleUserChange}
                        />
                        )
                }
            
            })()}

            

        </Grid>   


        {record && record._id && !record.returnCondition && (
        <Grid item md={6} sm={6} xs={12}>
            <InfoDynamic
                fieldType='select'
                header='Current Condition'
                fieldName='currentCondition'
                fieldValue={record.currentCondition || record.startingCondition}
                options={(user && user.settings && user.settings.deviceConditionOptions) || ['New']}
                editMode={editMode}
                isRequired={true}
                handleChange={handleNewValueChange}                
            />                
        </Grid>        
    )}
    <Grid item md={6} sm={6} xs={6}>
        <InfoDynamic
            fieldType='date'
            header='Date Assigned'
            fieldName='dateAssigned'
            fieldValue={record.dateAssigned}
            editMode={editMode}
            isRequired={true}
            handleChange={handleNewValueChange}                
        />                
    </Grid>

    <Grid item md={6} sm={6} xs={6}>

    <InfoDynamic
            fieldType='select'
            header='Starting Condition'
            fieldName='startingCondition'
            fieldValue={record.startingCondition || ''}
            editMode={editMode}
            isRequired={true}
            options={(user && user.settings && user.settings.deviceConditionOptions) || ['New']}
            handleChange={handleNewValueChange}                
        />   
    </Grid>

    {record && record._id && (
        <>
    <Grid item md={6} sm={6} xs={6}>
        <InfoDynamic
            fieldType='date'
            header='Date Returned'
            fieldName='dateReturned'
            fieldValue={record.dateReturned}
            editMode={editMode}
            isRequired={true}
            handleChange={handleNewValueChange}                
        />                
    </Grid>  

    <Grid item md={6} sm={6} xs={6}>
    <InfoDynamic
            fieldType='select'
            header='Return Condition'
            fieldName='returnCondition'
            fieldValue={record.returnCondition}
            editMode={editMode}
            isRequired={false}
            options={(user && user.settings && user.settings.deviceConditionOptions) || ['New']}
            handleChange={handleNewValueChange}                
        />   
    </Grid>

    <Grid item md={12}>
        <Box pb={1} />
        <Divider />
        <Box pb={2} />
    </Grid>
    

    <Grid item md={6}>
            <InfoDynamic
                fieldType='shortText'
                header='Entered By'
                fieldName='enteredBy'
                fieldValue={record.enteredBy}
                editMode={false}
                
            />                
    </Grid>
    <Grid item md={6}>
            <InfoDynamic
                fieldType='date'
                header='Last Updated'
                fieldName='dateUpdated'
                fieldValue={record.dateUpdated}
                editMode={false}
                
            />                
    </Grid>      

        </>
    )}
    
    
    

</Grid>


    );
}

export {
    AssetOwnershipRecord
}