
import {
    FormControlLabel,
    FormControl,
    InputLabel,
    Box,
    Switch,
} from '@material-ui/core';

/**
 * 
 * @param {} props 
 * @returns 
 * @description fieldValue={(value === true) }
 */

const SwitchField = (props) => {
    const { header, fieldName, fieldValue, disabled=false, handleChange } = props;
    const testClick = (e) => {       
        handleChange({ [e.target.name]: (e.target.checked) })
    }
    return (
        <>
        <Box mt={2} />
        <FormControl component="div" fullWidth>
        <InputLabel className='MuiInputLabel-shrink' htmlFor={fieldName} >{header}</InputLabel>        
        <Box mt={2} />
        <FormControlLabel
            id={fieldName}
            control={<Switch                
                checked={(fieldValue === true)}
                onChange={testClick}
                color="primary"
                disabled={disabled}
                name={fieldName} />
            }
            label={fieldValue ? 'yes' : 'no'}
        />
        </FormControl>
        <Box mb={.3} />
        </>
     )
}


export { SwitchField };