import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Box,    
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { 
  getUserDirectory
} from '../../../store/actions';


const UserList = ( {email, handleChange}) => {
    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState({fullName: ''});
    const { users } = useSelector((state) => state.userDirectory, shallowEqual);
    
    useEffect(() => {        
            dispatch(getUserDirectory());
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    useEffect(() => {                
        const activeUser = users.find(me => me.email === email) || {familyName: '', givenName: '', fullName: ''};
        setSelectedUser(activeUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, users]);      
    return (
        <Box mt={2}>
        <Autocomplete
          value={selectedUser || null}
          id="user-directory-picker"
          fullWidth
          onChange={(event, newValue) => {
              if (newValue) {
                  handleChange(newValue);
              }
          }}   
          options={users}
          getOptionLabel={(option) => option.fullName}
          renderInput={(params) => <TextField {...params} required={true} margin='none' label="User"  />}
        />
        </Box>
      );

  }
  export {
      UserList
  }