import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,    
 } from '@material-ui/core';

const DisplayFields = ({displayFields, handleChange}) => {
    return (
        <FormControl component="fieldset">
        <FormLabel component="legend">Fields</FormLabel>
        <FormGroup>
        <div className="container-list">
            {displayFields.map((field, index) => (                        
                <FormControlLabel  
                className='container-list-child'                  
                key={`show-${index}-index`}
                control={<Checkbox checked={field.display || false} onChange={(e) => {handleChange(e.target.name)}} name={field.fieldName} />}
                label={field.fieldDisplay}
                />
            ))}
            </div>
        </FormGroup>
    </FormControl>        
    )
}

export {
    DisplayFields
}