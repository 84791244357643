import React, { useState, createRef, useEffect } from 'react';
import { 
    useDispatch,
    useSelector
  } from 'react-redux';
  import {
    useParams,
    Link
  } from 'react-router-dom';  
import {
    Box,
} from '@material-ui/core';

import {
    Grid,
    // Icon,
    Button,
    Divider,
    Paper,
    // Tabs,
    // Tab,
    Typography,
    makeStyles,
} from '@material-ui/core';

// import {
//   TabPanel,
//   TabContext }
//  from '@material-ui/lab';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import EditIcon from '@material-ui/icons/Edit';

import { CategoryStandardFields } from './CategoryStandardFields';
import { CategoryUniqueFields } from './CategoryUniqueFields';
import { CategoryMdm } from './CategoryMdm';
import { CustomFields } from './CustomFields';

// import ExtensionIcon from '@material-ui/icons/Extension';


import { 
  updateAssetCategory,
} from '../../store/actions';

// import { Templates } from '../Templates/Templates';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));


const initCategory = {customFields: [], trackRefreshCycle: false, trackDepreciation: false};
const Category = () => {
    const classes = useStyles();
    const { assetType } = useParams();
    const { categories } = useSelector((state) => state.assetCategories);
    const { user } = useSelector((state) => state.auth);
    const form = createRef();
    const dispatch = useDispatch();
    
    // const [tabIndex, setTabIndex] = useState("0");
    const [editMode, updateEditMode] = useState(false);
    const [editCategory, updateCategory] = useState(initCategory);
    const [nameTaken, updateNameTaken] = useState(false);
    

    useEffect(() => {      
      const category = categories.find(item => item.assetType === assetType);
      updateCategory({...initCategory, ...category});       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, assetType]);


    
    const handleCategoryChange = (newValue) => {
      // Make sure that the key is not already taken
      if (Object.keys(newValue).find(x => x === 'assetType')) {
        const isTaken = categories.find(x => x.assetType === newValue.assetType && x._id !== editCategory._id);
        updateNameTaken(isTaken ? true : false);        
      }

      updateCategory({...editCategory, ...newValue });
        
    }

    const handleSubmit = () => {
        if (form.current.reportValidity() && !nameTaken) {
          const { standardFields, customFields, ...restOfField } = editCategory;
          dispatch(updateAssetCategory(restOfField));          
          updateEditMode("");
        }
    }


    // const handleChange = (event, newValue) => {
    //   setTabIndex(newValue);
    // };

  return (
    <>    
     <Button 
        size='small'
        variant="outlined"
        color="default"
        startIcon={<KeyboardArrowLeftIcon />}
        >
        <Link to='../..'  relative="path">Back</Link>
      </Button>

      <Box pt={2} />    

      
        <h2>Category: {editCategory.categoryName || "New Category"}</h2>
        <div className={classes.root}>
          <Grid container spacing={2} direction="column">
            <Paper className={classes.paper}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6">Overview</Typography>
                </Box>
                      {editMode ? (
                      <>
                        <Button size="small" className="button-list" onClick={() => handleSubmit()} variant="outlined">Save</Button>
                        <Button size="small" className="button-list" onClick={() => updateEditMode(null)} variant="outlined">Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button size="small" className="button-list" startIcon={<EditIcon />} onClick={() => updateEditMode(true)} variant="outlined">Edit</Button>
                      </>
                    )}
              </Box>
              <Box pt={1} />
              <Divider />    
              <Box pt={2} />          
              <form ref={form} onSubmit={e => e.preventDefault()}>                
                    <CategoryStandardFields  nameTaken={nameTaken} editMode={editMode} editCategory={editCategory} handleChange={handleCategoryChange} />
                      <Box pt={3} />
                    <CategoryUniqueFields editMode={editMode} editCategory={editCategory} handleChange={handleCategoryChange} />                
              </form>

              </Paper>
              {user && user.integrationProviders && (
                <>
              <Box pt={2} />
              <Paper className={classes.paper}>
                  <CategoryMdm assetType={assetType} handleChange={handleCategoryChange} />     
              </Paper>
              </>
              )}
              <Box pt={2} />
              <Paper className={classes.paper}>
                <CustomFields categoryId={editCategory._id} showHeader={true} />
              </Paper>
          </Grid>
          </div>
{/* 

      <Paper>
        <TabContext value={tabIndex}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            variant="fullWidth"
            centered
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="Category Settings"
          >                            
              
              <Tab value="0" icon={<Icon color={tabIndex === "0" ? "secondary" : "primary"}>{editCategory && editCategory.icon ? editCategory.icon : 'important_devices_icon'}</Icon>} label="Overview" />    
              <Tab value="1" icon={<ExtensionIcon color={tabIndex === "1" ? "secondary" : "primary"} />} label="Custom Fields" />
              <Tab value="2" icon={<DynamicFeedIcon  color={tabIndex === "2" ? "secondary" : "primary"} />} label="Templates" />                        
          </Tabs>

          <TabPanel value="0">
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                    {editMode ? (
                      <>
                        <Button size="small" className="button-list" onClick={() => handleSubmit()} variant="outlined">Save</Button>
                        <Button size="small" className="button-list" onClick={() => updateEditMode(null)} variant="outlined">Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button size="small" className="button-list" startIcon={<EditIcon />} onClick={() => updateEditMode(true)} variant="outlined">Edit</Button>
                      </>
                    )}
              </Box>
              <Box pt={1} />
              <Divider />    
              <Box pt={2} />
              
              <form ref={form} onSubmit={e => e.preventDefault()}>
                <Box display="flex" flexDirection="column">
                <CategoryStandardFields  nameTaken={nameTaken} editMode={editMode} editCategory={editCategory} handleChange={handleCategoryChange} />
                  <Box pt={3} />
                <CategoryUniqueFields editMode={editMode} editCategory={editCategory} handleChange={handleCategoryChange} />
                {user && user.integrationProviders && (
                  <>
                    <Box pt={6} />
                    <CategoryMdm assetType={assetType} handleChange={handleCategoryChange} />                
                    </>
                )}
                </Box>
              </form>
          </TabPanel>

          <TabPanel value="1">                   
                <CustomFields categoryId={editCategory._id} />            
          </TabPanel>                    
          <TabPanel value="2">
              <Templates categoryId={editCategory._id} />
          </TabPanel>

        </TabContext>
        </Paper> */}
    </>
  );
}

export {
    Category
};




   