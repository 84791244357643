import { API_URL } from '../../env';

import { 
    axiosWithAuth,
 } from '../../utils/axiosWithAuth';

import {
    FETCH_USER_ASSETLIST_REQUEST,
    FETCH_USER_ASSETLIST_SUCCESS,
    FETCH_USER_ASSETLIST_FAILURE,
} from './actionTypes';


const baseUri = `${API_URL}/api/v1/assets/users`;

export const getUserAssetList = ( ) => async (dispatch) => {    
    const uri = `${baseUri}/list`;
    dispatch({ type: FETCH_USER_ASSETLIST_REQUEST});
    try {
        const response = await axiosWithAuth().get(uri);
        dispatch({ type: FETCH_USER_ASSETLIST_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: FETCH_USER_ASSETLIST_FAILURE, payload: err.message});
    }
}