import React from 'react';

import {
    Link as RouterLink
  } from 'react-router-dom';
import { 
      Box,
      Grid,
      Typography,      
      makeStyles,

} from '@material-ui/core';  


import {     
    Table,    
    TableBody,
    TableCell,    
    TableHead,
    TableRow,  
  } from '@material-ui/core';

import { InfoDynamic, DynamicFormat } from '../Common';

const useStyles = makeStyles((theme) => ({
    striped: {
        backgroundColor: '#EAEAEA',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursort: 'pointer'
    },
    sortedField: {
        color: theme.palette.primary.dark,
        textDecoration: 'underline'
    },
    unstriped: {
        backgroundColor: '#fff',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursort: 'pointer'
    },
    button: {
        // marginLeft: theme.spacing(.5),        
        margin: theme.spacing(.5),
        marginTop: theme.spacing(1.5),
    },
    root: {
        // display: 'flex',
        width: '100%'
    },
    content: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}));




const DeviceList = ({ assets, displayFields, sortOrder, isLoading=false, handleSortChange, searchTerm, handleSearchTermChange }) => {
    const classes = useStyles();
    

    const orderTable = (fieldName) => {

        let order = 'asc';
        if (sortOrder.fieldName === fieldName && sortOrder.order === 'asc') {
            order = 'desc';
        } 
        
        handleSortChange({ fieldName, order });
      }


    return (

    
        <div className={`${classes.root} fadeIn ${classes.content}`}>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >                        


            <Grid item md={5} sm={12} xs={12} >
      
            <InfoDynamic
                            fieldType='text'
                            header='Search'                    
                            fieldName='searchTerm'
                            fieldValue={searchTerm}
                            editMode={true}
                            handleChange={(newSearchTerm) => handleSearchTermChange(newSearchTerm.searchTerm)}
                    />
            </Grid>                    
        </Grid>
        <Box p={1} />
        <Table size="small" aria-label="list of assets" style={isLoading ? {'display': 'none'} : {}}>
            <TableHead>
              <TableRow>
                {displayFields.filter(f => f.display).map((field, index) => (
                    <TableCell className='clickable' key={index} onClick={() => orderTable(field.fieldName)}>
                        <Typography variant="overline" className={sortOrder.fieldName === field.fieldName ? classes.sortedField : ''}>{field.fieldDisplay}</Typography>
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            
            <TableBody>
            {assets.map((asset, index) => (
                <TableRow key={index} className={(index % 2 === 0) ? classes.unstriped : classes.striped}>
                {displayFields.filter(f => f.display).map((field, fieldIndex) => (
                    <TableCell key={fieldIndex}>
                            <Typography
                            component={fieldIndex === 0 ? RouterLink : null} to={`asset/${asset._id}`}
                            variant="caption"                 
                        ><DynamicFormat fieldValue={asset[field.fieldName] || ''} fieldType={field.fieldType || ''} /></Typography>

                    </TableCell>
                ))} 
                
                </TableRow>
            ))}
            </TableBody>
            
            </Table>

    </div>

    )
}

export {
    DeviceList
};