import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import {  
  useSelector,
  useDispatch
} from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Drawer,
  Icon,
  Paper,
  Typography
 } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PersonIcon from '@material-ui/icons/Person';

import { logoutUser, getProfile, getAssetCategories } from '../../store/actions';
import { useWindowSize } from '../../utils';
// import { LogoutMenu } from './LogoutMenu';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logoStyle: {
    width: drawerWidth * 0.6
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),    
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  leftToolBar: {
    // backgroundColor: theme.palette.info.dark,
    // color: theme.palette.info.light
  },
  content: {
    flexGrow: 1,    
    // padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

const MiniDrawer = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const size = useWindowSize();

  const [open, setOpen] = useState(true);
  const { authEvent, user } = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.assetCategories);
  const [activeRoute, updateActiveRoute] = useState([]);
  const location = useLocation();
  
  
  useEffect(() => {
    if (authEvent === 'logout') {
      navigate('/login');      
    } else if (!user.accountName) {
      dispatch(getProfile());
      dispatch(getAssetCategories());      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authEvent]);  
  
  useEffect(() => {    
    const myLocation = location.pathname.split("/");
    myLocation.shift();
    updateActiveRoute(myLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Dynamically update Pane
  useEffect(() => {  
    if (size.width <= theme.breakpoints.values.md) {      
      setOpen(false);
    } else if (size.width > theme.breakpoints.values.md) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLink = (myLink) => {
    navigate(myLink);
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />      
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>        
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />            
          </IconButton>
          
          <Typography variant="h6" className={classes.title} noWrap>
              {user.accountName}
          </Typography>
          <Button color="inherit" variant='outlined' onClick={handleLogout}>Logout</Button>
          {/* <LogoutMenu handleLogout={handleLogout} /> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
          <Paper>
        <div className={`${classes.toolbar}`}>
          <h3>Asset Docket</h3>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
          </Paper>
        <Divider />
          
        <List dense>
          
          <ListItem className={!open ? classes.hide : ''} >              
              <ListItemText primary='Device Categories' />
          </ListItem> 
          <ListItem button selected={activeRoute && activeRoute[0] === ''} onClick={()=>handleLink('/')} key='Dashboard'>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary='Dashboard' />
          </ListItem>          

          {categories.map(({ icon, categoryName, assetType }) => (
            <ListItem
                button 
                key={categoryName}
                onClick={()=>handleLink(`/category/${assetType}`)}
                selected={activeRoute && activeRoute[0] === 'category' && activeRoute[1] === assetType}
              >
              <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>              
              <ListItemText primary={categoryName} />              
            </ListItem>
          ))}
        </List>
        <Divider />


        <List dense>
          <ListItem className={!open ? classes.hide : ''}>              
              <ListItemText primary='Reports' />
          </ListItem>          

          <ListItem button selected={activeRoute && activeRoute[0] === 'assignments'} onClick={()=>handleLink('/assignments')}>
              <ListItemIcon><Icon>supervisor_account</Icon></ListItemIcon>
              <ListItemText primary='Assignments' />
          </ListItem>          
        </List>
          
          <Divider />

    <List dense>        
        <ListItem className={!open ? classes.hide : ''}>              
              <ListItemText primary='Admin' />
        </ListItem> 

        <ListItem
          className='clickable'
          onClick={()=>handleLink('/admin/categories')}
          selected={activeRoute && activeRoute[0] === 'admin' && activeRoute[1] === 'categories'}
          >
            <ListItemIcon><FilterNoneIcon /></ListItemIcon>
            <ListItemText primary='Categories' />
        </ListItem>

        <ListItem
          className='clickable'
          onClick={()=>handleLink('/admin/users')}
          selected={activeRoute && activeRoute[0] === 'admin' && activeRoute[1] === 'users'}
          >
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary='Users' />

        </ListItem>         
              
      
    </List>       

      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {/* {theme.breakpoints.down('lg')} */}
        {/* <div style={{ width: innerContainerWidth}}> */}
            <Outlet />

        {/* <Box justifyContent="space-between">
            <Box flexShrink={0} style={{width: '500px', marginLeft: '300px' }} />
          
          </Box>             */}

      </main>
    </div>
  );
}


export { MiniDrawer };