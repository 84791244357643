import { API_URL } from '../env';
import { 
    axiosWithAuth,
} from './';
const baseUri = `${API_URL}/api/v1/assets`;
const openFile = async (storageName) => {
    const uri = `${baseUri}/storage/file/url`;
    try {        
        const response = await axiosWithAuth().post(uri, { storageName });
        window.open(response.data)
        
    } catch (err) {
        
    }
}

export {
    openFile
};