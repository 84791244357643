import axios from 'axios';


const axiosWithAuth = () => {
  const instance = axios.create({
    withCredentials: true,
  });
  
  instance.interceptors.response.use(response=>response,
    err=>{
      if (err.response.status === 401) {
        // throw err;     
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'; 
        }
      } else {
        throw err;
      }
    }
    );

  return instance;
  
};


const axiosFile = () => {

  const instance = axios.create({
    withCredentials: true,
    headers: {
      'content-type': 'multipart/form-data',
    }
  });
  
  instance.interceptors.response.use(response=>response,
    err=>{
      if (err.response.status === 401) {        
        // window.location.href = '/login';
      } else {
        throw err;
      }
    }
    );

  return instance;
  
};


export {
  axiosWithAuth,
  axiosFile
} 
