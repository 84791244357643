
import {    
    FETCH_USER_DIRECTORY_REQUEST,
    FETCH_USER_DIRECTORY_SUCCESS,
    FETCH_USER_DIRECTORY_FAILURE,
    ADD_USER_DIRECTORY_REQUEST,
    ADD_USER_DIRECTORY_SUCCESS,
    ADD_USER_DIRECTORY_FAILURE,
    CLEAR_USER_DIRECTORY_ERROR

} from '../actions';

const initialState = 
{
    isLoading: false,
    error: '',    
    users: []
};

const userDirectoryReducer = ((state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_DIRECTORY_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case FETCH_USER_DIRECTORY_SUCCESS:
            return { ...state, users: action.payload, isLoading: false, error: '' };        
        case FETCH_USER_DIRECTORY_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case ADD_USER_DIRECTORY_REQUEST:            
        return { ...state, isLoading: true, error: '' };
        case ADD_USER_DIRECTORY_SUCCESS:
            return { ...state, users: [...state.users, action.payload], isLoading: false, error: '' };                    
        case ADD_USER_DIRECTORY_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case CLEAR_USER_DIRECTORY_ERROR:
            return { ...state, error: '' };            
    
        default:
            return state;
    }
});

export default userDirectoryReducer;