import React, { useState, createRef, useEffect } from 'react';

import { 
    useSelector,
    useDispatch,
    shallowEqual   
  } from 'react-redux';
  import Alert from '@material-ui/lab/Alert';
 
  import {
    Box,
    Button,
    Card,
    CardContent,
    Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { 
    InfoDynamic,
} from '../Common';

import {
    updateAssetOverview
} from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  inactiveRecord: { cursor: 'pointer' },
  activeRecordHeader: { backgroundColor: '#E0E0E0' },
  button: {
    margin: theme.spacing(.5),
    },  
}));

  const AssetDispose = ({ handleCancel, newEntry=false }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = createRef();
    const { asset } = useSelector((state) => state.asset, shallowEqual);
    const [editRecord, updateEditRecord] = useState({ dateDisposed: '', disposalMethod: '' });
    
    const isAssigned = (Array.isArray(asset.ownership) && asset.ownership.find(x => !x.dateReturned)) ? true : false;

    const [overrideEditMode, updateOverrideEditMode] = useState(newEntry);

    useEffect(() => {
        const { disposalMethod, dateDisposed } = asset;
        updateEditRecord({ disposalMethod, dateDisposed });
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [asset]); 

    const editMode = () => { 
        if (overrideEditMode || newEntry) {
            return true;
        } else {
            return asset.dateDisposed ? false : true;
        }        
    }
    const handleNewValueChange = (newValue) => {
        updateEditRecord({...editRecord, ...newValue });
        
    }
    const handleSubmit = () => {
        if(form.current.reportValidity()) {            
            dispatch(updateAssetOverview(asset._id, editRecord));
            // updateAddMode(false);
        }
    }  
    const handleDelete = () => {        
            dispatch(updateAssetOverview(asset._id, { dateDisposed: null, disposalMethod: null }));
        
      }      

    const triggerCancel = () => {
        handleCancel();
        updateOverrideEditMode(false);
    }
    return (
        <>
            
        {isAssigned && newEntry && (
            <Alert severity="error">Unable to dispose of this {asset.assetType} until all other assignments are closed.</Alert>
        )}
        
        {!isAssigned && (newEntry || asset.dateDisposed) && (
       

            <Box pb={3} >
            

            <Grid item xs={12} sm={12}>
            <form ref={form} onSubmit={e => e.preventDefault()}>
            <Card>

            <CardContent onDoubleClick={() => {updateOverrideEditMode(true)}} className={classes.inactiveRecord}>
            <Grid container spacing={editMode() ? 2 : 0}>       
            <Grid item md={6} sm={6} xs={6}>
                <InfoDynamic
                    fieldType='date'
                    header='Date Disposed'
                    fieldName='dateDisposed'
                    fieldValue={editRecord.dateDisposed}
                    editMode={editMode()}
                    isRequired={true}
                    handleChange={handleNewValueChange}                
                />                
                </Grid>  

                <Grid item md={6} sm={6} xs={6}>
                <InfoDynamic
                        fieldType='select'
                        header='Disposal Method'
                        options={['Recycled', 'Sold', 'Other']}
                        fieldName='disposalMethod'
                        fieldValue={editRecord.disposalMethod || ''}
                        editMode={editMode()}
                        handleChange={handleNewValueChange}
                    />
                    </Grid>
                </Grid>
                </CardContent>

                {editMode()  && (
                    <CardContent className={classes.activeRecordHeader}> 
                    <Box display="flex" flexDirection="row" alignItems="space-between">
                        <Box flexGrow={1}>
                            {asset && asset.dateDisposed && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className={classes.button}                        
                                onClick={handleDelete}
                            >Delete</Button>
                            )}
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}                        
                            onClick={handleSubmit}
                        >Save</Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={triggerCancel}
                            className={classes.button}                        
                        >Cancel</Button>                            
                    </Box>
                </CardContent>
                )}                    
        </Card>
        </form>
                </Grid>
        </Box>
        )}
        </>
    )
  }

  export {
    AssetDispose
  }