import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Icon
} from '@material-ui/core';

import {  
  useDispatch
 , useSelector 
} from 'react-redux';

import { DashboardBox } from '.';
import { Loading } from '..';
import { getDeviceTotals } from '../../store/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  gridList: {
    width: '100%',
    
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  content: {
    padding: theme.spacing(3),    
  }  
}));

const Dashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isLoading, totals } = useSelector((state) => state.assetTotals);
    const { isAuthenticated, authEvent } = useSelector((state) => state.auth)
    useEffect(() => {
      if (isAuthenticated && authEvent !== 'logout') {
        dispatch(getDeviceTotals());
      }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);  

    return (
      <>            
      {isLoading ? (
          <Loading />
      ) : (      
        <div className={`${classes.root} ${classes.content} fadeIn`}>
            <Grid container spacing={2}>
            {totals.map(({ icon, categoryName, assetType, count }, index) => (
              <Grid item md={4} sm={4} xs={12} key={index}>              
                <DashboardBox
                    content={{
                      numOfResources: count,
                      resourceText: `${categoryName}`,
                      path: `/category/${assetType}`
                    }}
                    headingIcon={<Icon>{icon}</Icon>}
                    headingTitle={`${categoryName}`}
                  />              
              </Grid>
              ))}
          </Grid>
          
        </div>
      )}
     </>      
    );
}

export {
    Dashboard
};
