import {
    Grid,
} from '@material-ui/core';
import { InfoDynamic } from '../Common';

const iconOptions = [
    {
      fieldDisplay: 'Computer',
      fieldValue: 'computer'
    },
    {
      fieldDisplay: 'Monitor',
      fieldValue: 'desktop_windows'
    },
    {
      fieldDisplay: 'iOS Device',
      fieldValue: 'tablet_mac'
    },        
    {
      fieldDisplay: 'AV Equipment',
      fieldValue: 'voice_chat'
    },
    {
      fieldDisplay: 'Peripherals',
      fieldValue: 'cable'
    },
    {
      fieldDisplay: 'Office Equipment',
      // fieldValue: 'holiday_village'
      // fieldValue: 'important_devices'
      // fieldValue: 'chair_alt'
      fieldValue: 'widgets'
    },
    {
      fieldDisplay: 'Office',
      fieldValue: 'villa'
      // fieldValue: 'important_devices'
      // fieldValue: 'chair_alt'
    },      
    {
      fieldDisplay: 'Printers',
      fieldValue: 'printer'
    },    
    {
      fieldDisplay: 'Computing Device',
      fieldValue: 'important_devices'
    },
  ]

const CategoryStandardFields = ({ editMode, editCategory, handleChange, nameTaken }) => {
    return (
      <Grid container spacing={2}>
        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
                  fieldType='text'
                  header='Display Name'
                  fieldName='categoryName'
                  fieldValue={editCategory.categoryName || ""}
                  disabled={false}
                  editMode={editMode}
                  handleChange={handleChange}
          />
                                
          <InfoDynamic
            fieldType='text'
            header='Unique Key (cannot change)'
            fieldName='assetType'
            fieldValue={editCategory.assetType || ""}            
            error={nameTaken}
            helperText="Incorrect entry."
            disabled={editCategory.hasOwnProperty('_id') ? true : false}
            editMode={editMode}
            handleChange={handleChange}
          />
          
          {editMode && (
            <InfoDynamic
                      fieldType='selectObject'
                      header='Icon'                      
                      fieldName='icon'
                      fieldValue={editCategory.icon || ""}
                      options={iconOptions}
                      disabled={false}
                      editMode={editMode}
                      handleChange={handleChange}
              />
          )}
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
            fieldType='switch'
            header='Track Depreciation'
            fieldName='trackDepreciation'
            fieldValue={ (editCategory.trackDepreciation === true) }
            editMode={editMode}
            handleChange={handleChange}
          />

          {editCategory.trackDepreciation && (
            <>
              <InfoDynamic
              fieldType='number'
              header='Depreciation (months)'
              fieldName='depreciationMonths'
              fieldValue={editCategory.depreciationMonths || 0}
              editMode={editMode}
              handleChange={handleChange}
            />

            <InfoDynamic
            fieldType='currency'
            header='Ignore items less than'
            fieldName='depreciationThreshold'
            fieldValue={editCategory.depreciationThreshold || 0}
            editMode={editMode}
            handleChange={handleChange}
            />
            </>
          )}
          </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
            fieldType='switch'
            header='Track Refresh Cycle'
            fieldName='trackRefreshCycle'
            fieldValue={(editCategory.trackRefreshCycle === true) }
            editMode={editMode}
            handleChange={handleChange}
          />        

        {editCategory.trackRefreshCycle && (
          <>
          <InfoDynamic
            fieldType='number'
            header='Refresh (months)'
            fieldName='refreshMonths'
            fieldValue={editCategory.refreshMonths || 0}
            editMode={editMode}
            handleChange={handleChange}
          />

          </>
          )}

        </Grid>
      </Grid>
    )
}

export {
    CategoryStandardFields
}