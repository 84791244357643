
import {
    TextField,
} from '@material-ui/core';
import { NumberFormatCustom } from '..';

const EditCurrencyField = ({header, fieldName, fieldValue, isRequired=true, handleChange}) => {
    return (      
            <TextField            
                fullWidth       
                InputLabelProps={{ shrink: true }}
                label={header}
                margin="normal"
                name={fieldName}
                onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                required={isRequired}
                value={fieldValue || ''}
                InputProps={{    
                    inputComponent: NumberFormatCustom,
                }}                
                />
    )
}

export { EditCurrencyField };