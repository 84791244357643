
export const FETCH_ASSET_TOTALS_REQUEST = 'FETCH_ASSET_TOTALS_REQUEST';
export const FETCH_ASSET_TOTALS_SUCCESS = 'FETCH_ASSET_TOTALS_SUCCESS';
export const FETCH_ASSET_TOTALS_FAILURE = 'FETCH_ASSET_TOTALS_FAILURE';

export const FETCH_ASSET_TYPE_REQUEST = 'FETCH_ASSET_TYPE_REQUEST';
export const FETCH_ASSET_TYPE_SUCCESS = 'FETCH_ASSET_TYPE_SUCCESS';
export const FETCH_ASSET_TYPE_FAILURE = 'FETCH_ASSET_TYPE_FAILURE';


export const FETCH_DEVICE_TEMPLATES_REQUEST = 'FETCH_DEVICE_TEMPLATES_REQUEST';
export const FETCH_DEVICE_TEMPLATES_SUCCESS = 'FETCH_DEVICE_TEMPLATES_SUCCESS';
export const FETCH_DEVICE_TEMPLATES_FAILURE = 'FETCH_DEVICE_TEMPLATES_FAILURE';


export const ADD_DEVICE_TEMPLATE_REQUEST = 'ADD_DEVICE_TEMPLATE_REQUEST';
export const ADD_DEVICE_TEMPLATE_SUCCESS = 'ADD_DEVICE_TEMPLATE_SUCCESS';
export const ADD_DEVICE_TEMPLATE_FAILURE = 'ADD_DEVICE_TEMPLATE_FAILURE';

export const UPDATE_DEVICE_TEMPLATE_REQUEST = 'UPDATE_DEVICE_TEMPLATE_REQUEST';
export const UPDATE_DEVICE_TEMPLATE_SUCCESS = 'UPDATE_DEVICE_TEMPLATE_SUCCESS';
export const UPDATE_DEVICE_TEMPLATE_FAILURE = 'UPDATE_DEVICE_TEMPLATE_FAILURE';

export const DELETE_DEVICE_TEMPLATE_REQUEST = 'DELETE_DEVICE_TEMPLATE_REQUEST';
export const DELETE_DEVICE_TEMPLATE_SUCCESS = 'DELETE_DEVICE_TEMPLATE_SUCCESS';
export const DELETE_DEVICE_TEMPLATE_FAILURE = 'DELETE_DEVICE_TEMPLATE_FAILURE';



export const ADD_ASSET_REQUEST = 'ADD_ASSET_REQUEST';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_FAILURE = 'ADD_ASSET_FAILURE';

export const CLEAR_ASSET = 'CLEAR_ASSET';
export const FETCH_ASSET_REQUEST = 'FETCH_ASSET_REQUEST';
export const FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS';
export const FETCH_ASSET_FAILURE = 'FETCH_ASSET_FAILURE';


export const ADD_OWNERSHIP_REQUEST = 'ADD_OWNERSHIP_REQUEST';
export const ADD_OWNERSHIP_SUCCESS = 'ADD_OWNERSHIP_SUCCESS';
export const ADD_OWNERSHIP_FAILURE = 'ADD_OWNERSHIP_FAILURE';

export const UPDATE_OWNERSHIP_REQUEST = 'UPDATE_OWNERSHIP_REQUEST';
export const UPDATE_OWNERSHIP_SUCCESS = 'UPDATE_OWNERSHIP_SUCCESS';


export const DELETE_OWNERSHIP_REQUEST = 'DELETE_OWNERSHIP_REQUEST';
export const DELETE_OWNERSHIP_SUCCESS = 'DELETE_OWNERSHIP_SUCCESS';

export const UPLOAD_INVOICE_REQUEST = 'UPLOAD_INVOICE_REQUEST';
export const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS';
export const UPLOAD_INVOICE_FAILURE = 'UPLOAD_INVOICE_FAILURE';

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';


export const UPDATE_ASSET_OVERVIEW_REQUEST = 'UPDATE_ASSET_OVERVIEW_REQUEST';
export const UPDATE_ASSET_OVERVIEW_SUCCESS = 'UPDATE_ASSET_OVERVIEW_SUCCESS';
export const UPDATE_ASSET_OVERVIEW_FAILURE = 'UPDATE_ASSET_OVERVIEW_FAILURE';

export const ADD_ASSET_NOTE_REQUEST = 'ADD_ASSET_NOTE_REQUEST';
export const ADD_ASSET_NOTE_SUCCESS = 'ADD_ASSET_NOTE_SUCCESS';
export const ADD_ASSET_NOTE_FAILURE = 'ADD_ASSET_NOTE_FAILURE';


export const UPDATE_ASSET_NOTE_REQUEST = 'UPDATE_ASSET_NOTE_REQUEST';
export const UPDATE_ASSET_NOTE_SUCCESS = 'UPDATE_ASSET_NOTE_SUCCESS';
export const UPDATE_ASSET_NOTE_FAILURE = 'UPDATE_ASSET_NOTE_FAILURE';

export const DELETE_ASSET_NOTE_REQUEST = 'DELETE_ASSET_NOTE_REQUEST';
export const DELETE_ASSET_NOTE_SUCCESS = 'DELETE_ASSET_NOTE_SUCCESS';
export const DELETE_ASSET_NOTE_FAILURE = 'DELETE_ASSET_NOTE_FAILURE';



export const FETCH_ASSET_CATEGORIES_REQUEST = 'FETCH_ASSET_CATEGORIES_REQUEST';
export const FETCH_ASSET_CATEGORIES_SUCCESS = 'FETCH_ASSET_CATEGORIES_SUCCESS';
export const FETCH_ASSET_CATEGORIES_FAILURE = 'FETCH_ASSET_CATEGORIES_FAILURE';

export const ADD_ASSET_CATEGORY_REQUEST = 'ADD_ASSET_CATEGORY_REQUEST';
export const ADD_ASSET_CATEGORY_SUCCESS = 'ADD_ASSET_CATEGORY_SUCCESS';
export const ADD_ASSET_CATEGORY_FAILURE = 'ADD_ASSET_CATEGORY_FAILURE';

export const UPDATE_ASSET_CATEGORY_REQUEST = 'UPDATE_ASSET_CATEGORY_REQUEST';
export const UPDATE_ASSET_CATEGORY_SUCCESS = 'UPDATE_ASSET_CATEGORY_SUCCESS';
export const UPDATE_ASSET_CATEGORY_FAILURE = 'UPDATE_ASSET_CATEGORY_FAILURE';



export const ADD_SAVED_VIEW_REQUEST = 'ADD_SAVED_VIEW_REQUEST';
export const ADD_SAVED_VIEW_SUCCESS = 'ADD_SAVED_VIEW_SUCCESS';
export const ADD_SAVED_VIEW_FAILURE = 'ADD_SAVED_VIEW_FAILURE';

export const UPDATE_SAVED_VIEW_REQUEST = 'UPDATE_SAVED_VIEW_REQUEST';
export const UPDATE_SAVED_VIEW_SUCCESS = 'UPDATE_SAVED_VIEW_SUCCESS';
export const UPDATE_SAVED_VIEW_FAILURE = 'UPDATE_SAVED_VIEW_FAILURE';


export const DELETE_SAVED_VIEW_REQUEST = 'DELETE_SAVED_VIEW_REQUEST';
export const DELETE_SAVED_VIEW_SUCCESS = 'DELETE_SAVED_VIEW_SUCCESS';
export const DELETE_SAVED_VIEW_FAILURE = 'DELETE_SAVED_VIEW_FAILURE';


export const FETCH_USER_DIRECTORY_REQUEST = 'FETCH_USER_DIRECTORY_REQUEST';
export const FETCH_USER_DIRECTORY_SUCCESS = 'FETCH_USER_DIRECTORY_SUCCESS';
export const FETCH_USER_DIRECTORY_FAILURE = 'FETCH_USER_DIRECTORY_FAILURE';

export const ADD_USER_DIRECTORY_REQUEST = 'ADD_USER_DIRECTORY_REQUEST';
export const ADD_USER_DIRECTORY_SUCCESS = 'ADD_USER_DIRECTORY_SUCCESS';
export const ADD_USER_DIRECTORY_FAILURE = 'ADD_USER_DIRECTORY_FAILURE';
export const CLEAR_USER_DIRECTORY_ERROR = 'CLEAR_USER_DIRECTORY_ERROR';


export const FETCH_USER_ASSETLIST_REQUEST = 'FETCH_USER_ASSETLIST_REQUEST';
export const FETCH_USER_ASSETLIST_SUCCESS = 'FETCH_USER_ASSETLIST_SUCCESS';
export const FETCH_USER_ASSETLIST_FAILURE = 'FETCH_USER_ASSETLIST_FAILURE';


export const ADD_CATEGORY_CUSTOMFIELD_REQUEST = 'ADD_CATEGORY_CUSTOMFIELD_REQUEST';
export const ADD_CATEGORY_CUSTOMFIELD_SUCCESS = 'ADD_CATEGORY_CUSTOMFIELD_SUCCESS';
export const ADD_CATEGORY_CUSTOMFIELD_FAILURE = 'ADD_CATEGORY_CUSTOMFIELD_FAILURE';


export const UPDATE_CATEGORY_CUSTOMFIELD_REQUEST = 'UPDATE_CATEGORY_CUSTOMFIELD_REQUEST';
export const UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS = 'UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS';
export const UPDATE_CATEGORY_CUSTOMFIELD_FAILURE = 'UPDATE_CATEGORY_CUSTOMFIELD_FAILURE';

export const DELETE_CATEGORY_CUSTOMFIELD_REQUEST = 'DELETE_CATEGORY_CUSTOMFIELD_REQUEST';
export const DELETE_CATEGORY_CUSTOMFIELD_SUCCESS = 'DELETE_CATEGORY_CUSTOMFIELD_SUCCESS';
export const DELETE_CATEGORY_CUSTOMFIELD_FAILURE = 'DELETE_CATEGORY_CUSTOMFIELD_FAILURE';



export const ADD_MDMPLATFORM_REQUEST = 'ADD_MDMPLATFORM_REQUEST';
export const ADD_MDMPLATFORM_SUCCESS = 'ADD_MDMPLATFORM_SUCCESS';
export const ADD_MDMPLATFORM_FAILURE = 'ADD_MDMPLATFORM_FAILURE';

export const DELETE_MDMPLATFORM_REQUEST = 'DELETE_MDMPLATFORM_REQUEST';
export const DELETE_MDMPLATFORM_SUCCESS = 'DELETE_MDMPLATFORM_SUCCESS';
export const DELETE_MDMPLATFORM_FAILURE = 'ADD_MDMPLATFDELETE_MDMPLATFORM_FAILUREORM_FAILURE';


export const TOGGLE_MDM_REQUEST = 'TOGGLE_MDM_REQUEST';
export const TOGGLE_MDM_SUCCESS = 'TOGGLE_MDM_SUCCESS';
export const TOGGLE_MDM_FAILURE = 'TOGGLE_MDM_FAILURE';
