import {    
    FETCH_DEVICE_TEMPLATES_REQUEST,
    FETCH_DEVICE_TEMPLATES_SUCCESS,
    FETCH_DEVICE_TEMPLATES_FAILURE,

    ADD_DEVICE_TEMPLATE_REQUEST,
    ADD_DEVICE_TEMPLATE_SUCCESS,
    ADD_DEVICE_TEMPLATE_FAILURE,

    UPDATE_DEVICE_TEMPLATE_REQUEST,
    UPDATE_DEVICE_TEMPLATE_SUCCESS,
    UPDATE_DEVICE_TEMPLATE_FAILURE,


    DELETE_DEVICE_TEMPLATE_REQUEST,
    DELETE_DEVICE_TEMPLATE_SUCCESS,
    DELETE_DEVICE_TEMPLATE_FAILURE    
} from '../actions';


const initialState = {
    isLoading: false,
    error: '',
    templates: []
}

const deviceTemplatesReducer = ((state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEVICE_TEMPLATES_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case FETCH_DEVICE_TEMPLATES_SUCCESS:
            return { ...state, templates: action.payload, isLoading: false, error: '' };
        case FETCH_DEVICE_TEMPLATES_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
            


        case ADD_DEVICE_TEMPLATE_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case ADD_DEVICE_TEMPLATE_SUCCESS:
            return { ...state, templates: [...state.templates, action.payload], isLoading: false, error: '' };
        case ADD_DEVICE_TEMPLATE_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
            


        case UPDATE_DEVICE_TEMPLATE_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case UPDATE_DEVICE_TEMPLATE_SUCCESS:
            const updatedTemplates = state.templates.reduce((memo, x) => {
                if (x._id === action.payload._id) {
                    return [...memo, action.payload];
                } else {
                    return [...memo, x];
                }
            }, []);
            return { ...state, templates: updatedTemplates, isLoading: false, error: '' };
        case UPDATE_DEVICE_TEMPLATE_FAILURE:
            return { ...state, isLoading: false, error: action.payload };

        case DELETE_DEVICE_TEMPLATE_REQUEST:
            return { ...state, isLoading: true, error: '' };
        case DELETE_DEVICE_TEMPLATE_SUCCESS:
            return { ...state, templates: state.templates.filter(x => x._id !== action.payload), isLoading: false, error: '' };
        case DELETE_DEVICE_TEMPLATE_FAILURE:
            return { ...state, isLoading: false, error: action.payload };

        default:
            return state;
    }
});

export default deviceTemplatesReducer;