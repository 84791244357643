import Box from '@material-ui/core/Box';

const Loading = () => {
    return (
        <Box component='div' display="flex" flexDirection='column' alignItems="center">
        <span className="half-page-load fadeOut">
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
                </div>                
            </span>
        </Box>          
    )
}

const LoadingPage = () => {
    return (
        <div className="twothirds-page-load fadeOut">          
        <div className="lds-ripple"><div></div><div></div></div>
        <span>Loading<span className='blink-me'>...</span></span>
      </div>      
    )
}

export {
    Loading,
    LoadingPage
}