import { API_URL } from '../../env';

import { 
    axiosWithAuth,
 } from '../../utils/axiosWithAuth';

import {
    FETCH_USER_DIRECTORY_REQUEST,
    FETCH_USER_DIRECTORY_SUCCESS,
    FETCH_USER_DIRECTORY_FAILURE,
    ADD_USER_DIRECTORY_REQUEST,
    ADD_USER_DIRECTORY_SUCCESS,
    ADD_USER_DIRECTORY_FAILURE,
    CLEAR_USER_DIRECTORY_ERROR

} from './actionTypes';

const baseUri = `${API_URL}/api/v1`;

export const getUserDirectory = ( ) => async (dispatch, getState) => {    
    const { users, isLoading } = getState().userDirectory;
    if (users.length || isLoading) {
        return false;        
    }
    const uri = `${baseUri}/admin/users/directory`;
    dispatch({ type: FETCH_USER_DIRECTORY_REQUEST});
    try {
        const response = await axiosWithAuth().get(uri);
        dispatch({ type: FETCH_USER_DIRECTORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: FETCH_USER_DIRECTORY_FAILURE, payload: err.response.data });
    }
}


export const syncUserDirectory = ( ) => async (dispatch) => {    

    const uri = `${baseUri}/admin/users/directory/sync`;
    dispatch({ type: FETCH_USER_DIRECTORY_REQUEST});
    try {
        const response = await axiosWithAuth().patch(uri);
        dispatch({ type: FETCH_USER_DIRECTORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: FETCH_USER_DIRECTORY_FAILURE, payload: err.response.data});
    }
}

export const addDirectoryUser = (user ) => async (dispatch) => {
    const uri = `${baseUri}/admin/users/directory`;
    dispatch({ type: ADD_USER_DIRECTORY_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, user);
        dispatch({ type: ADD_USER_DIRECTORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: ADD_USER_DIRECTORY_FAILURE, payload: err.response.data});
        setTimeout(() => { dispatch({ type: CLEAR_USER_DIRECTORY_ERROR}) }, 5000);        
    }
}

