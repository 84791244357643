import React, { useEffect } from 'react';
import {  
    useDispatch
   , useSelector 
  } from 'react-redux';

  import {
    Link as RouterLink
  } from 'react-router-dom';
import { 
      Typography,
      Box,
      makeStyles,
} from '@material-ui/core';  

import {     
    Table,    
    TableBody,
    TableCell,    
    TableHead,
    TableRow,
    Hidden,
  } from '@material-ui/core';

import { Loading } from '..';
import { getUserAssetList } from '../../store/actions'

const useStyles = makeStyles((theme) => ({
    striped: {
      backgroundColor: '#EAEAEA',
      paddingTop: '.5em',
      paddingBottom: '.5em',
      textDecoration: 'none',
      color: 'black'
    },
    unstriped: {
        backgroundColor: '#fff',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        textDecoration: 'none',
        color: 'black'
      },
    content: {
        padding: theme.spacing(3),    
    }
  }));

const AssignmentList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isLoading, users } = useSelector((state) => state.userAssets);
    useEffect(() => {
        dispatch(getUserAssetList());      
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);  

    
        return (
            <div className={classes.content}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                     <h2>Assignments</h2>
                <Table size="small" aria-label="list of assets">
                    <TableHead>
                      <TableRow>
                        <Hidden smDown>
                            <TableCell>
                                <Typography variant="overline">Type</Typography>
                            </TableCell>
                        </Hidden>
                        <TableCell>
                            <Typography variant="overline">Assignee</Typography>
                        </TableCell>                            
                        <TableCell>
                            <Typography variant="overline">Devices</Typography>
                        </TableCell>
                        <Hidden smDown>
                            <TableCell>
                                <Typography variant="overline">Condition</Typography>
                            </TableCell>   
                        </Hidden>                                                  
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {users && users.map((item, index) => (
                        <TableRow key={index} className={(index % 2 === 0) ? classes.unstriped : classes.striped}>
                            <Hidden smDown>
                                <TableCell>
                                    <Typography variant="overline">{item.ownerType}</Typography>
                                </TableCell> 
                            </Hidden>
                            <TableCell>
                                <Typography variant="caption">{item.ownerDisplay}</Typography>
                            </TableCell>                                                            
                            
                            <TableCell>                    
                            {item.assets.map((asset, assetIndex) => (
                                <Box display="flex" key={assetIndex} flexDirection='column'>
                                <Typography                                     
                                    variant="caption"
                                    className='clickable'
                                    component={RouterLink} to={`/category/${asset.assetType}/asset/${asset._id}`}
                                >{asset.assetName}</Typography>
                                </Box>                                    
                                ))}
                            </TableCell>
                            <Hidden smDown>
                                <TableCell> 
                                {item.assets.map((asset, assetIndex) => (               
                                    <Box display="flex" key={assetIndex} flexDirection='column'>
                                    <Typography                                     
                                        variant="caption"                                    
                                    >{asset.assetCondition}</Typography>
                                    </Box>
                                    ))}
                                </TableCell>
                            </Hidden>
                        </TableRow>
                    ))}
                    </TableBody>
                    </Table>


                </>
                   
                )}
                </div>
        )
}

export {
    AssignmentList
}