import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,    
    Icon,    
    Button,
    Modal,
   } from '@material-ui/core';
import {  
    useSelector,
  } from 'react-redux';
  import {
    useNavigate
  } from 'react-router-dom';    
import { CategoryBox } from './CategoryBox';
import { NewCategory } from './NewCategory';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    icon: {
      color: theme.palette.primary.main// 'rgba(255, 255, 255, 0.54)',
    },
    content: {
      padding: theme.spacing(3),    
    },
    fontSizeXLarge: {
        fontSize: theme.typography.pxToRem(64),
    },    
  }));
const Categories = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [modalOpen, updateModalOpen] = useState(false);
    const { categories } = useSelector((state) => state.assetCategories);
    const goToNew = (newName) => {
      navigate(`category/${newName}`);
    }


    return (

        <div className={`${classes.root}`}>
            
            <Modal
                open={modalOpen}
                onClose={() => { updateModalOpen(false)}}
                aria-labelledby="Add New Category"
                aria-describedby="Add New Category"
                component="p"
            >
              <div>
                <NewCategory reportBack={goToNew} cancelAdd={() => updateModalOpen(false) }/>

              </div>
              
            </Modal>   

            

            <Grid container spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              >
                <h2>Categories</h2>
                <Button 
                  variant='outlined'
                  color="default"
                  size="small"
                  onClick={() => updateModalOpen(true) }
                  >Add</Button>
            </Grid>
              <Grid container spacing={2}>
              {categories.map(({ assetType, icon, categoryName }, index) => (
                <Grid item md={4} sm={4} xs={12} key={index}>
                  <CategoryBox
                      content={{
                        resourceText: `${categoryName}`,
                        path: `${assetType}`
                      }}
                      headingIcon={<Icon className={`${classes.fontSizeXLarge} ${classes.icon}`}>{icon}</Icon>}
                      headingTitle={`${categoryName}`}
                    />              
                </Grid>
                ))}
            </Grid>            
          </div>

    )
}

export {
    Categories
}