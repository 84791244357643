import { Link } from 'react-router-dom';
import {
    Box,
    Typography,
} from '@material-ui/core';

const NotFound = () => {
    return (
        <Box className='half-page-load'>
            <Typography
                color="textSecondary"
                variant="caption"
            >            
                <h2>It looks like this page doesn't exist...</h2>
            </Typography>
            <p>
                <Link to="/">Take me back</Link>
            </p>            
        </Box>
    )
}

export { NotFound };