
import React, { useEffect } from 'react';

import { GoogleLogin } from 'react-google-login';
import { GOOGLE_CLIENT_ID } from '../../env';
import {  
  useNavigate
} from "react-router-dom";

import {
  Box,
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { loginGoogle } from '../../store/actions';

const googleId = GOOGLE_CLIENT_ID;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  intro: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: 345,

  },
  media: {
    width: '150px',
  },
}));

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading: isAuthLoading } = useSelector((state) => state.auth);   

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
   
  const responseGoogle = (googleUser) => {    
    const token = googleUser.getAuthResponse().id_token;
    // const profile = googleUser.getBasicProfile();    
   dispatch(loginGoogle(token));
  }
  const badResponseGoogle = (response) => {
    console.error(response);
  }

  return (
    <>
    {isAuthLoading ? (
      <div className="full-page-load">          
        <div className="lds-ripple"><div></div><div></div></div>
        <span>Loading<span className='blink-me'>...</span></span>
      </div>
    ) : (     
  <Container component="main" maxWidth="xs">
    <div className={classes.paper}>
    <Card className={classes.root}>
        <CardContent>        
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">      
          <Box p={3}>
            <img src="/logos/CPM.svg" className={classes.media} alt="CPM logo" />
            <Typography gutterBottom>
              Educational Program
            </Typography>          
          </Box>

          <Box p={3} flexShrink={0}>
              <GoogleLogin
                  clientId={googleId}
                  buttonText="Google CPM Login"
                  onSuccess={responseGoogle}
                  onFailure={badResponseGoogle}
                />
          </Box>
        </Box>
        </CardContent>
    </Card>
    </div>
  </Container>
  
  )} 
  </>
  )
    }

export {
    Login
} 
