import { API_URL } from '../../env';
import { 
    axiosWithAuth,
    } from '../../utils/axiosWithAuth';


import {
    FETCH_ASSET_CATEGORIES_REQUEST,
    FETCH_ASSET_CATEGORIES_SUCCESS,
    FETCH_ASSET_CATEGORIES_FAILURE,

    ADD_ASSET_CATEGORY_REQUEST,
    ADD_ASSET_CATEGORY_SUCCESS,
    ADD_ASSET_CATEGORY_FAILURE,
    

    UPDATE_ASSET_CATEGORY_REQUEST,
    UPDATE_ASSET_CATEGORY_SUCCESS,
    UPDATE_ASSET_CATEGORY_FAILURE,    

    ADD_CATEGORY_CUSTOMFIELD_REQUEST,
    ADD_CATEGORY_CUSTOMFIELD_SUCCESS,
    ADD_CATEGORY_CUSTOMFIELD_FAILURE,


    UPDATE_CATEGORY_CUSTOMFIELD_REQUEST,
    UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS,
    UPDATE_CATEGORY_CUSTOMFIELD_FAILURE,

    DELETE_CATEGORY_CUSTOMFIELD_REQUEST,
    DELETE_CATEGORY_CUSTOMFIELD_SUCCESS,
    DELETE_CATEGORY_CUSTOMFIELD_FAILURE,


    FETCH_DEVICE_TEMPLATES_REQUEST,
    FETCH_DEVICE_TEMPLATES_SUCCESS,
    FETCH_DEVICE_TEMPLATES_FAILURE,

    ADD_DEVICE_TEMPLATE_REQUEST,
    ADD_DEVICE_TEMPLATE_SUCCESS,
    ADD_DEVICE_TEMPLATE_FAILURE,

    UPDATE_DEVICE_TEMPLATE_REQUEST,
    UPDATE_DEVICE_TEMPLATE_SUCCESS,
    UPDATE_DEVICE_TEMPLATE_FAILURE,

    DELETE_DEVICE_TEMPLATE_REQUEST,
    DELETE_DEVICE_TEMPLATE_SUCCESS,
    DELETE_DEVICE_TEMPLATE_FAILURE,

    ADD_SAVED_VIEW_REQUEST,
    ADD_SAVED_VIEW_SUCCESS,
    ADD_SAVED_VIEW_FAILURE,

    UPDATE_SAVED_VIEW_REQUEST,
    UPDATE_SAVED_VIEW_SUCCESS,
    UPDATE_SAVED_VIEW_FAILURE,    

    DELETE_SAVED_VIEW_REQUEST,
    DELETE_SAVED_VIEW_SUCCESS,
    DELETE_SAVED_VIEW_FAILURE,


    ADD_MDMPLATFORM_REQUEST,
    ADD_MDMPLATFORM_SUCCESS,
    ADD_MDMPLATFORM_FAILURE,

    DELETE_MDMPLATFORM_REQUEST,
    DELETE_MDMPLATFORM_SUCCESS,
    DELETE_MDMPLATFORM_FAILURE,


    TOGGLE_MDM_REQUEST,
    TOGGLE_MDM_SUCCESS,
    TOGGLE_MDM_FAILURE,

} from './actionTypes';


const adminUri = `${API_URL}/api/v1/admin`;
const mdmUri = `${API_URL}/api/v1/mdm`;



export const getAssetCategories = ( ) => async (dispatch, getState) => {    
    const status = 'active'
    const { isLoading } = getState().assetCategories;
    if (isLoading) {
        return false;
    }
    dispatch({ type: FETCH_ASSET_CATEGORIES_REQUEST});
    try {
        const response = await axiosWithAuth().get(`${adminUri}/categories/${status}`);
        
        dispatch({ type: FETCH_ASSET_CATEGORIES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: FETCH_ASSET_CATEGORIES_FAILURE, payload: err.message});
    }
}

export const addAssetCategory = ( category ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/category`;
    
    dispatch({ type: ADD_ASSET_CATEGORY_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, category);
        dispatch({ type: ADD_ASSET_CATEGORY_SUCCESS, payload: response.data});
        
    } catch (err) {
            dispatch({ type: ADD_ASSET_CATEGORY_FAILURE, payload: err.message});
    }
}


export const updateAssetCategory = ( category ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/category/${category._id}`;
    
    dispatch({ type: UPDATE_ASSET_CATEGORY_REQUEST});
    try {
        const response = await axiosWithAuth().put(uri, category);
        dispatch({ type: UPDATE_ASSET_CATEGORY_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: UPDATE_ASSET_CATEGORY_FAILURE, payload: err.message});
    }
}

export const deleteAssetCategoryCustomField = ( { categoryId, customFieldId } ) => async (dispatch, getState) => {     
    const uri = `${adminUri}/category/${categoryId}/custom/${customFieldId}`;
    
    dispatch({ type: DELETE_CATEGORY_CUSTOMFIELD_REQUEST});
    try {
        await axiosWithAuth().delete(uri);
        dispatch({ type: DELETE_CATEGORY_CUSTOMFIELD_SUCCESS, payload: {categoryId, customFieldId}});
    } catch (err) {
        dispatch({ type: DELETE_CATEGORY_CUSTOMFIELD_FAILURE, payload: err.message});
    }
}

export const addAssetCategoryCustomField = ( { categoryId, customField } ) => async (dispatch, getState) => {     
    const uri = `${adminUri}/category/${categoryId}/custom`;
    
    dispatch({ type: ADD_CATEGORY_CUSTOMFIELD_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, { customField });
        dispatch({ type: ADD_CATEGORY_CUSTOMFIELD_SUCCESS, payload: { categoryId, customField: response.data }});
    } catch (err) {
        dispatch({ type: ADD_CATEGORY_CUSTOMFIELD_FAILURE, payload: err.message});
    }
}

export const updateAssetCategoryCustomField = ( { categoryId, customField } ) => async (dispatch, getState) => {     
    const { _id: customFieldId } = customField;
    const uri = `${adminUri}/category/${categoryId}/custom/${customFieldId}`;
    
    dispatch({ type: UPDATE_CATEGORY_CUSTOMFIELD_REQUEST});
    try {
        const response = await axiosWithAuth().put(uri, { customField });
        dispatch({ type: UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS, payload: { categoryId, customField: response.data } });
    } catch (err) {
            dispatch({ type: UPDATE_CATEGORY_CUSTOMFIELD_FAILURE, payload: err.message});
    }
}



export const getDeviceTemplates = ( deviceType ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/templates/${deviceType}`;
    
    dispatch({ type: FETCH_DEVICE_TEMPLATES_REQUEST});
    try {
        const response = await axiosWithAuth().get(uri);
        dispatch({ type: FETCH_DEVICE_TEMPLATES_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: FETCH_DEVICE_TEMPLATES_FAILURE, payload: err.message});
    }
}

export const updateDeviceTemplate = ( template ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/template/${template._id}`;
    
    dispatch({ type: UPDATE_DEVICE_TEMPLATE_REQUEST});
    try {
        const response = await axiosWithAuth().put(uri, template);
        dispatch({ type: UPDATE_DEVICE_TEMPLATE_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: UPDATE_DEVICE_TEMPLATE_FAILURE, payload: err.message});
    }
}
export const deleteDeviceTemplate = ( templateId ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/template/${templateId}`;
    
    dispatch({ type: DELETE_DEVICE_TEMPLATE_REQUEST});
    try {
        await axiosWithAuth().delete(uri);
        dispatch({ type: DELETE_DEVICE_TEMPLATE_SUCCESS, payload: templateId});
    } catch (err) {
        dispatch({ type: DELETE_DEVICE_TEMPLATE_FAILURE, payload: err.message});
    }
}

export const addDeviceTemplate = ( template ) => async (dispatch, getState) => {    
    const uri = `${adminUri}/template`;
    
    dispatch({ type: ADD_DEVICE_TEMPLATE_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, template);
        dispatch({ type: ADD_DEVICE_TEMPLATE_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: ADD_DEVICE_TEMPLATE_FAILURE, payload: err.message});
    }
}

export const addSavedView = ( savedView ) => async (dispatch) => {    
    const uri = `${adminUri}/views`;
    
    dispatch({ type: ADD_SAVED_VIEW_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, savedView);
        dispatch({ type: ADD_SAVED_VIEW_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: ADD_SAVED_VIEW_FAILURE, payload: err.message});
    }
}

export const updateSavedView = ( viewId, savedView ) => async (dispatch) => {
    const uri = `${adminUri}/views/${viewId}`;
    
    dispatch({ type: UPDATE_SAVED_VIEW_REQUEST});
    try {
        const response = await axiosWithAuth().patch(uri, savedView);
        dispatch({ type: UPDATE_SAVED_VIEW_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: UPDATE_SAVED_VIEW_FAILURE, payload: err.message});
    }
}


export const deleteSavedView = ( savedViewId ) => async (dispatch) => {    
    const uri = `${adminUri}/views/${savedViewId}`;
    
    dispatch({ type: DELETE_SAVED_VIEW_REQUEST});
    try {
        await axiosWithAuth().delete(uri);
        dispatch({ type: DELETE_SAVED_VIEW_SUCCESS, payload: savedViewId});
    } catch (err) {
            dispatch({ type: DELETE_SAVED_VIEW_FAILURE, payload: err.message});
    }
}



export const enableMdm = ( { assetType } ) => async (dispatch) => {    
    const uri = `${adminUri}/category/${assetType}/mdm/enable`;
    
    dispatch({ type: TOGGLE_MDM_REQUEST});
    try {
        await axiosWithAuth().patch(uri);
        dispatch({ type: TOGGLE_MDM_SUCCESS, payload: { assetType, useMdm: true }});
    } catch (err) {
            dispatch({ type: TOGGLE_MDM_FAILURE, payload: err.message});
    }
}

export const disableMdm = ( { assetType } ) => async (dispatch) => {    
    const uri = `${adminUri}/category/${assetType}/mdm/disable`;
    
    dispatch({ type: TOGGLE_MDM_REQUEST});
    try {
        await axiosWithAuth().patch(uri);
        dispatch({ type: TOGGLE_MDM_SUCCESS, payload: { assetType, useMdm: false }});
    } catch (err) {
            dispatch({ type: TOGGLE_MDM_FAILURE, payload: err.message});
    }
}

export const addMDMPlatform = ( { assetType, mdmProvider, platform } ) => async (dispatch) => {    
    const uri = `${adminUri}/category/${assetType}/mdm/provider/${mdmProvider}`;
    
    dispatch({ type: ADD_MDMPLATFORM_REQUEST});
    try {
        await axiosWithAuth().patch(uri, { platform });
        dispatch({ type: ADD_MDMPLATFORM_SUCCESS, payload: { assetType, mdmProvider, platform }});
    } catch (err) {
            dispatch({ type: ADD_MDMPLATFORM_FAILURE, payload: err.message});
    }
}

export const deleteMDMPlatform = ( { assetType, mdmProvider, platform } ) => async (dispatch) => {    
    const uri = `${adminUri}/category/${assetType}/mdm/provider/${mdmProvider}`;
    
    dispatch({ type: DELETE_MDMPLATFORM_REQUEST});
    try {
        await axiosWithAuth().delete(uri, { data: { platform } });
        dispatch({ type: DELETE_MDMPLATFORM_SUCCESS, payload: { assetType, mdmProvider, platform }});
    } catch (err) {
            dispatch({ type: DELETE_MDMPLATFORM_FAILURE, payload: err.message});
    }
}

export const syncMdm = async ( assetType ) => {
    const uri = `${mdmUri}/sync/${assetType}`;
    
    try {
        const response = await axiosWithAuth().patch(uri);
        return response.data;
    } catch (err) {
            throw err.message;
    }
}


export const getSavedView = async ( viewId ) => {
    const uri = `${adminUri}/views/${viewId}`;
    
    try {
        const response = await axiosWithAuth().get(uri);
        return response.data;
    } catch (err) {
            throw err.message;
    }
}





export const getCategoryTemplates = async (  ) => {
    const uri = `${adminUri}/category/generator`;
    
    try {
        const response = await axiosWithAuth().get(uri);
        return response.data;
    } catch (err) {
            throw err.message;
    }
}

export const addCategoryByTemplate = async ( templateName, { assetType, categoryName } ) => {
    const uri = `${adminUri}/category/generator/${templateName}`;
    
    try {
        const response = await axiosWithAuth().post(uri, { assetType, categoryName });        
        return response.data;
    } catch (err) {
            throw err.message;
    }
}