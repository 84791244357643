import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {     
    Table,    
    TableBody,
    TableCell,    
    TableHead,
    TableRow,
    Typography,
    Grid,
    Hidden,
    Box,
    makeStyles,
    Tooltip,
    Button,
    Snackbar,
    Slide
  } from '@material-ui/core';
  import SyncIcon from '@material-ui/icons/Sync';
  import AddIcon from '@material-ui/icons/Add';
import { 
    getUserDirectory,
    syncUserDirectory,
    addDirectoryUser
  } from '../../../store/actions';
  import {
    Alert
} from '@material-ui/lab';  
import { InfoDynamic, DynamicForm } from '../../Common';

  const useStyles = makeStyles((theme) => ({
    striped: {
        backgroundColor: '#EAEAEA',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursort: 'pointer'
    },
    sortedField: {
        color: theme.palette.primary.dark,
        textDecoration: 'underline'
    },
    unstriped: {
        backgroundColor: '#fff',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursort: 'pointer'
    },
    button: {
        // marginLeft: theme.spacing(.5),        
        margin: theme.spacing(.5),
        marginTop: theme.spacing(1.5),
    },
    root: {
        // display: 'flex',
        width: '100%'
    },
    content: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}));

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }
  
  const addUserDefaults = [
    {
      fieldName: 'firstName',
      fieldDisplay: 'First Name',
      fieldValue: '',
      fieldType: 'text',      
      displayLength: 'half' 
    },       
    {
        fieldName: 'lastName',
        fieldDisplay: 'Last Name',
        fieldValue: '',
        fieldType: 'text',
        displayLength: 'half'
    },    
    {
        fieldName: 'email',
        fieldDisplay: 'Email',
        fieldValue: '',
        fieldType: 'email',
        displayLength: 'third',      
    },
    {
        fieldName: 'department',
        fieldDisplay: 'Department',
        fieldValue: '',
        fieldType: 'text',
        required: false,
        displayLength: 'third'
    },
    // {
    //     fieldName: 'active',
    //     fieldDisplay: 'Active',
    //     fieldValue: 'true',
    //     fieldType: 'select',
    //     options: ['true', 'false'],
    //     displayLength: 'third',      
    // },
    {
        fieldName: 'employeeId',
        fieldDisplay: 'Employee ID',
        fieldValue: '',
        fieldType: 'text',        
        displayLength: 'third',
        required: false
    },        
  ];


const Users = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [ searchTerm, updateSearchTerm] = useState();
    const { users, isLoading, error } = useSelector((state) => state.userDirectory, shallowEqual);    
    const [ filteredUsers, updateFilteredUsers] = useState([]);
    const [ disableSync, updateDisableSync] = useState(false);

    const [addUserState, updateAddUserState] = useState(false);
    const [snackbarState, updateSnackbarState] = useState({
        open: false,
        message: '',
        Transition: SlideTransition,
        severity: 'info'
      });

    useEffect(() => {        
        dispatch(getUserDirectory());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {        
        if (error) {
            updateSnackbarState({...snackbarState, open: true, message: error, severity: 'error'});
        } else {
            updateSnackbarState({...snackbarState, open: false, message: ''});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);    
    useEffect(() => {        
        if (!users.length) {            
            updateFilteredUsers([]);
            return false;
        }
        if (!searchTerm) {         
            updateFilteredUsers(users);
            return false;
        }

        const searchToLower = searchTerm.toLowerCase();
        
        const filteredList = users.reduce((memo, user) => {                        
            try {
                if (Object.values(user).find(myValue => (typeof myValue !== 'object' && typeof myValue !== 'undefined') && myValue.toString().toLowerCase().startsWith(searchToLower))) {
                    return [...memo, user];    
                } else {
                    return memo;
                }
            } catch(err) {
                Object.keys(user).forEach(item => {
                   console.log(item, user[item], (typeof user[item] !== 'object' && typeof user[item] !== 'undefined'))
                });
                return memo;
            }
            
        }, []);
        updateFilteredUsers(filteredList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, users]);

    const handleClose = () => {
        updateSnackbarState({ ...snackbarState, open: false });
    };

    const syncUsers = () => {
        updateDisableSync(true);   
        updateSnackbarState({...snackbarState, open: true, severity: 'info', message: 'A sync has been initiated.  The list below should be updated shortly.'});
        try {
            dispatch(syncUserDirectory());
        } catch (err) {
            updateDisableSync(false);
            updateSnackbarState({...snackbarState, open: true,  severity: 'error', message: err.message});
        }        
    }

    const handleAddUser = (newUser) => {
        dispatch(addDirectoryUser(newUser));
        updateAddUserState(false);
    }

    return (
        <div className={`${classes.root} fadeIn ${classes.content}`}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={5000}
              onClose={handleClose}
              TransitionComponent={snackbarState.Transition}
              open={snackbarState.open}              
              message={snackbarState.message}
              key={snackbarState.Transition.name}
              >
              <Alert onClose={handleClose} severity={snackbarState.severity}>
                {snackbarState.message}
              </Alert>
          </Snackbar>            

        <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"      
        >
            <h2>Users</h2>
            

            <Grid item> 
            <Tooltip title="Sync Users with Directory Service" aria-label="sync">
                            <Button
                                size="small"
                                disabled={disableSync}
                                className='button-list'
                                variant='contained'
                                color="primary"
                                onClick={syncUsers} 
                                ><SyncIcon />
                            </Button>
                </Tooltip>
            
            <Tooltip title="Add New User" aria-label="sync">
                            <Button
                                size="small"
                                className='button-list'
                                variant='contained'
                                color="primary"
                                onClick={() => updateAddUserState(true)} 
                                ><AddIcon />
                            </Button>
                </Tooltip>
            </Grid>            
        </Grid>
        {addUserState && (
            <DynamicForm formDefaults={addUserDefaults} handleSubmit={handleAddUser} handleCancel={() => updateAddUserState(false)} />
        )}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
        
            <Grid item md={5} sm={12} xs={12} >            
            <InfoDynamic
                            fieldType='text'
                            header='Search'                    
                            fieldName='searchTerm'
                            fieldValue={searchTerm}
                            editMode={true}
                            handleChange={(newSearchTerm) => updateSearchTerm(newSearchTerm.searchTerm)}
                    />
            </Grid>
        </Grid>

        <Box p={1} />
        <Table size="small" aria-label="list of users" style={isLoading ? {'display': 'none'} : {}}>
            <TableHead>
              <TableRow>
                <TableCell>
                        <Typography variant="overline">Name</Typography>
                </TableCell>  
                <Hidden xsDown>
                <TableCell>
                        <Typography variant="overline">Email</Typography>
                </TableCell>
                </Hidden>
                <Hidden smDown>
                <TableCell>
                        <Typography variant="overline">Department</Typography>
                </TableCell>
                </Hidden>
                <Hidden smDown>
                <TableCell>
                        <Typography variant="overline">Source</Typography>
                </TableCell>
                </Hidden>                
              </TableRow>
            </TableHead>
            
            <TableBody>
            {filteredUsers.map((user, index) => (
                <TableRow key={index} className={(index % 2 === 0) ? classes.unstriped : classes.striped}>
                    <TableCell>
                        <Typography variant="caption">{user.fullName}</Typography>
                    </TableCell>
                    <Hidden xsDown>                    
                    <TableCell>
                        <Typography variant="caption">{user.email}</Typography>
                    </TableCell>
                    </Hidden>
                    <Hidden smDown>
                    <TableCell>
                        <Typography variant="caption">{user.department}</Typography>
                    </TableCell>                    
                    </Hidden>
                    <Hidden smDown>
                    <TableCell>
                        <Typography variant="caption">{user.directoryProvider}</Typography>
                    </TableCell>                    
                    </Hidden>                    
                </TableRow>
            ))}
            </TableBody>
            
            </Table>

    </div>

    )
}

export {
    Users
};