import {    
    FETCH_ASSET_CATEGORIES_REQUEST,
    FETCH_ASSET_CATEGORIES_SUCCESS,
    FETCH_ASSET_CATEGORIES_FAILURE,

    ADD_ASSET_CATEGORY_REQUEST,
    ADD_ASSET_CATEGORY_SUCCESS,
    ADD_ASSET_CATEGORY_FAILURE,

    UPDATE_ASSET_CATEGORY_REQUEST,
    UPDATE_ASSET_CATEGORY_SUCCESS,
    UPDATE_ASSET_CATEGORY_FAILURE,

    
    ADD_CATEGORY_CUSTOMFIELD_SUCCESS,       
    UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS,        
    DELETE_CATEGORY_CUSTOMFIELD_SUCCESS,


    // ADD_MDMPLATFORM_REQUEST,
    ADD_MDMPLATFORM_SUCCESS,
    // ADD_MDMPLATFORM_FAILURE,

    // DELETE_MDMPLATFORM_REQUEST,
    DELETE_MDMPLATFORM_SUCCESS,

    // DELETE_MDMPLATFORM_FAILURE,

    // ENABLE_MDMPLATFORM_REQUEST,
    // DISABLE_MDMPLATFORM_SUCCESS,
    TOGGLE_MDM_SUCCESS
    
} from '../actions';

const initialState = {
    isLoading: false,
    error: '',
    lastAddedId: null,
    categories: []
}



const assetCategoryReducer = ((state = initialState, action) => {
    switch (action.type) {

        case FETCH_ASSET_CATEGORIES_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case FETCH_ASSET_CATEGORIES_SUCCESS:
            return { ...state, categories: action.payload, isLoading: false, error: '' };
        case FETCH_ASSET_CATEGORIES_FAILURE:
            return { ...state, isLoading: false, error: action.payload };


        case ADD_ASSET_CATEGORY_REQUEST:            
        return { ...state, isLoading: true, error: '' };
        case ADD_ASSET_CATEGORY_SUCCESS:
            return { ...state, categories: [...state.categories, action.payload], lastAddedId: action.payload._id, isLoading: false, error: '' };
        case ADD_ASSET_CATEGORY_FAILURE:
            return { ...state, isLoading: false, error: action.payload };            


        case UPDATE_ASSET_CATEGORY_REQUEST:
            return { ...state, isLoading: true, error: '' };
        case UPDATE_ASSET_CATEGORY_SUCCESS:
            const updatedCategories = state.categories.reduce((memo, x) => {
                if (x._id === action.payload._id) {                    
                    return [...memo, {...x, ...action.payload}];
                } else {
                    return [...memo, x];
                }
            }, []);
            return { ...state, categories: updatedCategories, isLoading: false, error: '' };
        case UPDATE_ASSET_CATEGORY_FAILURE:
            return { ...state, isLoading: false, error: action.payload };    

        case TOGGLE_MDM_SUCCESS:
            const toggleMDMCategory = state.categories.reduce((memo, category) => {
                if (category.assetType === action.payload.assetType) {                    
                    return [...memo, {...category, mdmProviders: {}, useMdm: action.payload.useMdm }];
                } else {
                    return [...memo, category];
                }
            }, []);
            return { ...state, categories: toggleMDMCategory, isLoading: false, error: '' };    

        case ADD_MDMPLATFORM_SUCCESS:
            const newProviderCategories = state.categories.reduce((memo, category) => {
                if (category.assetType === action.payload.assetType) {
                                                      
                    const mdmProviders = {...category.mdmProviders, [action.payload.mdmProvider]: [...category.mdmProviders[action.payload.mdmProvider] || [], ...[action.payload.platform]] };
                    return [...memo, {...category, mdmProviders }];
                } else {
                    return [...memo, category];
                }
            }, []);
            return { ...state, categories: newProviderCategories, isLoading: false, error: '' };            
        case DELETE_MDMPLATFORM_SUCCESS:
            const lessProviderCategories = state.categories.reduce((memo, category) => {
                if (category.assetType === action.payload.assetType) {                                    
                    const lessMdmProviders = {...category.mdmProviders, [action.payload.mdmProvider]: category.mdmProviders[action.payload.mdmProvider].filter(x => x !== action.payload.platform) };
                    return [...memo, {...category, mdmProviders: lessMdmProviders }];
                } else {
                    return [...memo, category];
                }
            }, []);
            return { ...state, categories: lessProviderCategories, isLoading: false, error: '' };    

        case ADD_CATEGORY_CUSTOMFIELD_SUCCESS:
            const newCategoriesDeleteCustomFields = state.categories.reduce((memo, category) => {
                if (category._id === action.payload.categoryId) {     
                    return [...memo, {...category, customFields: [...category.customFields, action.payload.customField]}];
                } else {
                    return [...memo, category];
                }
            }, []);
            return { ...state, categories: newCategoriesDeleteCustomFields, isLoading: false, error: '' };    



        case UPDATE_CATEGORY_CUSTOMFIELD_SUCCESS:                   
            const updatedCategoriesCustomFields = state.categories.reduce((memo, x) => {
                if (x._id === action.payload.categoryId) {
                    const updatedCustomFields = x.customFields.map(item => {
                        if (item._id === action.payload.customField._id) {
                            return action.payload.customField;
                        } else {
                            return item;
                        }
                    });
                    return [...memo, {...x, customFields: updatedCustomFields }];
                } else {
                    return [...memo, x];
                }
            }, []);
            return { ...state, categories: updatedCategoriesCustomFields, isLoading: false, error: '' };
        case DELETE_CATEGORY_CUSTOMFIELD_SUCCESS:
            const updatedCategoriesDeleteCustomFields = state.categories.reduce((memo, category) => {
                if (category._id === action.payload.categoryId) {
                    const deletedCustomFields =  category.customFields.filter(customField => customField._id !== action.payload.customFieldId);                        
                    return [...memo, {...category, customFields: deletedCustomFields}];
                } else {
                    return [...memo, category];
                }
            }, []);
            return { ...state, categories: updatedCategoriesDeleteCustomFields, isLoading: false, error: '' };            

            default:
                return state;
        }
});

export default assetCategoryReducer;