import React, { useState } from 'react';
import { 
    useSelector,
    useDispatch,
    shallowEqual,
  } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,
    Divider,
    List, ListItem, ListItemText, Avatar, ListItemAvatar,
    Grid }
     from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';

import { UploadFile } from '../Common';
import { uploadAssetFile, deleteAssetFile } from '../../store/actions';
import { openFile } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {  
      '& > *': {
        margin: theme.spacing(1),
      },      
    },
    dangerButton: {
        backgroundColor: theme.palette.danger.dark,        
    },
    accentButton: {
        backgroundColor: theme.palette.accent.main,        
    },   
    accentButtonLight: {
        backgroundColor: theme.palette.accent.light,        
    },        
    secondaryButton: {
        backgroundColor: theme.palette.primary.main,
        hover: {
            backgroundColor: theme.palette.accent.dark,        
        }  
    },
    hidden: {
        display: 'none'
    }
  }));

const AssetInvoices = ( ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { asset } = useSelector((state) => state.asset, shallowEqual);
    const [editMode, updateEditMode] = useState(false);
    const [addMode, updateAddMode] = useState(false);
    const [deletingId, updateDeletingId] = useState();

    const handleUploadFile = (formData) => {
        dispatch(uploadAssetFile(asset._id, formData));
        updateAddMode(false);
      }

    const handleDeletefile = (storageId) => {
        updateDeletingId(storageId);
        dispatch(deleteAssetFile(asset._id, storageId));
    }

    const toggleEditMode = () => {
        updateAddMode(false);
        updateEditMode(!editMode);

    }
    const toggleAddMode = () => {
        updateEditMode(false);        
        updateAddMode(!addMode);
    } 
    const downloadfile = (storageName) => {
        openFile(storageName)
        // dispatch(openFile(storageName));
    }
    return (
        <>
         <Grid
            container
            direction="row"
            justifyContent="flex-end"
            component="div"            
            >                
    

            {editMode ? (
                <Avatar
                    onClick={toggleEditMode}                    
                    className={classes.accentButton}>                
                        <CloseIcon/>
                </Avatar>                                
                ) : (

                <Avatar
                    onClick={toggleEditMode}
                    className={asset && asset.files.length ? classes.accentButton : classes.hidden}>
                        <DeleteIcon/>
                </Avatar>   

            )}   


            <Box pr={1}></Box>

            {addMode ? (
                <Avatar
                    onClick={toggleAddMode}
                    className={classes.secondaryButton}>                
                        <CloseIcon/>
                </Avatar>                                
                ) : (
                <Avatar
                    onClick={toggleAddMode}                    
                    className={classes.secondaryButton}>
                        <AddIcon/>
                </Avatar>               
            )}            

        </Grid>


        <Box pt={1}>
            <Divider />
        </Box>
        {addMode && (
            <UploadFile handleUpload={handleUploadFile} />
        )}
        <List className={classes.root}>
            {asset && asset.files.map((file, index) => (
                <ListItem className={file.storageId === deletingId ? 'fadeOut' : ''} key={`file-${index}-key`}>
                    <ListItemAvatar>
                        <Avatar className={editMode ? classes.accentButton : classes.secondaryButton}>
                            {editMode ? (
                                <DeleteForeverIcon onClick={() => handleDeletefile(file.storageId)} />
                            ) : (
                                <AttachFileIcon onClick={()=>downloadfile(file.storageName)} />
                            )}
                        
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={file.name} secondary={file.description} />
                </ListItem>                
            ))}    
        </List>        
        </>

                
    )
}

export { AssetInvoices };


