import {
    Grid,
} from '@material-ui/core';
import { InfoDynamic } from '../Common';

const CategoryUniqueFields = ({ editMode, editCategory, handleChange }) => {
    return (
      <Grid container spacing={2}>

        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
            fieldType='switch'
            header='Unique Serial Field'
            fieldName='useSerial'
            fieldValue={ (editCategory.useSerial === true) }
            editMode={editMode}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
            fieldType='switch'
            header='Unique Text Field'
            fieldName='useUniqueCategoryString1'
            fieldValue={ (editCategory.useUniqueCategoryString1 === true) }
            editMode={editMode}
            handleChange={handleChange}
          />

          {editCategory.useUniqueCategoryString1 && (
            <>
              <InfoDynamic
              fieldType='text'
              header='Display Name'
              fieldName='uniqueCategoryString1Display'
              fieldValue={editCategory.uniqueCategoryString1Display || ""}
              editMode={editMode}
              handleChange={handleChange}
            />
            </>
          )}
          </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <InfoDynamic
            fieldType='switch'
            header='Unique Number Field'
            fieldName='useUniqueCategoryNumber1'
            fieldValue={(editCategory.useUniqueCategoryNumber1 === true) }
            editMode={editMode}
            handleChange={handleChange}
          />        

        {editCategory.useUniqueCategoryNumber1 && (
          <>
          <InfoDynamic
            fieldType='text'
            header='Display Name'
            fieldName='uniqueCategoryNumber1Display'
            fieldValue={editCategory.uniqueCategoryNumber1Display || ""}
            editMode={editMode}
            handleChange={handleChange}
          />

          </>
          )}

        </Grid>
      </Grid>
    )
}

export {
  CategoryUniqueFields
}