import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { theme } from '../../theme';
import { useWindowSize } from '../../utils';
import {  
    useDispatch,
    useSelector 
} from 'react-redux';
import {
    useParams,
    useNavigate,
    // Link as RouterLink
  } from 'react-router-dom';
import { 
      Box,
      Grid,
      Button,
      makeStyles,
      CssBaseline,
      Collapse,
      FormControl,
      FormLabel,
      FormGroup,      
      Typography,
      Chip,
      Paper,
      Tooltip
} from '@material-ui/core';  


import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import FilterListIcon from '@material-ui/icons/FilterList';
import SyncIcon from '@material-ui/icons/Sync';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

// import { devicesViewReducer } from '../../store/reducers/devicesViewReducer';

import { getAssetType, addNewDevice, addSavedView, updateSavedView, syncMdm } from '../../store/actions'
import { 
    initView,
    updateSort,
    updateStatus,
    updateFieldDisplay,
    addFilter,
    deleteFilter,
    getSavedView
 } from '../../store/reducers/devicesViewSlice';
import { DeviceList } from './DeviceList';

import { DynamicFormat } from '../Common';

import { ViewCriteria, DisplayFields, SavedCustomViews, AddCustomView } from '../CustomView';
import GetAppIcon from '@material-ui/icons/GetApp';
const useStyles = makeStyles((theme) => ({
    striped: {
        backgroundColor: '#EAEAEA',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursor: 'pointer'
    },
    sortedField: {
        color: theme.palette.primary.dark,
        textDecoration: 'underline'
    },
    unstriped: {
        backgroundColor: '#fff',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        cursort: 'pointer'
    },
    root: {
        // display: 'flex',
        width: '100%'
    },
    content: {
        // display: 'flex',
        // width: '100%'        ,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    fab: {
        position: 'absolute',
        right: theme.spacing(8),
        top: theme.spacing(9),
      },
    fab2: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(9),
    },
    filterChip: {
        color: theme.palette.primary.dark,        
    },
    filterChipIcon: {
        color: theme.palette.secondary.dark,        
    },    
    
}));


const FILTERS_NAME = 'filters';
const FIELDS_NAME = 'fields';
const VIEWS_NAME = 'views';
// const NEW_VIEW = 'newView';

const Devices = () => {
    const classes = useStyles();
    const size = useWindowSize();
    const { assetType } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [category, updateCategory] = useState({standardFields: [], customFields: []});
    
    const deviceView = useSelector(state => state.devicesView[category._id]);

    const { categories, isLoading: isCategoriesLoading } = useSelector((state) => state.assetCategories);
    const { assets, assetStatusTotals, isLoading } = useSelector((state) => state.assets);
    const [assetStatusOptions, updateAssetStatusOptions ] = useState([]);
    const [tableWidth, updateTableWidth] = useState(theme.breakpoints.values.sm);
    const [activeCollapse, updateActiveCollapse] = useState();  //  options 'filters', 'fields', 'views'

    const [addViewMode, updateAddViewMode] = useState(false);
    const [filteredAssets, updateFilteredAssets] = useState([]);
    const [exportableAssets, updateExportableAssets] = useState([]);

    const [searchTerm, updateSearchTerm] = useState();

    const fetchAssets = () => {        
        if (deviceView) {
            const { sort, viewFilters, assetStatuses} = deviceView;
            dispatch(getAssetType(assetType, {assetStatuses, sort, viewFilters}));
        }
    }
    
    const addDevice = async () => {
        try {
            const newDevice = await addNewDevice(assetType);            
            navigate(`asset/${newDevice._id}`, {isNew:true});
        } catch (err) {
            alert(err.message);
        }
    }

    const handleSyncMdm = async () => {
        try {
            alert("syncing.  this could take a few minutes")
            await syncMdm(category.assetType);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (category && category._id) {
            dispatch(initView({ ...category, categoryId: category._id, viewId: category._id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [category]); 
    
    useEffect(() => {                
        if (!isCategoriesLoading && categories.length) {
            const currentCategory = categories.find(x => x.assetType === assetType);
            if (currentCategory) {                 
                updateCategory(currentCategory);       
            } else {
                console.log('unable to find category.  Assuming that it is a typo so redirecting back to the main dashboard');
                navigate(`../../`, { relative: 'path'})
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetType, categories]);

    useEffect(() => {        
        const formattedStatusTotals = assetStatusTotals.map(x => ({ fieldDisplay: `${x.assetStatus} (${x.count})`, fieldValue: x.assetStatus}));        
        updateAssetStatusOptions(formattedStatusTotals);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetStatusTotals]);      

    useEffect(() => { 
        fetchAssets();
      // eslint-disable-next-line react-hooks/exhaustive-deps          
    }, [deviceView]);




    useEffect(() => {
        if (size.width >= theme.breakpoints.values.md) {      
            updateTableWidth(size.width - 240);
        } else if (size.width <= theme.breakpoints.values.md) {
            updateTableWidth(size.width - 75);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [size]);


      // Filter list based on search term
      const destruct = (obj, ...keys) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

      useEffect(() => {        
          if (!assets.length) {            
              updateFilteredAssets([]);
              return false;
          }
          if (!searchTerm) {         
              updateFilteredAssets(assets);
              return false;
          }
          const searchableFields = deviceView.viewFields.filter(item => item.display).map(item => item.fieldName);
          const filteredList = assets.reduce((memo, asset) => {
              const assetSubset = destruct(asset, ...searchableFields);
              try {
                  if (Object.values(assetSubset).find(myValue => (typeof myValue !== 'object' && typeof myValue !== 'undefined') && myValue.toString().startsWith(searchTerm))) {
                      return [...memo, asset];
                  } else {
                      return memo;
                  }
              } catch(err) {
                  console.log(err)
                  Object.keys(assetSubset).forEach(item => {
                     console.log(item, assetSubset[item], (typeof assetSubset[item] !== 'object' && typeof assetSubset[item] !== 'undefined'))
                  });
                  return memo;
              }
              
          }, []);
          updateFilteredAssets(filteredList);

          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchTerm, assets]);   




    const loadCustomView = (viewId) => {
        if (!viewId) {
            alert("Invalid Saved View")
        }        
        dispatch(getSavedView(viewId));        
    }

    const handleUpdateSavedView = (viewId) => {        
        if (deviceView && deviceView.viewId === viewId) {
            dispatch(updateSavedView(viewId, deviceView));        
        }
    }    

    const handleCollapsePane = (newPane) => {
        updateActiveCollapse(activeCollapse === newPane ? '' : newPane);
    }


    const handleChangeStatus = (assetStatus) => {        
        dispatch(updateStatus( { _id: category._id, assetStatus }));        
    }

    const handleSortOrderChange = (sort) => {
        dispatch(updateSort({ _id: category._id, sort }));        
    }

    const handleDisplayFieldChange = (displayField) => {        
        dispatch(updateFieldDisplay( { _id: category._id, fieldName: displayField }));
    }

    const addViewFilter = (newCriteria) => {    
        updateActiveCollapse('');
        dispatch(addFilter( { _id: category._id, newFilter: newCriteria }));
      }
    const handleDeleteFilter = (itemIndex) => {
        dispatch(deleteFilter( { _id: category._id, index: itemIndex }));        
    }
     
    const handleSaveNewView = (newValues) => {
        const newView = {isGlobal: false, ...deviceView, assetType, ...newValues };
        dispatch(addSavedView(newView));
        updateActiveCollapse('');
    }


    let csvLink;
    const download = () => {
        // Get a dictionary of display vs internal name so that column headings can use the friendlier version
        const fieldNames = deviceView.viewFields.filter(item => item.display).reduce((memo, item) => {
            return {...memo, [item.fieldName]: item.fieldDisplay };
        }, {});
        
        // Get a list of fields that are currently displayed
        const activeFields = deviceView.viewFields.filter(item => item.display).map(item => item.fieldName);
        
        const reducedFields = filteredAssets.reduce((memo, item) => {
            const assetSubset = destruct(item, ...activeFields);
            const mappedKeys = Object.keys(assetSubset).reduce((keyMemo, key) => {
                // A double quote in the text will prevent the file from being properly delimited, which seems to be a common issue with csv
                // but... if we replace a double quote with a double quote the deliminiter is respected

                // eslint-disable-next-line react-hooks/exhaustive-deps
                return {...keyMemo, [fieldNames[key]]: typeof assetSubset[key] === 'string' ? assetSubset[key].replaceAll('"', '""') : assetSubset[key]};
            }, {});
            return [...memo, mappedKeys];
        }, []);

        // Defer the actual export to useEffect so that 
        updateExportableAssets(reducedFields);        
    }

    useEffect(() => {
        if (exportableAssets.length) {
            const myLink = csvLink;
            myLink.link.click();

        } // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [exportableAssets]);

    return (
        <div>

        <CssBaseline />      
            <CSVLink
            data={exportableAssets}
            filename="assetList.csv"
            className="hidden"
            separator=";"
            ref={(r) => csvLink = r}                   
            target="_blank"/>

      <div className={classes.content} style={{backgroundColor: 'WhiteSmoke'}}>
                
      <Box pt={3}></Box>

      <Grid
        container
        direction="row"
        justifyContent="space-between"        
        >
        <Grid item>            
            <FormControl component="fieldset" >
                <FormLabel component="legend">
                        <Typography color='primary' variant="overline">Custom Views</Typography>
                </FormLabel>

                <FormGroup>
                    <Box display="flex" alignContent="flex-end">                        
                    <Button
                                size="small"
                                className='button-list'
                                variant='outlined'
                                color="secondary"
                                onClick={() => handleCollapsePane(VIEWS_NAME)}
                                startIcon={activeCollapse === VIEWS_NAME ? <ClearIcon /> : <PermDataSettingIcon />}
                                >Saved Views
                            </Button>

                            <Button
                                size="small"
                                className='button-list'
                                variant='outlined'
                                color="secondary"
                                onClick={() => handleCollapsePane(FIELDS_NAME)}
                                startIcon={activeCollapse === FIELDS_NAME ? <ClearIcon /> : <PlaylistAddCheckIcon />}
                                >Columns
                            </Button>     
                            <Button
                                size="small"
                                className='button-list'
                                variant='outlined'
                                color="secondary"
                                onClick={() => handleCollapsePane(FILTERS_NAME)}
                                startIcon={activeCollapse === FILTERS_NAME ? <ClearIcon /> : <FilterListIcon />}
                                >Add Filter
                            </Button>                                               
                    </Box>
                </FormGroup>
            </FormControl>
        </Grid>
        <Grid item>       
            <FormControl component="fieldset" >
                <FormLabel component="legend">
                    <Typography color='primary' variant="overline">Actions</Typography>
                </FormLabel>
                <FormGroup>
                    <Box display="flex" alignContent="flex-end">
                        {category && category.useMdm && (
                            <Tooltip title="Sync MDM" aria-label="sync mdm">
                                <Button
                                    size="small"
                                    className='button-list'
                                    onClick={handleSyncMdm}
                                    variant='contained'
                                    color="primary"                                    
                                    ><SyncIcon />
                                </Button>
                            </Tooltip>
                        )}
                        
                        <Tooltip title="Download" aria-label="download">
                            <Button
                                size="small"
                                className='button-list'
                                variant='contained'
                                color="primary"
                                onClick={download} 
                                ><GetAppIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={`Add ${category.categoryName || 'Device'}`} aria-label={`Add ${category.categoryName || 'Device'}`}>
                            <Button
                                size="small"
                                className='button-list'
                                to='add'
                                variant='contained'
                                color="primary"                                
                                onClick={addDevice}
                                ><AddIcon />
                            </Button>
                            </Tooltip>

                    </Box>
                </FormGroup>
            </FormControl>
        </Grid>



        </Grid>




      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        >
        <Grid item>                
            <FormControl component="fieldset" >
                <FormLabel component="legend">
                    <Typography color='primary' variant="overline">Status</Typography>
                </FormLabel>
                <FormGroup>
                    <Box display="flex" alignContent="flex-end">        
                        {assetStatusOptions.map((item, index) => (
                            <Button
                                key={index}
                                size="small"
                                className='button-list'
                                color="primary"
                                variant={deviceView && Array.isArray(deviceView.assetStatuses) && deviceView.assetStatuses.includes(item.fieldValue) ? 'contained' : 'outlined'}
                                onClick={() =>handleChangeStatus(item.fieldValue)}
                                >{item.fieldDisplay}
                            </Button>
                        ))}              
                    </Box>

                    <Box display="flex" pt={1} alignContent="flex-end">
                        
                    {deviceView && deviceView.viewFilters.map((item, index) => (
                                <li className='chipList' key={`filter-${index}-index`}>
                                    <Chip
                                        size="small"                    
                                        color='secondary' 
                                        variant='outlined'                                        
                                        icon={<FilterListIcon/>}   
                                        label={<span>{item.fieldDisplay} {item.fieldOperator} <DynamicFormat fieldType={item.fieldType} fieldValue={item.fieldValue} /></span>}                    
                                        onDelete={() => handleDeleteFilter(index)}
                                    />
                                </li>            
                            ))}   
                    </Box>
                </FormGroup>
            </FormControl>
        </Grid>
    
    </Grid>
    
    
    <Box pt={1}></Box>

    <Collapse in={activeCollapse === VIEWS_NAME}>        
            <Paper>
            <Box p={2}>
                {(addViewMode) ? (                                   
                    <Box display="flex" flexDirection='column'>
                    <Typography color='primary' variant="overline">New Saved View</Typography>
                    <AddCustomView handleSave={handleSaveNewView} handleCancel={() => updateAddViewMode(false)} />                    
                    <Typography color='primary' variant="overline">Existing Saved Views</Typography>
                    </Box>
                ) : (
                    <Box display="flex" alignItems='center' justifyContent="space-between">
                        <Typography color='primary' variant="overline">Manage Saved Views</Typography>
                        <Box display="flex" alignItems='center' justifyContent="space-between">
                            {/* {deviceView && deviceView.viewId !== category._id && (
                                <Button className='button-list' size='small' variant='outlined' onClick={() => updateAddViewMode(true)} >Update</Button>
                            )} */}
                                
                            <Button className='button-list' size='small' color='primary' variant='outlined' onClick={() => updateAddViewMode(true)} >Create</Button>
                        </Box>
                    </Box>
                )}
                </Box>
                
                <Box pl={2}>
                            
                    <SavedCustomViews 
                        activeViewId={deviceView && deviceView.viewId} 
                        handleSelect={loadCustomView}
                        handleUpdate={handleUpdateSavedView}
                        assetType={assetType} />
                </Box>
                </Paper> 
            <Box pb={2} />
        </Collapse>

        <Collapse in={activeCollapse === FILTERS_NAME}>
            <Paper>
                <Box p={3}>
                    {deviceView && deviceView.viewFields && (
                        <ViewCriteria isNew={true} criteria={{}} viewFields={deviceView.viewFields} handleChange={addViewFilter} />
                    )}
                </Box>            
            </Paper>
            <Box pb={2} />
        </Collapse>


        <Collapse in={activeCollapse === FIELDS_NAME}>
            <Paper>
                <Box p={3}>
                    {deviceView && deviceView.viewFields && (
                    <DisplayFields displayFields={deviceView.viewFields} handleChange={handleDisplayFieldChange} />                    
                    )}
                </Box>
            </Paper>
            <Box pb={2} />
        </Collapse>
        </div>
                
        <Box p={1}></Box>
       
        {deviceView && deviceView.viewFields && (
            <div className={`${classes.root} fadeIn`} style={{maxWidth: tableWidth}}>
                <Box pt={2}></Box>
                <DeviceList 
                    assets={filteredAssets} 
                    displayFields={deviceView.viewFields}
                    sortOrder={deviceView.sort}
                    searchTerm={searchTerm}
                    handleSearchTermChange={updateSearchTerm}
                    isLoading={isLoading}
                    handleSortChange={handleSortOrderChange} 
                />
            </div>
        )}

        </div>
    )
}

export {
    Devices
};