import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { debounce } from 'lodash';
import rootReducer from './reducers';

const debounceNotify = debounce((notify) => notify());

export default createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk), batchedSubscribe(debounceNotify))
)