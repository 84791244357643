// FETCH_ASSET_TOTALS_REQUEST,
//     FETCH_ASSET_TOTALS_SUCCESS,
//     FETCH_ASSET_TOTALS_FAILURE,

import {    
    FETCH_ASSET_TYPE_REQUEST,
    FETCH_ASSET_TYPE_SUCCESS,
    FETCH_ASSET_TYPE_FAILURE,

} from '../actions';


const initialState = {
    isLoading: false,
    error: '',
    assets: [],
    assetStatusTotals: []
}

const devicesReducer = ((state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASSET_TYPE_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case FETCH_ASSET_TYPE_SUCCESS:
            return { ...state, ...action.payload, isLoading: false, error: '' };
        case FETCH_ASSET_TYPE_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
});

export default devicesReducer;