import React, { useState, createRef, useEffect } from 'react';

import {
    useDispatch,
    useSelector,
  } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    Modal,
} from '@material-ui/core';

import {    
    Button,
    Grid
} from '@material-ui/core';

import { InfoDynamic } from '../Common';
import { updateDeviceTemplate, addDeviceTemplate, deleteDeviceTemplate } from '../../store/actions';
import { backfillCustomFields } from '../../utils';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },  
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center'
  },
  activeRecordHeader: { backgroundColor: '#E0E0E0' },
  button: {
    margin: theme.spacing(.5),
  },  
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}



const Template = ({ assetType, template=null, isNew=false }) => {
  const classes = useStyles();
  const form = createRef();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.assetCategories);
  const [modalStyle] = useState(getModalStyle);
  const [editMode, updateEditMode] = useState(isNew);
  const [editTemplate, updateTemplate] = useState(template);

  const [confirmModelOpen, updateConfirmModlOpen] = useState(false);
  
  const seedTemplate = () => {
    const category = categories.find(item => item.assetType === assetType);
    const {_id, customFields: unsafeCategoryCustomFields, ...restOfCategory} = category;
    const templateCustomFields = template && Array.isArray(template.customFields) ? template.customFields : [];
    const categoryCustomFields = Array.isArray(unsafeCategoryCustomFields) ? unsafeCategoryCustomFields : [];      

    const customFields = backfillCustomFields(categoryCustomFields, templateCustomFields);
    
    updateTemplate({ ...restOfCategory, ...template, customFields });
  }

  const toggleEditMode = () => {
    if (editMode) {
      seedTemplate();
    }
      updateEditMode(!editMode);
  };

    const handleTemplateChange = (newValue) => {
        updateTemplate({...editTemplate, ...newValue });        
    }


    const handleCustomFieldChange = (newValue) => {
      try {        
          const fieldName = Object.keys(newValue).pop();
          const newCustomFields = editTemplate.customFields.reduce((memo,item) => {
          if (item.fieldName === fieldName) {              
              return [...memo, {...item, fieldValue: newValue[fieldName]}];
          } else {
              return [...memo, item];
          }
          }, []);
          updateTemplate({...editTemplate, customFields: newCustomFields });
      } catch (err) {
          alert(err.message);
      }
    } 

    const handleSubmit = () => {
        if (form.current.reportValidity()) {
          if (isNew) {
              dispatch(addDeviceTemplate(editTemplate)); 
          } else {
              dispatch(updateDeviceTemplate(editTemplate));
          }
          toggleEditMode();
        }
    }
 
    const handleDelete = (templateId) => {
      dispatch(deleteDeviceTemplate(templateId));
    }

    useEffect(() => {
      seedTemplate();      
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);    


    const modalBody = (
        
      <Card >
          <div style={modalStyle} className={classes.paper}>
          <CardContent>
              <Typography variant="h5" color="textSecondary" component="p" gutterBottom>
                  Confirm Template Delete
              </Typography>
          </CardContent>
          <CardContent>
            <Box display="flex" spacing={3} flexDirection="row" justifyContent="space-around" alignItems="center">
              <Button 
                size="small"
                color="secondary"
                flexGrow={1}
                variant="contained"
                onClick={() => handleDelete(editTemplate._id)}>
                    Confirm
                </Button>
                <Button size="small" color="secondary" variant="outlined" onClick={() => updateConfirmModlOpen(false)}>
                  Cancel
                </Button>                 
            </Box>
          </CardContent>    
          <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                      This action cannot be undone.  Any assets created using the template will be retained.
              </Typography>  
          </CardContent>                             
  </div>
      </Card>
);


  return (
    <>

      <Modal
                open={confirmModelOpen}
                onClose={() => { updateConfirmModlOpen(false)}}
                aria-labelledby="confirm license push"
                aria-describedby="Verify that you wish to replace CPM licenses with latest data"
            >
                {modalBody}
      </Modal>    
    
    {editTemplate ? (
    <Card className={classes.root}>
        <form ref={form} className={classes.form}  onSubmit={e => e.preventDefault()}>

      
      
          
      <CardHeader               
        subheader={
          <InfoDynamic 
            header='Template Name'
            fieldName='template'
            fieldValue={editTemplate.template}
            isRequired={true}
            editMode={editMode}
            handleChange={handleTemplateChange}/>
        }
      />


      <CardContent>      
      <div>
          <InfoDynamic
            fieldType='shortText'
            header='Friendly Name'
            fieldName='assetName'
            fieldValue={editTemplate.assetName}
            isRequired={false}
            editMode={editMode}
            handleChange={handleTemplateChange}
          />
          <Grid container spacing={3}>
          {editTemplate && editTemplate.customFields && editTemplate.customFields.map((myField, myFieldIndex) => (
              <Grid key={myFieldIndex} item md={myField.displayLength === 'full' ? 12 : 6} sm={6} xs={12}>  
                  <InfoDynamic 
                    fieldType={myField.fieldType}
                    header={myField.fieldDisplay} 
                    fieldName={myField.fieldName} 
                    fieldValue={editTemplate.customFields[myFieldIndex].fieldValue}
                    editMode={editMode}
                    options={myField.options || []}
                    // isRequired={editTemplate.customFields[myFieldIndex].required}
                    isRequired={false}
                    handleChange={handleCustomFieldChange}
                    />
              </Grid>        
            ))}
          </Grid>
      </div>

      </CardContent>
      
      
      {editMode && isNew && (
        <CardActions>
        <Button fullWidth size="small" color="primary" variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        </CardActions>
      )}

        {editMode && !isNew && (
           <CardContent className={classes.activeRecordHeader}> 

              <Box display="flex" flexDirection="row" alignItems="space-between">
                            <Box flexGrow={1}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    className={classes.button}                        
                                    onClick={() => updateConfirmModlOpen(true)}
                                >Delete</Button>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}                        
                                onClick={handleSubmit}
                            >Save</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={toggleEditMode}
                                className={classes.button}                        
                            >Cancel</Button>                            
                </Box>
                  
            </CardContent>
        )}
        {!editMode && !isNew && (
          <CardActions>
            <Button fullWidth size="small" color="primary" variant="contained"  onClick={toggleEditMode}>
                Edit
            </Button>
            </CardActions>
        )
        }
        

      
      </form>  
    </Card>
    ) : (
      <div>Nothing Here - {assetType}</div>
    )}
    </>
  );
}

export {
    Template
};