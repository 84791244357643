
import assetsReducer from './assetsReducer';
import assetTotalsReducer from './assetTotalsReducer';
import assetReducer from './assetReducer';
import assetTemplatesReducer from './assetTemplatesReducer';
import assetCategoryReducer from './assetCategoriesReducer';
import userDirectoryReducer from './userDirectoryReducer';
import userAssetsReducer from './userAssetsReducer';
import authReducer from './authReducer';
import devicesViewReducer from './devicesViewSlice';
const { combineReducers } = require("redux");

const rootReducer = combineReducers ({
    auth: authReducer,
    assets: assetsReducer,  
    assetTotals: assetTotalsReducer,
    asset: assetReducer,  
    userAssets: userAssetsReducer,
    assetTemplates: assetTemplatesReducer,
    assetCategories: assetCategoryReducer,
    userDirectory: userDirectoryReducer,
    devicesView: devicesViewReducer

});

export default rootReducer;