import { API_URL } from '../../env';
import { 
    axiosWithAuth,
    axiosFile 
} from '../../utils/axiosWithAuth';

import {
    FETCH_ASSET_TOTALS_REQUEST,
    FETCH_ASSET_TOTALS_SUCCESS,
    FETCH_ASSET_TOTALS_FAILURE,
    
    FETCH_ASSET_TYPE_REQUEST,
    FETCH_ASSET_TYPE_SUCCESS,
    FETCH_ASSET_TYPE_FAILURE,

    ADD_ASSET_REQUEST,
    // ADD_ASSET_SUCCESS,
    ADD_ASSET_FAILURE,

    CLEAR_ASSET,
    FETCH_ASSET_REQUEST,
    FETCH_ASSET_SUCCESS,
    FETCH_ASSET_FAILURE,

    ADD_ASSET_NOTE_REQUEST,
    ADD_ASSET_NOTE_SUCCESS,
    ADD_ASSET_NOTE_FAILURE,


    UPDATE_ASSET_NOTE_REQUEST,
    UPDATE_ASSET_NOTE_SUCCESS,
    UPDATE_ASSET_NOTE_FAILURE,
    
    DELETE_ASSET_NOTE_REQUEST,
    DELETE_ASSET_NOTE_SUCCESS,
    DELETE_ASSET_NOTE_FAILURE,



    ADD_OWNERSHIP_REQUEST,
    ADD_OWNERSHIP_SUCCESS,
    ADD_OWNERSHIP_FAILURE,

    UPDATE_OWNERSHIP_REQUEST,
    UPDATE_OWNERSHIP_SUCCESS,
    
    DELETE_OWNERSHIP_REQUEST,
    DELETE_OWNERSHIP_SUCCESS,

    UPLOAD_INVOICE_REQUEST,
    UPLOAD_INVOICE_SUCCESS,
    UPLOAD_INVOICE_FAILURE,

    DELETE_INVOICE_REQUEST,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,
    
    UPDATE_ASSET_OVERVIEW_REQUEST,
    UPDATE_ASSET_OVERVIEW_SUCCESS,
    UPDATE_ASSET_OVERVIEW_FAILURE,
} from './actionTypes';

const baseUri = `${API_URL}/api/v1/assets`;

export const getDeviceTotals = ( ) => async (dispatch, getState) => {   
    const status = 'active'
    const auth = getState().auth;
    if(!auth.isAuthenticated) {
        return false;
    }
    dispatch({ type: FETCH_ASSET_TOTALS_REQUEST});
    try {
        const response = await axiosWithAuth().get(`${baseUri}/totals/${status}`);
        dispatch({ type: FETCH_ASSET_TOTALS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: FETCH_ASSET_TOTALS_FAILURE, payload: err.message});
    }
}

export const getAssetType = ( assetType, settings) => async (dispatch, getState) => {    
    const { isLoading } = getState().assets;
    if (isLoading) {
        return false;
    }
    const uri = `${baseUri}/type/${assetType}`;
    dispatch({ type: FETCH_ASSET_TYPE_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, { ...settings });
        dispatch({ type: FETCH_ASSET_TYPE_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: FETCH_ASSET_TYPE_FAILURE, payload: err.message});
    }
}

export const addNewDevice = async (assetType) => {            
    const uri = `${baseUri}/asset/${assetType}`;
    
    try {
        const response = await axiosWithAuth().post(uri);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export const addNewDeviceAction = ( assetType, device ) => async (dispatch, getState) => {        
    const { _id, ...cleanDevice } = device;
    const uri = `${baseUri}/asset/${assetType}`;
    dispatch({ type: ADD_ASSET_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, cleanDevice);
        dispatch({ type: FETCH_ASSET_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: ADD_ASSET_FAILURE, payload: err.response.data || err.message});
    }
}




export const getDevice = ( deviceId ) => async (dispatch) => {    
    const uri = `${baseUri}/asset/${deviceId}`;
    dispatch({ type: FETCH_ASSET_REQUEST});
    try {
        const response = await axiosWithAuth().get(uri);
        dispatch({ type: FETCH_ASSET_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: FETCH_ASSET_FAILURE, payload: err.response.data || err.message});
    }
}

export const clearDevice = (  ) => (dispatch) => {        
    dispatch({ type: CLEAR_ASSET});    
}


export const addOwnership = ( assetId, data ) => async (dispatch, getState) => {    
    
    const uri = `${baseUri}/asset/${assetId}/owner`;
    dispatch({ type: ADD_OWNERSHIP_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, data);
        dispatch({ type: ADD_OWNERSHIP_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: ADD_OWNERSHIP_FAILURE, payload: err.message});
    }
}

export const updateOwnership = ( assetId, data ) => async (dispatch, getState) => {    
    
    const uri = `${baseUri}/asset/${assetId}/owner/${data._id}`;
    dispatch({ type: UPDATE_OWNERSHIP_REQUEST});
    try {
        const response = await axiosWithAuth().put(uri, data);
        dispatch({ type: UPDATE_OWNERSHIP_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: ADD_OWNERSHIP_FAILURE, payload: err.message});
    }
}

export const deleteOwnership = ( assetId, ownershipId ) => async (dispatch) => {
    dispatch({ type: DELETE_OWNERSHIP_REQUEST});
    const uri = `${baseUri}/asset/${assetId}/owner/${ownershipId}`;
    try {        
        await axiosWithAuth().delete(uri);
        dispatch({ type: DELETE_OWNERSHIP_SUCCESS, payload: ownershipId});
    } catch (err) {
        dispatch({ type: DELETE_INVOICE_FAILURE, payload: err.message});
    }
}

export const addNote = ( assetId, data ) => async (dispatch, getState) => {        
    const uri = `${baseUri}/asset/${assetId}/note`;
    dispatch({ type: ADD_ASSET_NOTE_REQUEST});
    try {
        const response = await axiosWithAuth().post(uri, data);
        dispatch({ type: ADD_ASSET_NOTE_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: ADD_ASSET_NOTE_FAILURE, payload: err.message});
    }
}

export const updateNote = ( assetId, data ) => async (dispatch, getState) => {        
    const uri = `${baseUri}/asset/${assetId}/note/${data._id}`;
    dispatch({ type: UPDATE_ASSET_NOTE_REQUEST});
    try {
        const response = await axiosWithAuth().put(uri, data);
        dispatch({ type: UPDATE_ASSET_NOTE_SUCCESS, payload: response.data});
    } catch (err) {
            dispatch({ type: UPDATE_ASSET_NOTE_FAILURE, payload: err.message});
    }
}

export const deleteNote = ( assetId, noteId ) => async (dispatch) => {
    dispatch({ type: DELETE_ASSET_NOTE_REQUEST});
    const uri = `${baseUri}/asset/${assetId}/note/${noteId}`;
    try {        
        await axiosWithAuth().delete(uri);
        dispatch({ type: DELETE_ASSET_NOTE_SUCCESS, payload: noteId});
    } catch (err) {
        console.log('good by', err)
        dispatch({ type: DELETE_ASSET_NOTE_FAILURE, payload: err.message});
    }
}

export const uploadAssetFile = ( assetId, file ) => async (dispatch) => {
    dispatch({ type: UPLOAD_INVOICE_REQUEST});
    const uri = `${baseUri}/storage/${assetId}`;
    try {        
        const response = await axiosFile().post(uri, file);
        dispatch({ type: UPLOAD_INVOICE_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: UPLOAD_INVOICE_FAILURE, payload: err.message});
    }
}

export const deleteAssetFile = ( assetId, storageId ) => async (dispatch) => {
    dispatch({ type: DELETE_INVOICE_REQUEST});    
    const uri = `${baseUri}/storage/${assetId}/file/${storageId}`;
    try {        
        await axiosFile().delete(uri);
        dispatch({ type: DELETE_INVOICE_SUCCESS, payload: storageId});
    } catch (err) {
        dispatch({ type: DELETE_INVOICE_FAILURE, payload: err.message});
    }
}

export const uploadAssetImage = ( assetId, file ) => async (dispatch) => {
    dispatch({ type: UPDATE_ASSET_OVERVIEW_REQUEST});
    const uri = `${baseUri}/asset/${assetId}/image`;
    try {        
        const response = await axiosFile().post(uri, file);
        dispatch({ type: UPDATE_ASSET_OVERVIEW_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: UPDATE_ASSET_OVERVIEW_FAILURE, payload: err.message});
    }
}

export const openFile = ( file ) => async (dispatch) => {
    const uri = `${baseUri}/file/${file}`;
    window.open(uri)
}


export const updateAssetOverview = ( assetId, updates ) => async (dispatch) => {
    dispatch({ type: UPDATE_ASSET_OVERVIEW_REQUEST});
    const uri = `${baseUri}/asset/${assetId}/overview`;
    try {        
        const response = await axiosWithAuth().patch(uri, updates);
        dispatch({ type: UPDATE_ASSET_OVERVIEW_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({ type: ADD_ASSET_FAILURE, payload: err.response.data || err.message});
    }
}

export const deleteAsset = async ( assetId ) => {
    const uri = `${baseUri}/asset/${assetId}`;
    
    try {
        const response = await axiosWithAuth().delete(uri);        
        return response.data;
    } catch (err) {
            throw err.message;
    }
}