import React, { useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Box,    
    Button,    
    Card,
    CardContent,
    Grid 
} from '@material-ui/core';
import { InfoDynamic } from '../Common';

const useStyles = makeStyles((theme) => ({
    root: {  
        '& > *': {
        margin: theme.spacing(1),
        },      
    },
    button: {
    margin: theme.spacing(.5),
  },
  secondaryButton: {
    backgroundColor: theme.palette.primary.main,
    hover: {
        backgroundColor: theme.palette.accent.dark,        
    }  
  },  
  inactiveRecord: { cursor: 'pointer' },
  activeRecord: { backgroundColor: '#F8F6F6' },
  activeRecordHeader: { backgroundColor: '#E0E0E0' }  
}));
 

const AssetNote = ({record, editMode, handleSubmit, handleCancel, handleDelete}) => {
    const classes = useStyles();
    const [editRecord, updateEditRecord] = useState(record);
    const form = createRef();
    const cancelEdit = () => {
        updateEditRecord(record);
        handleCancel();
    }
    const handleChange = (newValue) => {
        updateEditRecord({...editRecord, ...newValue });
    }
    const submitForm = () => {
        if(form.current.reportValidity()) {
            handleSubmit(editRecord);
        }
    }
    return (
    <form ref={form} onSubmit={e => e.preventDefault()}>
        <Card 
            className={editMode ? classes.activeRecord : classes.inactiveRecord}
        >
        
        <CardContent>
        <Grid container spacing={0}>
            <Grid item  md={4} sm={12} xs={12}>
                <InfoDynamic
                    fieldType='date'
                    header='Date'
                    fieldName='dateAdded'
                    fieldValue={record.dateAdded}
                    editMode={false}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item  md={4} sm={12} xs={12}>
                <InfoDynamic
                    fieldType='shortText'
                    header='First'
                    fieldName='firstName'
                    fieldValue={record.firstName}
                    disabled={true}
                    editMode={false}
                    handleChange={handleChange}
                />
            </Grid>   
            <Grid item  md={4} sm={12} xs={12}>
                <InfoDynamic
                    fieldType='shortText'
                    header='Last'
                    fieldName='lastName'
                    fieldValue={record.lastName}
                    disabled={true}
                    editMode={false}
                    handleChange={handleChange}
                />
            </Grid>                                     

            <Grid item  md={12} sm={12} xs={12}>
                <InfoDynamic
                    fieldType='textArea'
                    header='Note'
                    fieldName='note'
                    fieldValue={editRecord.note}
                    editMode={editMode}
                    handleChange={handleChange}
                />
            </Grid>
            </Grid>
            </CardContent>
            {editMode && (
                <CardContent className={classes.activeRecordHeader}> 
                            <Box display="flex" flexDirection="row" alignItems="space-between">
                                <Box flexGrow={1}>
                                {editRecord._id && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}                        
                                        onClick={handleDelete}
                                    >Delete</Button>
                                )}
                                </Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}                        
                                        onClick={submitForm}
                                    >Save</Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={cancelEdit}
                                        className={classes.button}                        
                                    >Cancel</Button>                            
                            </Box>
                </CardContent>
            )}        
        </Card>
    </form>
    );
}

export {
    AssetNote
}