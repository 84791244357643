import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../env'
import {  axiosWithAuth } from '../../utils/axiosWithAuth';

const adminUri = `${API_URL}/api/v1/admin`;
const initialState = { };


export const getSavedView = createAsyncThunk(
    'devicesView/getSavedView',
    async (viewId, thunkAPI) => {
      try {
        const uri = `${adminUri}/views/${viewId}`;
        const response = await axiosWithAuth().get(uri);
        return response.data;
        } catch (e) {
          throw e.message;
        }  
  });


  export const devicesViewSlice = createSlice({
    name: 'devicesView',
    initialState,
    reducers: {
        initView: (state, action) => {
            const { categoryId, standardFields, customFields, assetType, viewId } = action.payload;
            if (!state || !state[categoryId]) {                
                const initViewFields = [...standardFields, ...customFields].map(x => { 
                    return { fieldName: x.fieldName, fieldDisplay: x.fieldDisplay, fieldType: x.fieldType, display: x.display || false}
                });
                state[categoryId] = {
                    viewId,
                    assetType,
                    categoryId,
                    assetStatuses: ['User'],
                    viewFilters: [ ],        
                    sort: {fieldName: 'assetName', order: 'asc'},
                    viewFields: initViewFields
                }
            }
        },     
        updateSort: (state, action) => {            
            state[action.payload._id].sort = action.payload.sort;
        },
        updateStatus: (state, action) => {
            if (Array.isArray(state[action.payload._id].assetStatuses) && state[action.payload._id].assetStatuses.includes(action.payload.assetStatus)) {
                state[action.payload._id].assetStatuses = state[action.payload._id].assetStatuses.filter(x => x !== action.payload.assetStatus);
            } else {
                state[action.payload._id].assetStatuses = [...state[action.payload._id].assetStatuses, action.payload.assetStatus];
            }            
        },      
        updateFieldDisplay: (state, action) => {            
            const viewFields = state[action.payload._id].viewFields.reduce((memo, item) => {
                return [...memo, item.fieldName === action.payload.fieldName ? { ...item, display: !item.display} : item];
            }, []);            
            state[action.payload._id].viewFields = viewFields;
        },   
        addFilter: (state, action) => {            
            const viewFilters = [...state[action.payload._id].viewFilters, action.payload.newFilter];
            state[action.payload._id].viewFilters = viewFilters;
        },
        deleteFilter: (state, action) => {        
            const viewFilters = state[action.payload._id].viewFilters.filter((_item, index) => action.payload.index !== index);            
            state[action.payload._id].viewFilters = viewFilters;
        },

    },
    extraReducers: {
        [getSavedView.fulfilled]: (state, { payload }) => {
          const { _id: viewId, categoryId, ...rest } = payload;
          state[categoryId] = {...rest, categoryId, viewId};
        },
        [getSavedView.rejected]: (state) => {
          return state;
        },
    },
  })
  
export const { initView, updateSort, updateStatus, updateFieldDisplay, addFilter, deleteFilter } = devicesViewSlice.actions;
export default devicesViewSlice.reducer;