import React, { useEffect, useState, createRef }  from 'react';

import {
    Button,
    Box,
    IconButton
    
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { InfoDynamic } from '../Common';

const initCriteria = 
{
     fieldName: "",
     fieldValue: "",
     fieldOperator: "",
     fieldDisplay: ""
}

const fieldOperators = {
    text: ["=", "≠", "contains", "!contains"],
    date: ["=", ">", "<"],
    number: ["=", "≠", ">", "<"],
    currency: ["=", ">", "<"],
}


const ViewCriteria = ({ criteria, isNew, viewFields, handleChange }) => {
    const [fieldOperator, updateFieldOperator] = useState([]);
    const [fieldType, updateFieldType] = useState("text");
    const [tempCriteria, updateTempCriteria] = useState(initCriteria);
    const form = createRef();
  
    const handleLocalChange = (newValue) => {
      updateTempCriteria({...tempCriteria, ...newValue});
    }
    useEffect(() => {
      updateTempCriteria({...tempCriteria, ...criteria});
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteria]);
    
    useEffect(() => {
        const myTarget = viewFields.find(x => x.fieldName === tempCriteria.fieldName);
        if (myTarget) {
          const myFieldType = myTarget.hasOwnProperty('fieldType') ? myTarget.fieldType : 'text';
          const fieldDisplay = myTarget.hasOwnProperty('fieldDisplay') ? myTarget.fieldDisplay : tempCriteria.fieldName;
          handleLocalChange({fieldDisplay, fieldType: myFieldType});
          updateFieldType(myFieldType);
          updateFieldOperator(fieldOperators[myFieldType]);
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tempCriteria.fieldName]);

      const handleSubmit = (e) => {
        e.preventDefault();
        if(!form.current.reportValidity()) {
          return false;
        }
        handleChange(tempCriteria);
        updateTempCriteria(initCriteria);                
    }


      return (      
        <form ref={form} onSubmit={e => e.preventDefault()}>

        <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}  pr={3}>
              <InfoDynamic
                  fieldType='selectObject'
                  header='Field'
                  isRequired={true}
                  options={viewFields || []}
                  fieldName='fieldName'
                  fieldValue={tempCriteria.fieldName}
                  editMode={isNew}
                  handleChange={handleLocalChange}
              />     
            </Box>
            <Box flexGrow={1} pl={3} pr={3}>
              <InfoDynamic
                  fieldType='select'
                  header='Search Criteria'
                  isRequired={true}
                  options={fieldOperator}
                  fieldName='fieldOperator'
                  disabled={(!tempCriteria.fieldName.length)}
                  fieldValue={tempCriteria.fieldOperator}
                  editMode={isNew}
                  handleChange={handleLocalChange}
              />            
            </Box>        
            <Box flexGrow={1} >
              <InfoDynamic
                fieldType={fieldType}
                header='Value'
                isRequired={true}                                    
                fieldName='fieldValue'
                fieldValue={tempCriteria.fieldValue}
                disabled={(!tempCriteria.fieldName.length)}
                editMode={isNew}
                handleChange={handleLocalChange}
              />
            </Box>        
            <Box  pl={2} alignSelf="center">
              {isNew ? (
                <Button 
                  variant="outlined"
                  size="small"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  >Save</Button>
              ) : ( 
                <IconButton 
                // className={classes.link}
                onClick={handleChange}
                color="secondary"
                aria-label="delete">
                <DeleteIcon />
                </IconButton>
              )}
            </Box>                                
        </Box>
        </form>      
      )

}


export { ViewCriteria };