import React, {  useState  } from 'react';

import {  
    useDispatch,
    useSelector 
} from 'react-redux';

import {   
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Icon,
    makeStyles,
    Button,
 } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';

import { deleteSavedView} from '../../store/actions'
const useStyles = makeStyles((theme) => ({
    processing: {
        opacity: '20%',        
    },
}));
const SavedCustomViews = ({ assetType, activeViewId, handleSelect, handleUpdate }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user } = useSelector((state) => state.auth);
    const [deleteId, updateDeleteId] = useState();

    const handleDelete = (viewId) => {
        dispatch(deleteSavedView(viewId));
        updateDeleteId(viewId)
    }


    return (      

        <List component="nav" aria-label="manage custom views">
                   
                {user && user.savedViews.filter(item => item.assetType === assetType).map((myView, index) => (
                        <ListItem
                            key={index}
                            button
                            selected={activeViewId === myView._id}
                            className={deleteId === myView._id ? classes.processing : ''}
                        >


                    {activeViewId === myView._id && myView.canEdit ? (
                        <>
                        <ListItemIcon onClick={() => handleUpdate(myView._id)}>  
                            <Icon color='primary' aria-label='active saved view'>perm_data_setting</Icon>                
                        </ListItemIcon>
                        <ListItemText
                            primary={myView.viewName}  
                        />

                        <ListItemSecondaryAction>
                            <Button
                                size="small"
                                className='button-list'
                                variant='outlined'
                                color="secondary"
                                onClick={() => handleDelete(myView._id)}                                
                                startIcon={<Icon color='secondary' aria-label='delete saved view'>delete</Icon>}
                                >Delete
                            </Button>   
                            <Button
                                size="small"
                                className='button-list'
                                variant='outlined'
                                color="primary"
                                onClick={() => handleUpdate(myView._id)}
                                startIcon={<Icon color='primary' aria-label='Update saved view'>save</Icon>}
                                >Update
                            </Button>                                                        
                        </ListItemSecondaryAction>                            

                        </>
                    ) : (
                        <>
                        <ListItemIcon onClick={() => handleSelect(myView._id)}>
                            <Icon color={activeViewId === myView._id ? 'secondary' : 'inherit'} aria-label='select saved view'>perm_data_setting</Icon>
                        </ListItemIcon>
                        <ListItemText 
                            primary={myView.viewName}
                            onClick={() => handleSelect(myView._id)}
                        />

                        <ListItemSecondaryAction onClick={() =>handleSelect(myView._id)}>
                            <IconButton edge="end" disabled={(myView.canEdit !== true)} color='primary' aria-label="delete">
                                <Icon color='primary' aria-label='select saved view'>chevron_right</Icon>
                            </IconButton>                            
                        </ListItemSecondaryAction>                            

                        </>

                    )}                
                    </ListItem>
                ))}         
      </List>      
    )
}

export { SavedCustomViews };