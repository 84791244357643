import {    
    CLEAR_ASSET,
    FETCH_ASSET_REQUEST,
    FETCH_ASSET_SUCCESS,
    FETCH_ASSET_FAILURE,

    ADD_ASSET_REQUEST,
    // ADD_ASSET_SUCCESS, // USE FETCH_ACCESS_SUCCESS
    ADD_ASSET_FAILURE,

    UPDATE_ASSET_OVERVIEW_REQUEST,
    UPDATE_ASSET_OVERVIEW_SUCCESS,
    UPDATE_ASSET_OVERVIEW_FAILURE,


    // ADD_ASSET_NOTE_REQUEST,
    // ADD_ASSET_NOTE_FAILURE,
    ADD_ASSET_NOTE_SUCCESS,

    // UPDATE_ASSET_NOTE_REQUEST,
    UPDATE_ASSET_NOTE_SUCCESS,
    // UPDATE_ASSET_NOTE_FAILURE,
    
    // DELETE_ASSET_NOTE_REQUEST,
    DELETE_ASSET_NOTE_SUCCESS,
    // DELETE_ASSET_NOTE_FAILURE,




    // ADD_OWNERSHIP_REQUEST,
    ADD_OWNERSHIP_SUCCESS,
    // UPDATE_OWNERSHIP_REQUEST,
    UPDATE_OWNERSHIP_SUCCESS,
    // ADD_OWNERSHIP_FAILURE,
    
    DELETE_OWNERSHIP_SUCCESS,

    UPLOAD_INVOICE_REQUEST,
    UPLOAD_INVOICE_SUCCESS,
    UPLOAD_INVOICE_FAILURE,

    DELETE_INVOICE_REQUEST,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,

} from '../actions';


const initialState = {
    isLoading: false,
    error: '',
    asset: {
        _id: '',
        accountId: '',
        assetName: '',
        assetType: '',
        template: '',
        customFields: [],
        files: [],
        ownership: [],
        notes: [],
        image: {},
        status: '',
        location: '',
        purchaseDate: '',
        purchasePrice: ''
    },    
}

const deviceReducer = ((state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ASSET:
            return initialState;
        case FETCH_ASSET_REQUEST:
            return { ...initialState, isLoading: true };            
        case FETCH_ASSET_SUCCESS:
            return { ...state, asset: {...initialState.asset, ...action.payload}, isLoading: false, error: '' };
        case FETCH_ASSET_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        //
        case ADD_ASSET_REQUEST:            
            return { ...initialState, isLoading: true };
        case ADD_ASSET_FAILURE:            
        return { ...state, isLoading: false, error: action.payload };

            
        case UPDATE_ASSET_OVERVIEW_REQUEST:
            return { ...state, isLoading: true };     
        case UPDATE_ASSET_OVERVIEW_SUCCESS:
            return { ...state, asset: {...initialState.asset, ...action.payload}, isLoading: false, error: '' };
        case UPDATE_ASSET_OVERVIEW_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
            
        case ADD_ASSET_NOTE_SUCCESS:
            return { ...state, asset: {...state.asset, notes: action.payload }, isLoading: false, error: '' };

        case UPDATE_ASSET_NOTE_SUCCESS:
            return { ...state, asset: {...state.asset, notes: action.payload }, isLoading: false, error: '' };                    

        case DELETE_ASSET_NOTE_SUCCESS:
            const minusNote = state.asset.notes.reduce((memo, item) => {
                return (item._id === action.payload) ? memo : [...memo, item];
            }, []);
            return { ...state, asset: {...state.asset, notes: minusNote }, isLoading: false, error: '' };   

    
        case ADD_OWNERSHIP_SUCCESS:
            return { ...state, asset: {...state.asset, ownership: action.payload }, isLoading: false, error: '' };

        case UPDATE_OWNERSHIP_SUCCESS:
            return { ...state, asset: {...state.asset, ownership: action.payload }, isLoading: false, error: '' };                    

        case DELETE_OWNERSHIP_SUCCESS:
            const minusOwnership = state.asset.ownership.reduce((memo, ownership) => {
                return (ownership._id === action.payload) ? memo : [...memo, ownership];
            }, []);
            return { ...state, asset: {...state.asset, ownership: minusOwnership }, isLoading: false, error: '' };            

        case UPLOAD_INVOICE_REQUEST:
            return { ...state, isLoading: true };            
        case UPLOAD_INVOICE_SUCCESS:
            return { ...state, asset: {...state.asset, files: [...state.asset.files, action.payload] }, isLoading: false, error: '' };
        case UPLOAD_INVOICE_FAILURE:
            return { ...state, isLoading: false, error: action.payload };


        case DELETE_INVOICE_REQUEST:
            return state;       
        case DELETE_INVOICE_SUCCESS:
            return { ...state, asset: {...state.asset, files: state.asset.files.filter(x => x.storageId !== action.payload) }, error: '' };
        case DELETE_INVOICE_FAILURE:
            return { ...state, error: action.payload };
                    
        default:
            return state;
    }
});

export default deviceReducer;