import React, {  createRef, useState  } from 'react';

import { 
    Box,
    Button,

} from '@material-ui/core';  

import { 
    InfoDynamic, 
} from '../Common';

const initValues = { isGlobal: false, viewName: ''};
const AddCustomView = ({ handleSave, handleCancel }) => {
    const [newView, updateNewView] = useState({ isGlobal: false, viewName: ''});
    
    const form = createRef();


    const handleSubmit = () => {
        if(form.current.reportValidity()) {
            handleSave(newView);
            updateNewView(initValues)
        }
      }
      const handleUpdate = (newValue) => {
          updateNewView({...newView, ...newValue});
      } 
      
      

    return (   
        <form ref={form} onSubmit={e => e.preventDefault()}>
        <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}  pr={3}>
                <InfoDynamic
                    header='View Name'
                    fieldName='viewName'
                    fieldValue={newView.viewName}
                    editMode={true}
                    isRequired={true}
                    handleChange={handleUpdate}            
                />
            </Box> 
            <Box flexGrow={1} pr={3}>
                <InfoDynamic
                    fieldType='switch'
                    header='Public'
                    fieldName='isGlobal'
                    fieldValue={(newView.isGlobal === true)}
                    editMode={true}
                    isRequired={true}
                    handleChange={handleUpdate}
                />
            </Box>
            <Box >
                <Button 
                  variant="outlined"
                  className='button-list'
                  size="small"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  >
                Save
                </Button>
                <Button 
                  variant="outlined"
                  className='button-list'
                  size="small"
                  type="button"
                  color="secondary"
                  onClick={handleCancel}
                  >
                Cancel
                </Button>                
            </Box>
        </Box>

</form>
      
    )
}

export { AddCustomView };