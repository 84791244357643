
import {
    TextField,
} from '@material-ui/core';

const TextAreaEdit = (props) => {
    const { header, fieldName, fieldValue, disabled=false, isRequired=true, handleChange } = props;
    return (
            <TextField            
                fullWidth
                disabled={disabled}                
                InputLabelProps={{ shrink: true }}
                label={header}
                // margin="normal"
                name={fieldName}
                multiline
                rowsMax={4}
                onChange={(e)=>handleChange({ [e.target.name]: e.target.value })}
                required={isRequired}
                value={fieldValue || ''}
                />
    )
}


export { TextAreaEdit };