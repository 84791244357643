import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,

    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAILURE,

    ADD_SAVED_VIEW_REQUEST,
    ADD_SAVED_VIEW_SUCCESS,
    ADD_SAVED_VIEW_FAILURE,
    
    UPDATE_SAVED_VIEW_REQUEST,
    UPDATE_SAVED_VIEW_SUCCESS,
    UPDATE_SAVED_VIEW_FAILURE,

    DELETE_SAVED_VIEW_REQUEST,
    DELETE_SAVED_VIEW_SUCCESS,
    DELETE_SAVED_VIEW_FAILURE,

} from '../actions';


const initialState = 
{
    isLoading: false,
    isAuthenticated: false,
    authEvent: '',
    token: '',
    error: '',
    user: {
        'accountName': '',
        'email': '',
        'firstName': '',
        'lastName': '',
        'scope': [],
        'savedViews': [],
        'settings': {
            storageLocations: ['Warehouse'],
            shippingOptions: ['Fedex', 'UPS', 'USPS'],
            disposalOptions: ['Recycled', 'Sold', 'Other'],
            deviceConditionOptions: ['Excellent', 'Good', 'Has Issues', 'Broken'],
        },
    },
};

const authReducer = ((state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: true, isLoading: false, authEvent: 'login', error: '' };      
        case LOGIN_FAILURE:
            return { ...state, isLoading: false, isAuthenticated: false, error: action.payload };
        case LOGOUT_REQUEST:
            return { ...state, isLoading: true, authEvent: 'logout' };
        case LOGOUT_SUCCESS:
            return { ...initialState, isLoading: false, authEvent: 'logout' };
        case USER_PROFILE_REQUEST:            
            return { ...state, isLoading: true, error: '' };
        case USER_PROFILE_SUCCESS:            
            return { ...state, user: action.payload, isAuthenticated: true, authEvent: 'profile', isLoading: false, error: '' };
        case USER_PROFILE_FAILURE:
            return { ...initialState, error: action.payload };

        case ADD_SAVED_VIEW_REQUEST:
            return { ...state, isLoading: true, error: '' };  
        case ADD_SAVED_VIEW_SUCCESS:      
            return { ...state, isLoading: false, user: {...state.user, savedViews: [...state.user.savedViews, action.payload] }};
        case ADD_SAVED_VIEW_FAILURE:
            return { ...state, isLoading: false, error: action.payload };

        case UPDATE_SAVED_VIEW_REQUEST:
            return { ...state, isLoading: true, error: '' };  
        case UPDATE_SAVED_VIEW_SUCCESS:
            const updatedSavedViews = state.user.savedViews.map(item => {
                return item._id === action.payload._id ? action.payload : item;
            })
            return { ...state, isLoading: false, user: {...state.user, savedViews: updatedSavedViews }};
        case UPDATE_SAVED_VIEW_FAILURE:
            return { ...state, isLoading: false, error: action.payload };            
        
        case DELETE_SAVED_VIEW_REQUEST:            
            return { ...state, isLoading: true, error: '' };  
        case DELETE_SAVED_VIEW_SUCCESS:            
            return { ...state, isLoading: false, user: {...state.user, savedViews: state.user.savedViews.filter(view => view._id !== action.payload) }};
        case DELETE_SAVED_VIEW_FAILURE:
            return { ...state, isLoading: false, error: action.payload };            
        default:
            return state;
    }
});

export default authReducer;