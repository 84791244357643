import {
    createTheme,
    makeStyles
  } from '@material-ui/core/styles';
// import { pink } from '@material-ui/core/colors/pink';
const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0
        }
      },
      MuiPopover: {
        root: {
          zIndex: '10000 !important'
        }
      },
      MuiAutocomplete: {
        popper: {
          zIndex: '10000 !important'
        }
      }
    },
  
    palette: {
      primary: { main: '#01579b', light: "#4f83cc", dark: "#002f6c" },
    //   secondary: { main: '#d81b60', light: "#ff5c8d", dark: '#a00037' },
      // secondary: { main: '#455a64', light: "#718792", dark: '#1c313a' },
      accent: { light: '#ff79b0', main: '#ff4081', dark: '#c60055'},
      warning: { light: '#F9F1F0', main: '#F8AFA6', dark: '#F79489'  },
      danger: { light: '#ea5252', main: '#c80808', dark: '#880808'  },
      
      info: { light: '#EFEFEF', main: '#737d87', dark: '#59656f' },
      success: { light: "#60ad5e", main: "#2e7d32", dark: "#005005" }
    }
  });


  const useStyles = makeStyles((theme) => ({
    '@global': {
      body: {
        backgroundColor: '#f5f6fa'
      },
      // palette: {
      //   primary: {
      //     main: 'rgb(31, 52, 102)',
      //     secondary: '#106c43'
      //   }
      // }      
    },

    content: {
      flex: 1
    },
    offset: theme.mixins.toolbar,
    root: {
      display: 'flex'
    },
    routeContent: {
      display: 'flex',
      padding: theme.spacing(3),
      position: 'relative'
    },
    spinner: {
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center'
    },
    tooltip: {
      backgroundColor: theme.palette.success.main,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1)
    }, 
    selectStyle: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: '100%'
    },    
  }));

  
  export {
      theme,
      useStyles
  }